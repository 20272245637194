<template>
  <div class="rounded-xl overflow-hidden">
    <button @click="openModal($route.params.id, 'createKegiatan')" class="border px-5 py-1 bg-emerald-500 text-white hover:text-white rounded-md">Add Kegiatan</button>
<!--    <form @submit.prevent="submitAgenda">-->
<!--            <input class="border border-r-0 px-2 outline-none py-0.5 rounded-l-md" type="text"-->
<!--                   v-model="form.nama_kegiatan" placeholder="Nama Kegiatan">-->
<!--            <button type="submit" class="border bg-emerald-500 hover:text-white rounded-r-md">-->
<!--              <font-awesome-icon class="mx-2" icon="fa-solid fa-plus"/>-->
<!--            </button>-->
<!--          </form>-->
    <div class="grid grid-cols-1 gap-5">
      <div v-for="(item, index, key) in this.kegiatan" :class="item.status ? 'bg-white' : 'bg-gray-100' "
           class="border rounded-xl shadow p-10" :key="key">
        <div class="flex justify-between">
          <h3 class="text-xl">{{ item.nama }}</h3>
          <MenuDropdown>
            <button v-if="item.status" @click="openModal(item.id, 'kegiatan')" class="px-4 py-2">Edit</button>
          </MenuDropdown>
        </div>
        <div class="flex space-x-3">
          <a :href="item.file_proposal?item.file_proposal.file:'#'" :target="item.file_proposal?'_blank':'_self'" class="text-blue-500">
            <font-awesome-icon :class="item.file_proposal ? 'text-emerald-400' : 'text-rose-500'"
                               :icon="item.file_proposal ? 'fa-solid fa-circle-check' : 'fa-solid fa-circle-xmark'"/>
            Proposal
          </a>
          <a :href="item.file_lpj?item.file_lpj.file:'/#'" :target="item.file_proposal?'_blank':'_self'" class="text-blue-500">
            <font-awesome-icon :class="item.file_lpj ? 'text-emerald-400' : 'text-rose-500'"
                               :icon="item.file_lpj ? 'fa-solid fa-circle-check' : 'fa-solid fa-circle-xmark'"/>
            Laporan Penanggung Jawab
          </a>
          <ToolTipStatusComponent v-if="item.approval[item.approval.length - 1]" :status="item.approval[item.approval.length - 1].status" :pesan="item.approval[item.approval.length - 1].pesan" />
        </div>
        <div class="bg-gray-100 border flex-row p-5 rounded-lg space-y-5">
          <div
               class="rounded-lg border border-gray-400 bg-gray-200 p-4 flex justify-between">
            <div>
              <h3>Tanggal : {{ item.rincian_kegiatan ? item.rincian_kegiatan.created_at : '' }}</h3>
              <div class="my-4">Rincian :
                <p class="px-10" v-html="item.rincian_kegiatan ? item.rincian_kegiatan.rincian : ''"></p>
              </div>
              <h3 class="text-xl">Anggaran : Rp.{{ item.rincian_kegiatan ? item.rincian_kegiatan.anggaran : ''  | formatNumber}}</h3>
            </div>
            <MenuDropdown>
              <button @click="openModal(item.rincian_kegiatan.id, 'anggaran')" class="px-4 py-2">Edit</button>
            </MenuDropdown>
          </div>
        </div>
        <!--        {{item}}-->
      </div>
    </div>
    <div>
      <ModalComponent width="w-[60rem]">
        <div v-if="jenismodal == 'createKegiatan'">
          <div class="grid grid-cols-2 gap-x-5 gap-y-7 mb-5">
            <InputComponent :disabled="false" class="col-span-2" v-model="form.nama_kegiatan"
                            name="agenda" label="Agenda" type="text"/>
            <InputComponent :disabled="false" class="col-span-2" v-model="form.tanggal_kegiatan"
                            name="tanggal_kegiatan" label="Tanggal Kegiatan" type="date"/>
          </div>
          <div class="grid grid-cols-2 gap-5">
            <button @click="submitAgenda"
                    class="bg-green-500 mr-3 text-lg py-1 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
              Submit
            </button>
            <button @click="inactiveData"
                    class="bg-gray-100 transform text-lg py-1 focus:scale-110 transition duration-200 text-black text-center rounded-md shadow-md focus:shadow-lg px-1.5">
              Cancel
            </button>
          </div>
        </div>
        <div v-if="jenismodal == 'kegiatan'">
          <div class="grid grid-cols-2 gap-x-5 gap-y-7 mb-5" v-if="form.jenis_prestasi != 'kejuaraan'">
            <InputFileComponent v-if="!disabled || !form.file_proposal" :disabled="disabled"
                                class="col-start-1 col-span-1" @file="insertProposal"
                                name="file_proker"
                                label="Proposal"/>
            <InputFileComponent v-if="!disabled || !form.file_lpj" :disabled="disabled"
                                class="col-start-1 col-span-1" @file="insertLpj"
                                name="file_lpj"
                                label="LPJ"/>
            <div v-if="form.file_proposal" class="col-start-1 col-span-1">
              <a class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1 rounded-md" target="_blank"
                 :href="form.file_proposal.file">File Proposal</a>
            </div>
            <div v-if="form.file_lpj" class="col-start-1 col-span-1">
              <a class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1 rounded-md" target="_blank"
                 :href="form.file_lpj.file">File LPJ</a>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-5" v-if="disabled">
            <button @click="activeData"
                    class="bg-yellow-500 mr-3 text-lg py-1 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
              Edit
            </button>
            <!--        <button @click="deleteForm"-->
            <!--            class="bg-red-500 transform text-lg py-1 focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">-->
            <!--          Delete-->
            <!--        </button>-->
          </div>
          <div class="grid grid-cols-2 gap-5" v-if="!disabled">
            <button @click="updateAgenda"
                    class="bg-green-500 mr-3 text-lg py-1 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
              Submit
            </button>
            <button @click="inactiveData"
                    class="bg-gray-100 transform text-lg py-1 focus:scale-110 transition duration-200 text-black text-center rounded-md shadow-md focus:shadow-lg px-1.5">
              Cancel
            </button>
          </div>
        </div>
        <div v-if="jenismodal == 'anggaran'">
          <div class="grid grid-cols-2 gap-x-5 gap-y-7 mb-5" v-if="form.jenis_prestasi != 'kejuaraan'">
            <InputComponent :disabled="false" class="col-span-2" v-model="form_anggaran.anggaran"
                            :value="form_anggaran.anggaran"
                            name="anggaran" label="Anggaran" type="number"/>
            <div class="col-span-2">
              <Editor
              api-key="no-api-key"
              :init="{
                plugins: [
                 'advlist autolink lists link image charmap print preview anchor',
                 'searchreplace visualblocks code fullscreen',
                 'insertdatetime media table paste code help wordcount'
               ],
               toolbar:
                 'undo redo | formatselect | bold italic backcolor | \
                 bullist numlist | removeformat'
              }"
              v-model="form_anggaran.rincian" :value="form_anggaran.rincian"
            />
            </div>
<!--            <InputComponent :disabled="false" class="col-span-2" v-model="form_anggaran.rincian"-->
<!--                            :value="form_anggaran.rincian"-->
<!--                            name="rincian" label="Rincian" type="text"/>-->
            <input class="bg-gray-200 px-1.5 pt-2 pb-1.5 rounded-lg" name="created_at" type="date"
                   v-model="form_anggaran.created_at">
          </div>
          <div class="grid grid-cols-2 gap-5">
            <button @click="submitAnggaran"
                    class="bg-green-500 mr-3 text-lg py-1 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
              Submit
            </button>
            <button @click="deleteAnggaran"
                    class="bg-rose-500 mr-3 text-lg py-1 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
              Delete
            </button>
          </div>
        </div>
      </ModalComponent>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ModalComponent from "@/components/widget/Modal/ModalComponent";
import InputComponent from "@/components/widget/form/InputComponent";
import InputFileComponent from "@/components/widget/form/InputFileComponent";
import MenuDropdown from "@/components/widget/MenuDropdown";
import Editor from "@tinymce/tinymce-vue";
import ToolTipStatusComponent from "@/components/widget/ToolTipStatusComponent";


export default {
  name: "KegiatanPage",
  components: {
    Editor,
    ModalComponent,
    InputComponent,
    InputFileComponent,
    MenuDropdown,
    ToolTipStatusComponent
  },
  data() {
    return {
      disabled: true,
      kegiatan: [],
      form: {
        nama: '',
        nama_kegiatan: '',
        file_proposal: '',
        file_lpj: '',
        input_file_proposal: '',
        input_file_lpj: ''
      },
      form_anggaran: {
        id_kegiatan: null,
        anggaran: null,
        description: null,
        target: null,
        created_at: null,
      },
      jenismodal: null
    }
  },
  mounted() {
    this.getAgenda()
  },
  methods: {
    inactiveData: function () {
      this.disabled = true
    },
    activeData: function () {
      this.disabled = false
    },
    deleteKegiatan: function (id) {
      axios.delete(process.env.VUE_APP_BASE_URL + '/ormawa/kegiatan-ormawa/' + id + '/deleteKegiatan/',
          this.form, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
          .then(resp => {
            console.log(resp)
          })
          .finally(() => {
            this.getAgenda()
          })
    },
    submitAgenda: function () {
      axios.post(process.env.VUE_APP_BASE_URL + '/ormawa/kegiatan-ormawa/' + this.$route.params.id + '/insertKegiatan/',
          this.form, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
          .then(resp => {
            console.log(resp)
          })
          .finally(() => {
            this.getAgenda()
            this.form.nama = null
          })
    },
    submitAnggaran: function () {
      // console.log(id)
      if (this.form_anggaran.id_kegiatan) {
        console.log(this.form_anggaran)
        axios.post(process.env.VUE_APP_BASE_URL + '/ormawa/kegiatan-ormawa/submitAnggaran/', this.form_anggaran, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
            .then(resp => {
              console.log(resp)
            })
            .finally(() => {
              this.getAgenda()
              this.form.nama = null
              this.$store.commit('closeModal')
            })
      } else {
        axios.put(process.env.VUE_APP_BASE_URL + '/ormawa/kegiatan-ormawa/submitAnggaran/', this.form_anggaran, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
            .then(resp => {
              this.$store.commit('addNotification', {type: resp.data.type, message: resp.data.message})
            })
            .finally(() => {
              this.getAgenda()
              this.form.nama = null
              this.$store.commit('closeModal')
            })
      }
    },
    deleteAnggaran: function () {
      // console.log(id)
      axios.post(process.env.VUE_APP_BASE_URL + '/ormawa/kegiatan-ormawa/deleteAnggaran/', this.form_anggaran, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
          .then(resp => {
            console.log(resp)
          })
          .finally(() => {
            this.getAgenda()
            this.form.nama = null
            this.$store.commit('closeModal')
          })
    },
    updateAgenda: function () {
      const formData = new FormData()
      if (this.form.input_file_proposal) {
        formData.append('proposal', this.form.input_file_proposal)
      }
      if (this.form.input_file_lpj) {
        formData.append('lpj', this.form.input_file_lpj)
      }
      formData.append('token', this.$store.state.auth.refresh_token)
      axios.put(process.env.VUE_APP_BASE_URL + '/ormawa/kegiatan-ormawa/' + this.form.id + '/updateKegiatan/',
          formData, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
          .then(resp => {
            console.log(resp)
          })
          .finally(() => {
            this.getAgenda()
            this.form.nama = null
            this.$store.commit('closeModal')
          })
    },
    getAgenda: function () {
      axios.post(process.env.VUE_APP_BASE_URL + '/ormawa/kegiatan-ormawa/' + this.$route.params.id + '/getKegiatan/', {
        nim: this.$store.state.auth.uid
      }, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
          .then(resp => {
            this.kegiatan = resp.data
          })
    },
    openModal: function (url, jenis) {
      console.log(url)
      this.jenismodal = jenis
      this.form = {}
      this.form_anggaran = {}
      this.disabled = true
      this.$store.commit('openModal', url)
      if (this.jenismodal === 'anggaran') {
        if (!url.id_kegiatan) {
          axios.post(process.env.VUE_APP_BASE_URL + '/ormawa/kegiatan-ormawa/' + url + '/getAnggaran/', {}, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
              .catch(e => console.log(e))
              .then(resp => {
                this.form_anggaran = resp.data
              })
        }
        this.form_anggaran.id_kegiatan = url.id_kegiatan
      } else if (this.jenismodal === 'kegiatan') {
        axios.get(process.env.VUE_APP_BASE_URL + '/ormawa/kegiatan-ormawa/' + url + '/detailKegiatan/', {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
            .catch(e => console.log(e))
            .then(resp => {
              // console.log(resp)
              this.form = resp.data
            })
      } else if (this.jenismodal === 'createKegiatan') {
        // axios.post(process.env.VUE_APP_BASE_URL + '/ormawa/kegiatan-ormawa/' + url + '/insertKegiatan/', {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
        //     .catch(e => console.log(e))
        //     .then(resp => {
        //       console.log(resp)
        //     })
        // .finally(() => {
        //     this.getAgenda()
        //     this.form.nama = null
        //   })
      }
      // this.$store.dispatch('getDataModal', process.env.VUE_APP_BASE_URL + '/ormawa/organisasi-ormawa/' + url + '/')

    },
    getAnggaran: function (id, target) {
      this.jenismodal = 'anggaran'
      this.form_anggaran = {}
      this.disabled = true
      this.$store.commit('openModal', id)
      axios.post(process.env.VUE_APP_BASE_URL + '/ormawa/kegiatan-ormawa/' + id + '/getAnggaran/', {
        target_anggaran: target
      }, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
          .then(resp => {
            this.form_anggaran = resp.data
            this.form_anggaran.kegiatan_id = id
            this.form_anggaran.target = target
          })
      // this.$store.dispatch('getDataModal', process.env.VUE_APP_BASE_URL + '/ormawa/organisasi-ormawa/' + url + '/')
      // axios.get(process.env.VUE_APP_BASE_URL + '/ormawa/kegiatan-ormawa/' + url + '/detailKegiatan/')
      //     .catch(e => console.log(e))
      //     .then(resp => {
      //       // console.log(resp)
      //       this.form = resp.data
      //     })
    },
    insertProposal: function (input) {
      this.form.input_file_proposal = input
    },
    insertLpj: function (input) {
      this.form.input_file_lpj = input
    },
  }
}
</script>

<style scoped>

</style>