<template>
  <div>
    <div class="grid grid-cols-12 gap-x-5 gap-y-5 my-5">
      <MacCardComponent cardsname="Pengajuan Beasiswa Prestas | Beasiswa" class="col-span-12 overflow-hidden">
        <div class="px-5 mb-5">
          <div class="">
            <div class="grid grid-cols-4 gap-x-5 gap-y-7 pt-4">
              <button @click="generateRekomendasi"
                      class="bg-green-400 hover:bg-green-500 rounded-md h-10 text-white font-bold">Surat Rekomendasi
              </button>
              <button @click="generateKeterangan"
                      class="bg-green-400 hover:bg-green-500 rounded-md text-sm h-10 text-white font-bold">Surat
                Keterangan
                Tidak Menerima Beasiswa
              </button>
              <button @click="generateSPTJM"
                      class="bg-green-400 hover:bg-green-500 rounded-md text-sm h-10 text-white font-bold">Surat
                SPTJM Mahasiswa
              </button>
              <button @click="openModal()"
                      class="bg-green-400 hover:bg-green-500 rounded-md text-sm h-10 text-white font-bold">Surat
                Surat Rekomandasi Kampus Merdeka
              </button>
            </div>
          </div>
        </div>
      </MacCardComponent>
    </div>
    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
                  :paginate-elements-by-height="2000" filename="hee hee" :pdf-quality="2" :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait" pdf-content-width="800px" ref="rekomendasi">
      <section slot="pdf-content">
        <div class="pl-20">
          <table class="w-full px-52">
            <div>
              <div class="flex relative">
                <div class="">
                  <div class="flex space-x-2 relative h-28">
                    <div class="m-auto">
                      <img class="h-28 mr-10 m-auto" src="@/assets/logo-umkt.png" alt="logo umkt">
                    </div>
                  </div>
                </div>
                <div class="w-full border-l-4 border-blue-900">
                  <div class="flex">
                    <div class="w-full">
                      <span class="text-5xl leading-10" style="font-family: 'Times New Roman';">UMKT</span><br>
                      <span class="" style="font-size: 15px;">
                      <div class="mt-5 mb-2" style="font-family: Arial; font-size: 18px; font-weight: bolder">
                        Kemahasiswaan
                      </div>
                    </span>
                    </div>
                    <div class="my-auto ml-auto text-right">
                      <div class="flex relative mt-2">
                        <span class="h-5 mr-0 leading-3 m-auto">081253512902</span>
                        <div class="h-5 w-16 bg-blue-900 ml-3"></div>
                      </div>
                      <div class="flex relative mt-2">
                        <span class="h-5 mr-0 leading-3 m-auto">umkt.ac.id</span>
                        <div class="h-5 w-16 bg-blue-900 ml-3"></div>
                      </div>
                      <div class="flex relative mt-2">
                        <span class="h-5 mr-0 leading-3 m-auto">kemahasiswaan@umkt.ac.id</span>
                        <div class="h-5 w-16 bg-blue-900 ml-3"></div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full border-t-4 border-blue-900">
                    <div class="bg-blue-900 w-1/2 h-8"></div>
                  </div>
                </div>
              </div>
            </div>
          </table>
          <div>
            <div class="flex">
              <img class="h-20 m-auto" src="@/assets/the-best-bismillah-vector-images-download-from-17.png">
            </div>
            <div class="-mt-5">
              <div class="m-auto w-72 text-center uppercase font-bold text-2xl border-b-4 border-black pb-2">SURAT
                REKOMENDASI
              </div>
              <div class="m-auto w-72 text-center uppercase font-bold">NO : /BKA/A.5/REK/2022</div>
            </div>
          </div>
          <div class="pt-8">
            <div>Assalamu'alaikum Wr. Wb</div>
            <div>
              Yang bertanda tangan dibawah ini :
              <div class="px-10 py-5">
                <table>
                  <tr>
                    <td class="w-32">Nama</td>
                    <td class="w-4">:</td>
                    <td class="">Ns. Andri Praja Satria,M.Sc,M.Biomed</td>
                  </tr>
                  <tr>
                    <td class="w-32">NIDN</td>
                    <td class="w-4">:</td>
                    <td class="">1104068405</td>
                  </tr>
                  <tr>
                    <td class="w-32">Jabatan</td>
                    <td class="w-4">:</td>
                    <td class="">Kabag Admininstrasi Kemahasiswaan</td>
                  </tr>
                </table>
              </div>
              Dengan ini merekomendasikan kepada mahasiswa atas nama :
              <div class="px-10 py-5">
                <table>
                  <tr>
                    <td class="w-32">Nama</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_rekomendasi.nama }}</td>
                  </tr>
                  <tr>
                    <td class="w-32">NIM</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_rekomendasi.nim }}</td>
                  </tr>
                  <tr>
                    <td class="w-32">Program Studi</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_rekomendasi.program_studi }}</td>
                  </tr>
                </table>
              </div>
              Untuk bisa mengikuti <span class="font-bold">Program beasiswa Mahakam D3/D4/S1 Tahun 2022</span> yang
              diselenggarakan oleh skkmigas (Pertamina Hulu Mahakam) dan berikut kami cantumkan SPP Tetap, SPP Variabel
              dan No. Rekening Bank Unversitas Muhammadiyah Kalimantan Timur.
              <div class="py-5">
                <table>
                  <tr>
                    <td class="w-4">1.</td>
                    <td class="w-32">SPP Tetap</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_rekomendasi.spp_tetap }}</td>
                  </tr>
                  <tr>
                    <td class="w-4">2.</td>
                    <td class="w-32">SPP Variabel</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_rekomendasi.spp_variabel }}</td>
                  </tr>
                  <tr>
                    <td class="w-4">3.</td>
                    <td class="w-32">No. Rekening UMKT</td>
                    <td class="w-4">:</td>
                    <td class="">CIMB Niaga <span class="font-bold">No.Rek. 860002414200</span> atas nama Universitas
                      Muhammadiyah Kalimantan Timur.
                    </td>
                  </tr>
                </table>
              </div>
              Demikian surat rekomendasi ini dibuat untuk dapat dipergunakan sebagaimana mestinya.
              <br>
              Wassalamu'alaikum Wr. Wb
              <div class="flex px-10 font-bold">
                <div class="my-auto ml-auto">
                  <div>
                    Samarinda, 08 Jumadil Awwal 1443 H
                  </div>
                  <div>
                    04 Januari 2022 M
                  </div>
                  <div class="mt-32">
                    <div>Kabag Administrasi Kemahasiswaan,</div>
                    <div>
                      <div>
                        Ns. Andri Praja Satria, M.Sc.,M.Biomed
                      </div>
                      <div>NIDN.1104068405</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
                  :paginate-elements-by-height="2000" filename="hee hee" :pdf-quality="2" :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait" pdf-content-width="800px" ref="keterangan">
      <section slot="pdf-content">
        <div class="px-5">
          <div class="text-center text-2xl uppercase my-10">
            <div>Surat Pernyataan</div>
            <div>Tidak Sedang Sebagai Penerima Beasiswa Lain</div>
          </div>
          <div class="w-full" style="height: 2px; background-color: #eeb822"></div>
          <div class="w-full" style="height: 2px; background-color: #334164"></div>
          <div class="pt-8">
            <div>
              Yang bertanda tangan dibawah ini, saya :
              <div class="px-10 py-5">
                <table>
                  <tr>
                    <td class="w-4">a)</td>
                    <td class="w-60">Nama Lengkap Mahasiswa</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_pernyataan.nama }}</td>
                  </tr>
                  <tr>
                    <td class="w-4">b)</td>
                    <td class="w-60">Tempat/Tgl Lahir</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_pernyataan.tempat_tgl_lahir }}</td>
                  </tr>
                  <tr>
                    <td class="w-4">c)</td>
                    <td class="w-60">Alamat rumah</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_pernyataan.alamat }}</td>
                  </tr>
                  <tr>
                    <td class="w-4">d)</td>
                    <td class="w-60">Nomor Induk Mahasiswa (NIM)</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_pernyataan.nim }}</td>
                  </tr>
                  <tr>
                    <td class="w-4">e)</td>
                    <td class="w-60">Mahasiswa Tahun Akademik</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_pernyataan.tahun_akademik }}</td>
                  </tr>
                  <tr>
                    <td class="w-4">f)</td>
                    <td class="w-60">Fakultas</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_pernyataan.fakultas }}</td>
                  </tr>
                  <tr>
                    <td class="w-4">g)</td>
                    <td class="w-60">Jurusan/Program Studi</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_pernyataan.program_studi }}</td>
                  </tr>
                  <tr>
                    <td class="w-4">h)</td>
                    <td class="w-60">Jenjang Program</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_pernyataan.jenjang_program }}</td>
                  </tr>
                  <tr>
                    <td class="w-4">i)</td>
                    <td class="w-60">Semester</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_pernyataan.semester }}</td>
                  </tr>

                </table>
              </div>
              Dengan ini menyatakan bahwa saya pada tahun 2022 tidak sedang menerima beasiswa/ikatan dinas dari
              instasi/lembaga tertentu. Apabila dikemudian hari ternyata saya terbukti menerima beasiswa lain, saya
              sanggup dikenakan sanksi dan bersedia mengembalikan beasiswa tersebut kepada pihak yang berwenang

              Wassalamu'alaikum Wr. Wb
              <div class="flex mt-20 font-bold">
                <div class="">
                  <div class="mt-8">
                    <div>Mengetahui</div>
                    <div>Kabag Administrasi Kemahasiswaan</div>
                    <div class="mt-20">
                      <div class="font-thin text-sm">Materai Rp. 10.000,-</div>
                      <div class="mt-12">
                        Ns. Andri Praja Satria, M.Sc.,M.Biomed
                      </div>
                      <div>NIDN.1104068405</div>
                    </div>
                  </div>
                </div>
                <div class="m-auto">
                  <div class="-translate-y-10">
                    Samarinda, 04 Januari 2022 M
                  </div>
                  <div>
                    <div class="">Yang membuat pernyataan,</div>
                    <div class="mt-36">
                      <div>
                        Ricko Caesar Aprilla Tiaka
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
                  :paginate-elements-by-height="2000" filename="surat sptjm" :pdf-quality="2" :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait" pdf-content-width="800px" ref="sptjm">
      <section class="text-sm px-8 py-14" slot="pdf-content">
        <div class="px-5">
          <div class="text-center w-full font-bold">
            <div class="m-auto w-[38rem] ">
              SURAT PERNYATAAN TANGGUNG JAWAB MUTLAK (SPTJM) MAHASISWA
              PESERTA PROGRAM MAGANG DAN STUDI INDEPENDEN BERSERTIFIKAT
              ANGKATAN 5 TAHUN 2023
            </div>
          </div>
          <div class="">
            <div>
              Yang bertanda tangan dibawah ini :
              <div class="px-10 py-5">
                <table>
                  <tr>
                    <td class="w-32">Nama Lengkap</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_sptjm.nama }}</td>
                  </tr>
                  <tr>
                    <td class="w-32">Perguruan Tinggi</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_sptjm.perguruan_tinggi }}</td>
                  </tr>
                  <tr>
                    <td class="w-32">Jurusan/program studi</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_sptjm.program_studi }}</td>
                  </tr>
                  <tr>
                    <td class="w-32">NIM</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_sptjm.nim }}</td>
                  </tr>
                  <tr>
                    <td class="w-32">NIK</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_sptjm.nik }}</td>
                  </tr>
                  <tr>
                    <td class="w-32">no. HP/Whatsapp</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_sptjm.no_hp }}</td>
                  </tr>
                  <tr>
                    <td class="w-32 italic">e-mail</td>
                    <td class="w-4">:</td>
                    <td class="">{{ this.form_sptjm.email }}</td>
                  </tr>
                </table>
              </div>
              Dengan ini menyatakan dengan sesungguhnya bahwa :
              <div class="">
                <ol>
                  <li><span class="font-bold mr-5">1.</span> selain Program Magang dan Studi Independen Bersertifikat
                    (MSIB), saya sedang menerima/tidak
                    sedang menerima*) beasiswa dari Anggaran Pendapatan dan Belanja Negara (APBN) pada semester ini,
                    yaitu
                    beasiswa ………………………**), dengan bantuan biaya hidup bulanan (Living Allowance/LA) senilai
                    Rp………………***);
                  </li>
                  <li><span class="font-bold mr-5">2.</span> sanggup dan bertanggung jawab untuk mengikuti Program
                    MSIB Angkatan 5 tahun 2023 dengan mematuhi
                    segala ketentuan sebagai berikut:
                    <ol class="px-3">
                      <li><span class="font-bold mr-5">a.</span> mengikuti Program MSIB selama satu semester dan
                        melaksanakan program tersebut dengan
                        sungguh-sungguh sampai selesai dibuktikan dengan pengumpulan laporan kegiatan belajar sesuai
                        batas
                        waktu yang ditetapkan;
                      </li>
                      <li><span class="font-bold mr-5">b.</span> belum yudisium dan bersedia untuk tidak yudisium
                        selama jangka waktu Program MSIB
                        berlangsung;
                      </li>
                      <li><span class="font-bold mr-5">c.</span> tidak mengambil mata kuliah lain selama mengikuti
                        Program MSIB;
                      </li>
                      <li><span class="font-bold mr-5">d.</span> bersedia melaksanakan segala bentuk penugasan dari
                        pengelola program dan/atau Mitra dalam
                        proses
                        pembelajaran program ini dan bersedia serta sanggup ditempatkan di lokasi Mitra di seluruh
                        wilayah
                        Indonesia;
                      </li>
                      <li><span class="font-bold mr-5">e.</span> memenuhi ketentuan protokol kesehatan yang ditetapkan
                        oleh Mitra;
                      </li>
                      <li><span class="font-bold mr-5">f.</span> mematuhi ketentuan pelaksanaan Program MSIB yang
                        ditetapkan oleh Kementerian Pendidikan,
                        Kebudayaan, Riset, dan Teknologi (Kemendikbudristek) serta menaati segala ketentuan peraturan
                        perundang-undangan yang berlaku;
                      </li>
                      <li><span class="font-bold mr-5">g.</span> bersedia dikeluarkan sebagai peserta program dan
                        tidak mendapatkan pengakuan sks untuk
                        pembelajaran Program MSIB karena tindakan plagiarisme, termasuk plagiasi diri, tindakan
                        kriminal,
                        tindakan kekerasan dan diskriminasi dalam segala bentuk, termasuk tindakan asusila, kekerasan
                        seksual, perundungan, dan tindakan intoleransi, dan/atau penyalahgunaan obat-obatan terlarang;
                      </li>
                      <li><span class="font-bold mr-5">h.</span> menerima dan menjalankan segala bentuk sanksi sesuai
                        dengan peraturan yang berlaku termasuk
                        pencantuman dalam daftar hitam pendaftar Program MSIB untuk angkatan selanjutnya dan/atau
                        Program
                        Kampus Merdeka lainnya; dan
                      </li>
                      <li><span class="font-bold mr-5">i.</span> jika saya tidak menyelesaikan program sesuai dengan
                        waktu yang telah ditentukan karena
                        mengundurkan diri atau dikeluarkan atau alasan lainnya, maka saya bersedia untuk:
                        <div class="px-3">
                          <ol>
                            <li><span class="font-bold mr-5">1)</span> mengembalikan seluruh pembiayaan yang telah
                              dibayarkan oleh Kemendikbudristek;
                            </li>
                            <li><span class="font-bold mr-5">2)</span> tidak mendapatkan pengakuan sks untuk
                              pembelajaran Program MSIB maupun fasilitas
                              Program MSIB lainnya.
                            </li>
                          </ol>
                        </div>
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-32 px-5">
          <span class="font-bold mr-5">3.</span> Bertanggung jawab penuh untuk mengembalikan dana
          transportasi yang telah dikeluarkan oleh Program
          MSIB apabila saya lalai dan mengakibatkan terjadinya re-schedule, pembatalan, dan/atau tiket tidak
          digunakan (tidak jadi menggunakan tiket perjalanan sebagaimana mestinya). Kelalaian yang dimaksud
          adalah:
          <ol class="px-3">
            <li>
              <span class="font-bold mr-5">a.</span>Tidak hadir atau terlambat hadir sesuai jadwal
              keberangkatan, kecuali karena keadaan
              terpaksa/mendesak, yaitu karena:
              <ol class="px-3">
                <li><span class="font-bold mr-5">1)</span> sakit rawat inap atau karantina, melampirkan
                  surat keterangan dokter;
                </li>
                <li><span class="font-bold mr-5">2)</span> kecelakaan, melampirkan surat keterangan dari
                  kepolisian;
                </li>
                <li><span class="font-bold mr-5">3)</span> mengurus keluarga inti sakit, melampirkan surat
                  keterangan dari Rumah Sakit, dan copy
                  (salinan) Kartu Keluarga atau surat Keterangan dari perangkat desa setempat domisili pihak
                  keluarga yang sakit yang menyatakan hubungan keluarga;
                </li>
                <li><span class="font-bold mr-5">4)</span> menghadiri keluarga inti yang wafat, melampirkan
                  copy (salinan) Kartu Keluarga atau surat
                  Keterangan dari perangkat desa setempat domisili pihak keluarga yang wafat yang menyatakan
                  hubungan keluarga, dan surat keterangan dari perangkat desa setempat domisili pihak keluarga
                  yang wafat.
                </li>
              </ol>
            </li>
            <li>
              <span class="font-bold mr-5">b.</span>Tidak memenuhi persyaratan administrasi perjalanan
              sebagaimana yang ditentukan oleh peraturan
              perundang-undangan, seperti tidak dapat menunjukkan hasil vaksin, tidak membawa identitas, dan
              persyaratan perjalanan lainnya yang berlaku.
            </li>
          </ol>
          Demikian surat pernyataan ini dibuat dengan sebenarnya tanpa ada paksaan dari pihak manapun dan apabila
          dikemudian hari pernyataan ini terbukti tidak benar, maka saya bersedia dituntut di muka pengadilan serta
          bersedia menerima segala tindakan yang diambil oleh Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi.
          <div class="flex justify-center px-10 text-xs text-center mt-20 px-14">
            <div class="my-auto ml-auto">
              <div>
                Yang menyatakan,
              </div>
              <div class="mt-10 text-gray-500">Materai asli 10.000</div>
              <div class="mt-10">
                <div>
                  <div>
                    {{ this.form_sptjm.nama }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full text-center">
            Mengetahui dan menyetujui
          </div>
          <div class="flex px-10 text-xs text-center mt-20 px-14">
            <div class="my-auto ml-auto">
              <div>
                Orang Tua yang menyatakan,
              </div>
              <div class="mt-10 text-gray-500">Materai asli 10.000</div>
              <div class="mt-10">
                <div>
                  <div>
                    <div>.....................................................................</div>
                    (Nama Orang Tua/Wali Mahasiswa)
                  </div>
                </div>
              </div>
            </div>
            <div class="my-auto ml-auto">
              <div>
                Pimpinan Perguruan Tinggi
              </div>
              <div class="mt-5 w-60">
                Rektor/ Wakil rektor/ Direktur/ Wakil Direktur/ Dekan/ Wakil Dekan Perguruan Tinggi
              </div>
              <div class="mt-10 text-gray-500">TTD dan Cap</div>
              <div class="mt-10">
                <div>
                  <div>
                    <div>........................................................................</div>
                    <div>NIP ................................................................</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <ModalComponent width="w-[60rem]">
      <button v-if="rekomendasi_merdeka.id"
              :class="rekomendasi_merdeka.status.status == 0 ? 'bg-indigo-500' : rekomendasi_merdeka.status.status == 1 ? 'bg-emerald-500' : 'bg-red-500'"
              class="col-start-1 h-9 w-28 mb-5 transform cursor-default focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
        {{
          rekomendasi_merdeka.status.status == 0 ? 'Proses' : rekomendasi_merdeka.status.status == 1 ? 'Setuju' : 'Tolak'
        }}
      </button>
      <button v-if="rekomendasi_merdeka.id && rekomendasi_merdeka.status.status == 1"
              class="after:content-['_↗'] text-blue-500 mx-5" @click="$refs.rekomendasi_merdeka_download.generatePdf()">
        Unduk Berkas
      </button>
      <div v-if="rekomendasi_merdeka.id" class="overflow-y-scroll bg-gray-400">
        <div class="h-[35rem] px-10">
          <vue-html2pdf :show-layout="false" :float-layout="false" :enable-download="true" :preview-modal="false"
                        :paginate-elements-by-height="2000" filename="Surat_Rekomendasi_MSIB" :pdf-quality="2"
                        :manual-pagination="false" pdf-format="a4"
                        pdf-orientation="portrait" pdf-content-width="800px" class="h-full"
                        ref="rekomendasi_merdeka_download">
            <section class="" slot="pdf-content">
              <div class="px-5 py-5 bg-white">
                <table class="w-full px-52">
                  <div class="flex">
                    <div class="w-1/2">
                      <div class="flex space-x-2">
                        <img class="w-32" src="@/assets/logo-umkt.png" alt="logo umkt">
                        <div class="m-auto">
                          <span style="font-size: 50px; font-family: 'Times New Roman';">UMKT</span><br>
                          <span style="font-size: 15px;">
                            <div class="-mt-2" style="font-family: Arial; font-size: 18px; font-weight: bolder">
                              Universitas Muhammadiyah
                            </div>
                            <div class="-mt-2" style="font-family: Arial; font-size: 18px; font-weight: bolder">
                              Kalimantan Timur
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="flex w-1/2">
                      <div class="my-auto ml-auto text-right">
                        <div>
                          Kampus 1 : Jl. Ir. H. Juanda, No. 15, Samarinda
                        </div>
                        <div>
                          Kampus 2 : Jl. Pelita, Pesona Mahakam, Samarinda
                        </div>
                        <div>
                          Telp. 0541748511 Fax. 0541-766832
                        </div>
                      </div>
                    </div>
                  </div>
                </table>
                <div class="w-full" style="height: 2px; background-color: #eeb822"></div>
                <div class="w-full" style="height: 2px; background-color: #334164"></div>
                <div class="text-center w-full font-bold">
                  <div class="m-auto w-[38rem] ">
                    SURAT REKOMENDASI MAHASISWA PROGRAM MAGANG DAN STUDI INDEPENDEN BERSERTIFIKAT KAMPUS MERDEKA
                  </div>
                  <div class="m-auto w-72 text-center uppercase font-bold">NO :</div>
                </div>
                <div class="">
                  <div>
                    Yang bertanda tangan dibawah ini :
                    <div class="px-10 my-5">
                      <table>
                        <tr>
                          <td class="w-52">Nama</td>
                          <td class="w-4">:</td>
                          <td class="">{{ staff.nama }}</td>
                        </tr>
                        <tr>
                          <td class="w-52">Jabatan</td>
                          <td class="w-4">:</td>
                          <td class="">{{ staff.jabatan.nama_jabatan }}</td>
                        </tr>
                        <tr>
                          <td class="w-52">NIP/NIDN</td>
                          <td class="w-4">:</td>
                          <td class="">{{ staff.nip }}</td>
                        </tr>
                        <tr>
                          <td class="w-52">e-mail</td>
                          <td class="w-4">:</td>
                          <td class="">{{ staff.email }}</td>
                        </tr>
                        <tr>
                          <td class="w-52">no. Telp./HP</td>
                          <td class="w-4">:</td>
                          <td class="">{{ staff.no_hp }}</td>
                        </tr>
                      </table>
                    </div>
                    memberikan rekomendasi kepada mahasiswa berikut :
                    <div>
                      <div class="px-10 my-5">
                        <table>
                          <tr>
                            <td class="w-80">Nama lengkap sesuai KTP</td>
                            <td class="w-4">:</td>
                            <td class="">{{ $store.state.auth.profile.nama }}</td>
                          </tr>
                          <tr>
                            <td class="w-80">NIK</td>
                            <td class="w-4">:</td>
                            <td class="">{{ $store.state.auth.profile.nik }}</td>
                          </tr>
                          <tr>
                            <td class="w-80">NIM</td>
                            <td class="w-4">:</td>
                            <td class="">{{ $store.state.auth.profile.nim }}</td>
                          </tr>
                          <tr>
                            <td class="w-80">Program studi/Jurusan</td>
                            <td class="w-4">:</td>
                            <td class="">{{ $store.state.auth.profile.prodi }}</td>
                          </tr>
                          <tr>
                            <td class="w-80">Fakultas</td>
                            <td class="w-4">:</td>
                            <td class="">{{ $store.state.auth.profile.fakultas }}</td>
                          </tr>
                          <tr>
                            <td class="w-80">Semester saat ini</td>
                            <td class="w-4">:</td>
                            <td class="">{{ $store.state.auth.profile.semester_ini }}</td>
                          </tr>
                          <tr>
                            <td class="w-80">IPK</td>
                            <td class="w-4">:</td>
                            <td class="">{{ $store.state.auth.profile.ipk }}</td>
                          </tr>
                          <tr>
                            <td class="w-80">jumlah SKS yang sudah ditempuh dan lulus</td>
                            <td class="w-4">:</td>
                            <td class="">{{ $store.state.auth.profile.sks }}</td>
                          </tr>
                          <tr>
                            <td class="w-80">nama Koordinator PT MSIB</td>
                            <td class="w-4">:</td>
                            <td class="">Jubaidi Jubaidi, SE</td>
                          </tr>
                          <tr>
                            <td class="w-80">nomor no hp Koordinator PT</td>
                            <td class="w-4">:</td>
                            <td class="">-</td>
                          </tr>
                          <tr>
                            <td class="w-80">e-mail Koordinator PT</td>
                            <td class="w-4">:</td>
                            <td class="">jub141@umkt.ac.id</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div>
                      untuk menjadi peserta program Magang dan Studi Independen Bersertifikat Angkatan 5 Tahun 2023 dan
                      menyatakan bahwa mahasiswa tersebut terdaftar sebagai mahasiswa aktif pada semester yang berjalan
                      dan
                      belum yudisium.
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-2 py-5 px-5 bg-white">
                <div>
                  Sebagai bentuk dukungan dan fasilitasi bagi mahasiswa, kami menyatakan kesediaan untuk:
                </div>
                <ol class="px-3">
                  <li>
                    <span class="font-bold mr-5">1.</span>Memberikan pengakuan hingga 20 sks.
                  </li>
                  <li>
                    <span class="font-bold mr-5">2.</span>Mendukung proses belajar mahasiswa melalui pengalaman Magang
                    dan
                    Studi Independen Bersertifikat sesuai dengan ketentuan dalam juknis program magang dan studi
                    independen
                    bersertifikat.
                  </li>
                </ol>
                Demikian surat rekomendasi ini kami sampaikan untuk dipergunakan sebagaimana mestinya.
                <div class="flex justify-center px-10 text-xs text-center mt-20 px-14">
                  <div class="my-auto ml-auto">
                    <div>
                      {{ getHariIni() }}
                    </div>
                    <div class="mt-5">Kemahasiswaan</div>
                    <div class="mt-10 text-gray-500">TTD dan Cap</div>
                    <div class="mt-10">
                      <div>
                        <div>
                          <!-- {{ this.form_rekomendasi_merdeka.nama_ttd }} -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full text-center">
                  Mengetahui dan menyetujui
                </div>
                <div class="flex px-10 text-xs text-center mt-20 px-14">
                  <div class="my-auto ml-auto">
                    <div>
                      Orang Tua yang menyatakan,
                    </div>
                    <div class="mt-10 text-gray-500">Materai asli 10.000</div>
                    <div class="mt-10">
                      <div>
                        <div>
                          <div>.....................................................................</div>
                          (Nama Orang Tua/Wali Mahasiswa)
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="my-auto ml-auto">
                    <div>
                      Pimpinan Perguruan Tinggi
                    </div>
                    <div class="mt-5 w-60">
                      Rektor/ Wakil rektor/ Direktur/ Wakil Direktur/ Dekan/ Wakil Dekan Perguruan Tinggi
                    </div>
                    <div class="mt-10 text-gray-500">TTD dan Cap</div>
                    <div class="mt-10">
                      <div>
                        <div>
                          <div>........................................................................</div>
                          <div>NIP ................................................................</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </vue-html2pdf>
        </div>
      </div>
      <div v-if="!rekomendasi_merdeka.id" class="overflow-y-scroll bg-gray-400">
        <div class="h-[35rem] px-10">
          <vue-html2pdf :show-layout="false" :float-layout="false" :enable-download="false" :preview-modal="false"
                        :paginate-elements-by-height="2000" filename="Surat_Rekomendasi_MSIB" :pdf-quality="2"
                        :manual-pagination="false" pdf-format="a4"
                        pdf-orientation="portrait" pdf-content-width="800px" class="h-full" ref="rekomendasi_merdeka">
            <section class="" slot="pdf-content">
              <div class="px-5 py-5 bg-white">
                <table class="w-full px-52">
                  <div class="flex">
                    <div class="w-1/2">
                      <div class="flex space-x-2">
                        <img class="w-32" src="@/assets/logo-umkt.png" alt="logo umkt">
                        <div class="m-auto">
                          <span style="font-size: 50px; font-family: 'Times New Roman';">UMKT</span><br>
                          <span style="font-size: 15px;">
                            <div class="-mt-2" style="font-family: Arial; font-size: 18px; font-weight: bolder">
                              Universitas Muhammadiyah
                            </div>
                            <div class="-mt-2" style="font-family: Arial; font-size: 18px; font-weight: bolder">
                              Kalimantan Timur
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="flex w-1/2">
                      <div class="my-auto ml-auto text-right">
                        <div>
                          Kampus 1 : Jl. Ir. H. Juanda, No. 15, Samarinda
                        </div>
                        <div>
                          Kampus 2 : Jl. Pelita, Pesona Mahakam, Samarinda
                        </div>
                        <div>
                          Telp. 0541748511 Fax. 0541-766832
                        </div>
                      </div>
                    </div>
                  </div>
                </table>
                <div class="w-full" style="height: 2px; background-color: #eeb822"></div>
                <div class="w-full" style="height: 2px; background-color: #334164"></div>
                <div class="text-center w-full font-bold">
                  <div class="m-auto w-[38rem] ">
                    SURAT REKOMENDASI MAHASISWA PROGRAM MAGANG DAN STUDI INDEPENDEN BERSERTIFIKAT KAMPUS MERDEKA
                  </div>
                  <div class="m-auto w-72 text-center uppercase font-bold">NO :</div>
                </div>
                <div class="">
                  <div>
                    Yang bertanda tangan dibawah ini :
                    <div class="px-10 my-5">
                      <table>
                        <tr>
                          <td class="w-52">Nama</td>
                          <td class="w-4">:</td>
                          <td class="">Ghozali MH, M.Kes, Ph.D</td>
                        </tr>
                        <tr>
                          <td class="w-52">Jabatan</td>
                          <td class="w-4">:</td>
                          <td class="">Wakil Rektor I</td>
                        </tr>
                        <tr>
                          <td class="w-52">NIP/NIDN</td>
                          <td class="w-4">:</td>
                          <td class="">1114077102</td>
                        </tr>
                        <tr>
                          <td class="w-52">e-mail</td>
                          <td class="w-4">:</td>
                          <td class="">gm760@umkt.ac.id</td>
                        </tr>
                        <tr>
                          <td class="w-52">no. Telp./HP</td>
                          <td class="w-4">:</td>
                          <td class=""> 0811587750</td>
                        </tr>
                      </table>
                    </div>
                    memberikan rekomendasi kepada mahasiswa berikut :
                    <div>
                      <div class="px-10 my-5">
                        <table>
                          <tr>
                            <td class="w-80">Nama lengkap sesuai KTP</td>
                            <td class="w-4">:</td>
                            <td class="">{{ $store.state.auth.profile.nama }}</td>
                          </tr>
                          <tr>
                            <td class="w-80">NIK</td>
                            <td class="w-4">:</td>
                            <td class="">{{ $store.state.auth.profile.nik }}</td>
                          </tr>
                          <tr>
                            <td class="w-80">NIM</td>
                            <td class="w-4">:</td>
                            <td class="">{{ $store.state.auth.profile.nim }}</td>
                          </tr>
                          <tr>
                            <td class="w-80">Program studi/Jurusan</td>
                            <td class="w-4">:</td>
                            <td class="">{{ $store.state.auth.profile.prodi }}</td>
                          </tr>
                          <tr>
                            <td class="w-80">Fakultas</td>
                            <td class="w-4">:</td>
                            <td class="">{{ $store.state.auth.profile.fakultas }}</td>
                          </tr>
                          <tr>
                            <td class="w-80">Semester saat ini</td>
                            <td class="w-4">:</td>
                            <td class="">{{ $store.state.auth.profile.semester_ini }}</td>
                          </tr>
                          <tr>
                            <td class="w-80">IPK</td>
                            <td class="w-4">:</td>
                            <td class="">{{ $store.state.auth.profile.ipk }}</td>
                          </tr>
                          <tr>
                            <td class="w-80">jumlah SKS yang sudah ditempuh dan lulus</td>
                            <td class="w-4">:</td>
                            <td class="">{{ $store.state.auth.profile.sks }}</td>
                          </tr>
                          <tr>
                            <td class="w-80">nama Koordinator PT MSIB</td>
                            <td class="w-4">:</td>
                            <td class="">Jubaidi Jubaidi, SE</td>
                          </tr>
                          <tr>
                            <td class="w-80">nomor no hp Koordinator PT</td>
                            <td class="w-4">:</td>
                            <td class="">-</td>
                          </tr>
                          <tr>
                            <td class="w-80">e-mail Koordinator PT</td>
                            <td class="w-4">:</td>
                            <td class="">jub141@umkt.ac.id</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div>
                      untuk menjadi peserta program Magang dan Studi Independen Bersertifikat Angkatan 5 Tahun 2023 dan
                      menyatakan bahwa mahasiswa tersebut terdaftar sebagai mahasiswa aktif pada semester yang berjalan
                      dan
                      belum yudisium.
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-2 py-5 px-5 bg-white">
                <div>
                  Sebagai bentuk dukungan dan fasilitasi bagi mahasiswa, kami menyatakan kesediaan untuk:
                </div>
                <ol class="px-3">
                  <li>
                    <span class="font-bold mr-5">1.</span>Memberikan pengakuan hingga 20 sks.
                  </li>
                  <li>
                    <span class="font-bold mr-5">2.</span>Mendukung proses belajar mahasiswa melalui pengalaman Magang
                    dan
                    Studi Independen Bersertifikat sesuai dengan ketentuan dalam juknis program magang dan studi
                    independen
                    bersertifikat.
                  </li>
                </ol>
                Demikian surat rekomendasi ini kami sampaikan untuk dipergunakan sebagaimana mestinya.
                <div class="flex justify-center px-10 text-xs text-center mt-20 px-14">
                  <div class="my-auto ml-auto">
                    <div>
                      {{ getHariIni() }}
                    </div>
                    <div class="mt-5">Kemahasiswaan</div>
                    <div class="mt-10 text-gray-500">TTD dan Cap</div>
                    <div class="mt-10">
                      <div>
                        <div>
                          <!-- {{ this.form_rekomendasi_merdeka.nama_ttd }} -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full text-center">
                  Mengetahui dan menyetujui
                </div>
                <div class="flex px-10 text-xs text-center mt-20 px-14">
                  <div class="my-auto ml-auto">
                    <div>
                      Orang Tua yang menyatakan,
                    </div>
                    <div class="mt-10 text-gray-500">Materai asli 10.000</div>
                    <div class="mt-10">
                      <div>
                        <div>
                          <div>.....................................................................</div>
                          (Nama Orang Tua/Wali Mahasiswa)
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="my-auto ml-auto">
                    <div>
                      Pimpinan Perguruan Tinggi
                    </div>
                    <div class="mt-5 w-60">
                      Rektor/ Wakil rektor/ Direktur/ Wakil Direktur/ Dekan/ Wakil Dekan Perguruan Tinggi
                    </div>
                    <div class="mt-10 text-gray-500">TTD dan Cap</div>
                    <div class="mt-10">
                      <div>
                        <div>
                          <div>........................................................................</div>
                          <div>NIP ................................................................</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </vue-html2pdf>
        </div>
      </div>
      <div>
        <button v-if="!rekomendasi_merdeka.id" @click="insertFormRekomendasiMerdeka()"
                class="bg-emerald-500 col-start-1 h-9 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
          Ajukan
        </button>
      </div>
    </ModalComponent>
  </div>
</template>

<script>
import MacCardComponent from "@/components/widget/MacCardComponent";
import VueHtml2pdf from 'vue-html2pdf'
import axios from "axios";
import ModalComponent from "@/components/widget/Modal/ModalComponent";

export default {
  name: "AdministrasiPage",
  data() {
    return {
      form_rekomendasi: {
        nama: this.$store.state.auth.profile.nama,
        nim: this.$store.state.auth.profile.nim,
        program_studi: this.$store.state.auth.profile.prodi,
        spp_tetap: null,
        spp_variabel: null
      },
      form_pernyataan: {
        nama: this.$store.state.auth.profile.nama,
        tempat_tgl_lahir: this.$store.state.auth.profile.ttl,
        alamat: this.$store.state.auth.profile.alamat,
        nim: this.$store.state.auth.profile.nim,
        tahun_akademik: this.$store.state.auth.profile.semester_awal,
        fakultas: this.$store.state.auth.profile.fakultas,
        program_studi: this.$store.state.auth.profile.prodi,
        jenjang_program: this.$store.state.auth.profile.jenjang_program,
        semester: this.$store.state.auth.profile.semester_ini
      },
      form_sptjm: {
        nama: this.$store.state.auth.profile.nama,
        perguruan_tinggi: "Universitas Muhammadiyah Kalimantan Timur",
        program_studi: this.$store.state.auth.profile.nama,
        nim: this.$store.state.auth.profile.nim,
        nik: this.$store.state.auth.profile.nik,
        no_hp: this.$store.state.auth.profile.ponsel,
        email: this.$store.state.auth.profile.email
      },
      form_rekomendasi_merdeka: {
        email_koor_pt: null,
        nama_koor_pt: null,
        ponsel_koor_pt: null
      },
      rekomendasi_merdeka: {
        email_koor_pt: null,
        nama_koor_pt: null,
        ponsel_koor_pt: null,
      },
      staff: {
        nama: null,
        jabatan: null,
        nip: null,
        email: null,
        no_hp: null,
      },
      prestasi: []
    }
  },
  components: {
    ModalComponent,
    MacCardComponent,
    VueHtml2pdf
  },
  mounted() {
  },
  methods: {
    generateRekomendasi() {
      this.$refs.rekomendasi.generatePdf()
    },
    generateKeterangan() {
      this.$refs.keterangan.generatePdf()
    },
    generateSPTJM: function () {
      this.$refs.sptjm.generatePdf()
      // this.$store.commit('openModal', process.env.VUE_APP_BASE_URL + '/beasiswa/beasiswa-mahasiswa/InsertSuratSPTJM/')
    },
    insertFormRekomendasiMerdeka: function () {
      axios.post(process.env.VUE_APP_BASE_URL + '/administrasi/kampus-merdeka/insertKampusMerdeka/', {
        nim: this.$store.state.auth.uid,
        nama_koor_pt: this.form_rekomendasi_merdeka.nama_koor_pt,
        ponsel_koor_pt: this.form_rekomendasi_merdeka.ponsel_koor_pt,
        email_koor_pt: this.form_rekomendasi_merdeka.email_koor_pt
      }, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
          .catch(e => {
            console.log(e)
          })
          .then(resp => {
            console.log(resp)
          })
          .finally(() => {
            this.$refs.rekomendasi_merdeka.generatePdf()
          })
    },
    deleteMahasiswa: function (key) {
      if (key != 0) {
        this.$store.commit('deleteMahasiswa', key)
      }
    },
    getHariIni: function () {
      const arrbulan = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
      const date = new Date();
      const tanggal = date.getDate();
      const bulan = date.getMonth();
      const tahun = date.getFullYear();
      return this.getNamaHari(date, "in-IN") + ', ' + tanggal + " " + arrbulan[bulan] + ", " + tahun
    },
    getNamaHari: function (dateStr, locale) {
      var date = new Date(dateStr);
      return date.toLocaleDateString(locale, {weekday: 'long'});
    },
    openModal: function () {
      // this.insertFormRekomendasiMerdeka()
      this.form = {}
      this.disabled = true
      // if (this.rekomendasi_merdeka.id) {
      // this.$refs.rekomendasi_merdeka.generatePdf()
      // } else {
      this.$store.commit('openModal', null)
      // this.$refs.rekomendasi_merdeka.generatePdf()
      // }
      // axios.get(process.env.VUE_APP_BASE_URL + '/prestasi/prestasi-mahasiswa/' + url + '/')
      // this.$store.dispatch('getDataModal', process.env.VUE_APP_BASE_URL + '/prestasi/prestasi-mahasiswa/' + url + '/')
      //   .catch(e => console.log(e))
      //   .then(resp => {
      //     this.form = resp.data
      //   })
    },
  },
}
</script>

<style scoped>
#jlbg {
  color: white;
  font-weight: bold;
  border-top: 4px solid;
  border-color: #081247;
  border-bottom: 4px solid;
  border-color: #081247;
  border-right: 4px solid;
  border-color: #081247;
  background-color: #081247;
}

#tb_isi {
  width: 0;
}

#tb_isi td {
  border: 1px solid #000;
}

#tb_isi tr td:last-child {
  width: 1%;
  white-space: nowrap;
}

#logo-rektor {
  width: 230px;
  padding: 2px;
  height: 90px;
}

#bg-rektor {
  width: 300px;
  vertical-align: top;
}
</style>