<template>
  <div class="min-h-screen px-2 md:px-10 py-5">
    <div class="flex">
      <button @click="activeFormThread"
        class="bg-blue-600 hover:bg-blue-500 transition-all duration-200 ease-in-out text-white border px-3 py-1 rounded-xl mr-3">
        Start Thread
      </button>
      <button @click="showData('inbox')"
        :class="this.showdata == 'inbox' ? 'bg-indigo-700 text-white' : 'text-gray-500'"
        class="border px-3 py-1 rounded-l-xl flex gap-2">
        <font-awesome-icon :class="this.showdata == 'inbox' ? 'text-white' : 'text-indigo-700'" class="m-auto mr-2"
          icon="fas fa-envelope-open" />
        Inbox
        <div class="bg-indigo-600 rounded-full text-white w-6 h-6 p-0.5 text-sm">{{ this.inbox ? this.inbox.length : 0
          }}
        </div>
      </button>
      <button @click="showData('sent')" :class="this.showdata == 'sent' ? 'bg-blue-500 text-white' : 'text-gray-500'"
        class="border border-r-0 border-l-0 px-3 py-1">
        <font-awesome-icon :class="this.showdata == 'sent' ? 'text-white' : 'text-blue-500'" class="m-auto mr-2"
          icon="fas fa-envelope" />
        Sent
      </button>
      <button @click="showData('trash')" class="text-gray-500 border px-3 py-1 rounded-r-xl">
        <font-awesome-icon class="text-red-500 mr-2" icon="fas fa-trash" />
        Trash
      </button>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-8 gap-5 my-5">
      <div v-if="this.showdata === 'inbox'" class="md:col-span-3 space-y-3">
        <div v-for="(item, index, key) in this.inbox" :key="key" @click="getThread(item.id)"
          class="group transition-all duration-200 cursor-pointer border rounded-xl bg-white hover:bg-gray-100 shadow-lg px-5 py-3 flex">
          <img class="h-12 w-12 mr-2 md:mr-4 rounded-full"
            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt="">
          <div class="pt-1 w-full">
            <div class="flex justify-between mb-4">
              <div>
                <h1 class="font-semibold leading-4">{{ item.mahasiswa.name }}</h1>
                <span class="text-sm">{{ item.mahasiswa.id }}</span>
              </div>
              <h1 class="text-xs">{{ item.created_at }}</h1>
            </div>
            <div class="bg-gray-100 transition-all duration-200 group-hover:bg-gray-50 p-2 rounded-xl">
              <h2 class="font-bold">{{ item.title }}</h2>
              <p class="my-2 h-20 overflow-hidden leading-5 text-sm text-gray-600">
                {{ item.comments[0].comment }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.showdata === 'trash'" class="md:col-span-3 space-y-3">
        <div v-for="(item, index, key) in this.trash" :key="key" @click="getThread(item.id)"
          class="group transition-all duration-200 cursor-pointer border rounded-xl bg-rose-400 hover:bg-rose-300 shadow-lg px-5 py-3 flex">
          <img class="h-12 w-12 mr-2 md:mr-4 rounded-full"
            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt="">
          <div class="pt-1 w-full">
            <div class="flex justify-between mb-4">
              <div>
                <h1 class="font-semibold leading-4">{{ item.mahasiswa.name }}</h1>
                <span class="text-sm">{{ item.mahasiswa.id }}</span>
              </div>
              <h1 class="text-xs">{{ item.created_at }}</h1>
            </div>
            <div class="bg-rose-200 transition-all duration-200 group-hover:bg-rose-100 p-2 rounded-xl">
              <h2 class="font-bold">{{ item.title }}</h2>
              <p class="my-2 h-20 overflow-hidden leading-5 text-sm text-gray-600">
                {{ item.comments[0].comment }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.showdata === 'sent'" class="md:col-span-3 space-y-3">
        <div v-for="(item, index, key) in this.sent" :key="key" @click="getThread(item.id)"
          class="group transition-all duration-200 cursor-pointer border rounded-xl bg-white hover:bg-gray-100 shadow-lg px-5 py-3 flex">
          <img class="h-12 w-12 mr-2 md:mr-4 rounded-full"
            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt="">
          <div class="pt-1 w-full">
            <div class="flex justify-between mb-4">
              <div>
                <h1 class="font-semibold leading-4">{{ item.mahasiswa.name }}</h1>
                <span class="text-sm">{{ item.mahasiswa.id }}</span>
              </div>
              <h1 class="text-xs">{{ item.created_at }}</h1>
            </div>
            <div class="bg-gray-100 transition-all duration-200 group-hover:bg-gray-50 p-2 rounded-xl">
              <h2 class="font-bold">{{ item.title }}</h2>
              <p class="my-2 h-20 overflow-hidden leading-5 text-sm text-gray-600">
                {{ item.comments[0].comment }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--       Not Support for Mobile Version         -->
      <div class="hidden md:block md:col-span-5">
        <div v-if="detailmail.title" class="border rounded-xl bg-whited shadow-lg px-5 py-3 flex">
          <img class="h-12 w-12 mr-2 md:mr-4 rounded-full"
            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt="">
          <div class="pt-1 w-full">
            <div class="flex justify-between mb-5">
              <div>
                <h1 class="text-lg font-bold leading-4">{{ this.detailmail.from_user.name }}</h1>
                <span class="text-sm">{{ this.detailmail.from_user.id }}</span>
              </div>
              <h1 class="text-xs">{{ this.detailmail.created_at }}</h1>
              <button @click="deleteThread(detailmail.id)" class="w-8 h-8 bg-red-500 rounded-lg text-white">
                <font-awesome-icon icon="fas fa-trash" />
              </button>
            </div>
            <h2 class="text-xl font-bold mb-3">{{ this.detailmail.title }}</h2>
            <div v-for="(item, index, key) in this.detailmail.comments" :key="key" class="p-2 rounded-xl my-5"
              :class="item.user.id == $store.state.auth.uid ? 'bg-blue-400' : 'bg-gray-300'">
              <p class="my-2 overflow-hidden leading-5 text-sm font-medium"
                :class="item.user.id == $store.state.auth.uid ? 'text-white' : 'text-gray-600'"
                v-if="item.comment != 'null'">
                {{ item.comment }}
              </p>
              <div class="grid grid-cols-5 gap-5 pt-10">
                <div v-for="(item, index, key) in item.file" :key="key"
                  :class="item.file ? item.file.split('.').pop() == 'jpg' || item.file.split('.').pop() == 'jpeg' || item.file.split('.').pop() == 'png' ? '' : 'col-start-1 col-span-3' : item.file_url.split('.').pop() == 'jpg' || item.file_url.split('.').pop() == 'jpeg' || item.file_url.split('.').pop() == 'png' ? '' : 'col-start-1 col-span-3'">
                  <!--                  <img @click="openImage(item.file)"-->
                  <!--                       class="transition-all duration-500 bg-gray-100 cursor-pointer object-cover filter hover:grayscale h-32 w-full"-->
                  <!--                       :src="item.file"-->
                  <!--                       v-if="item.file">-->
                  <div v-if="item.file_url">
                    <img @click="openImage(item.file_url)"
                      class="transition-all duration-500 bg-gray-100 cursor-pointer object-cover filter hover:grayscale h-32 w-full"
                      :src="item.file_url"
                      v-if="item.file_url.split('.').pop() == 'jpg' || item.file_url.split('.').pop() == 'jpeg' || item.file_url.split('.').pop() == 'png'">
                    <a :href="item.file_url" target="_blank"
                      class="transition-all rounded-lg px-4 duration-500 bg-blue-500 cursor-pointer object-cover filter hover:bg-blue-400 w-full"
                      :src="item.file_url"
                      v-if="item.file_url.split('.').pop() == 'pdf' || item.file_url.split('.').pop() == 'docx'">
                      <font-awesome-icon class="text-white mr-1" icon="fa-solid fa-file" />
                      <span class="text-sm text-white">
                        {{ item.file_url.split('/').pop() }}
                      </span>
                    </a>
                  </div>
                  <div v-if="item.file">
                    <img @click="openImage(item.file)"
                      class="transition-all duration-500 bg-gray-100 cursor-pointer object-cover filter hover:grayscale h-32 w-full"
                      :src="item.file"
                      v-if="item.file.split('.').pop() == 'jpg' || item.file.split('.').pop() == 'jpeg' || item.file.split('.').pop() == 'png'">
                    <a :href="item.file" target="_blank"
                      class="transition-all rounded-lg px-4 duration-500 bg-blue-500 cursor-pointer object-cover filter hover:bg-blue-400 w-full"
                      :src="item.file"
                      v-if="item.file.split('.').pop() == 'pdf' || item.file.split('.').pop() == 'docx'">
                      <font-awesome-icon class="text-white mr-1" icon="fa-solid fa-file" />
                      <span class="text-sm text-white">
                        {{ item.file.split('/').pop() }}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex mt-10 mb-5">
              <input type="email" v-model="form.comment"
                class="flex-1 block border border-r-0 w-full rounded-l-xl focus:outline-none px-3 pt-2 pb-1"
                placeholder="Search what you want to know">
              <button @click="activeImages"
                class="inline-flex items-center border-x-0 border border-l-0 text-white w-7">
                <font-awesome-icon class="text-gray-600 text-sm m-auto" icon="fa-solid fa-images" />
              </button>
              <button @click="insertComment(detailmail.id)"
                class="inline-flex items-center rounded-r-xl border text-white w-12">
                <font-awesome-icon class="text-gray-600 text-xl m-auto" icon="fa-solid fa-paper-plane" />
              </button>
            </div>
            <div class="border rounded-xl p-2" v-if="activeImage">
              <InputPictureMultiple class="as" name="images" @file="insertFoto" :erase="erase" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalComponent>
      <div class="w-full h-96 bg-gray-400 flex rounded-xl overflow-hidden" v-if="activeImageItem">
        <img class="h-96 m-auto" :src="activeImageItem" />
      </div>
      <div>
        <div class="flex space-x-2">
          <div class="w-1/6">
            Sent to:
          </div>
          <div class="flex w-5/6" @click="openSelect(false)">
            <img class="h-12 w-12 mr-2 md:mr-4 rounded-full"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt="">
            <div class="pt-1 w-full">
              <div class="justify-between mb-4 w-full">
                <div class="relative w-full bg-gray-200 rounded-lg p-2 gap-3">
                  <div class="h-10 group w-full">
                    <div class="flex h-full relative w-full">
                      <input @input="searchInput" v-model="search" placeholder=""
                        class="w-full text-md px-1.5 pt-2 pb-1.5 font-thin rounded-md peer outline-none border-b-2 border-b-blue-800/ focus:border-b-blue-800/100 transition-all ease-in-out">
                      <label tabindex="1"
                        :class="search ? 'text-black -translate-y-8' : 'select-none text-gray-400 cursor-text peer-focus:text-black peer-focus:-translate-y-8'"
                        class="text-sm absolute px-2 h-full flex items-center transition-all ease-in-out">Dosen</label>
                    </div>
                    <!--                    <SearchInputComponent @search="searchInput"  class="col-span-2"-->
                    <!--                                          name="search" label="Search"-->
                    <!--                                          type="text" color="bg-color-white"/>-->
                  </div>
                  <div class="top-10">
                    <select @change="clickSelected" :hidden="openselect" v-model="selectedOption"
                      class='border px-1 w-full' :size="filtering ? dosenfilter.length + 1 : 5">
                      <option v-for="(option, key) in dosenfilter" :key="key" :value="option.username">{{
                        option.nama_bergelar }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <input placeholder="Perihal" type="text"
          class="flex-1 block border border-r-0 w-full mt-5 rounded-l-xl focus:outline-none px-3 pt-2 pb-1"
          v-model="form.title">
        <div class="mt-5">
          <div>
            <input type="radio" name="type" value="1" v-model="form.type">
            Akademis
          </div>
          <div>
            <input type="radio" name="type" value="2" v-model="form.type">
            Non Akademis
          </div>
        </div>
        <div class="flex mt-10 mb-5">
          <textarea type="email" v-model="form.comment"
            class="flex-1 block border border-r-0 w-full rounded-l-xl focus:outline-none px-3 pt-2 pb-1 h-20 resize-none"
            placeholder="Komentar anda"> </textarea>
          <button @click="activeImages" class="inline-flex items-center border-x-0 border border-l-0 text-white w-7">
            <font-awesome-icon class="text-gray-600 text-sm m-auto" icon="fa-solid fa-images" />
          </button>
          <button :disabled="this.loadingThread" @click="startThread"
            class="inline-flex items-center rounded-r-xl border text-white w-12">
            <font-awesome-icon class="text-gray-600 text-xl m-auto"
              :icon="this.loadingThread ? 'fa-solid fa-spinner' : ' fa-solid fa-paper-plane'" />
          </button>
        </div>
        <div class="border rounded-xl p-2" v-if="activeImage">
          <InputPictureMultiple class="as" name="images" @file="insertFoto" :erase="erase" />
        </div>
      </div>
    </ModalComponent>
  </div>
</template>

<script>
import axios from "axios";
import InputPictureMultiple from "@/components/widget/form/InputPictureMultiple";
import ModalComponent from "@/components/widget/Modal/ModalComponent";

export default {
  name: "BimbinganPage",
  data() {
    return {
      dosenlist: null,
      dosenfilter: null,
      selectedOption: null,
      filtering: false,
      openselect: true,
      search: null,
      dosen: {
        id: null,
        name: null
      },
      datamail: [],
      showdata: 'inbox',
      sent: null,
      inbox: null,
      detailmail: {
        id: null,
        title: null,
        from_user: {
          id: null,
          name: null,
          image: null,
        },
        date: null,
        comments: [],

      },
      page: 1,
      activeImage: false,
      openImages: false,
      form: {
        comment: null,
        title: null,
        images: null,
        type: null,
        date: null
      },
      formThread: false,
      pastThread: false,
      activeImageItem: null,
      erase: false,
      loadingThread: false
    }
  },
  components: {
    InputPictureMultiple,
    ModalComponent
  },
  methods: {
    openSelect: function (status) {
      this.openselect = status;
    },
    clickSelected: function () {
      this.search = this.selectedOption
    },
    searchInput: function () {
      if (this.search.length >= 3) {
        this.filtering = true
        this.dosenfilter = this.dosenlist.filter((item) => {
          if (item.nama_bergelar) {
            return item.nama_bergelar.toUpperCase().includes(this.search.toUpperCase())
          }
        })
      } else {
        this.filtering = false
        return this.dosenfilter = this.dosenlist
      }
    },
    getListDosen: function () {
      // axios.post(process.env.VUE_APP_BASE_URL + '/api/users/insert_get_api_umkt_notoken/', { url: '/pegawai-notoken/', token: '95d6a4e57c47891c96d8c20ce7feecee8ffc25d07abd9751b189224b252e32c0' })
      //   .catch(e => console.log(e))
      //   .then(resp => {
      //     console.log(resp)
      this.dosenlist = [
        {
          username: "aa287",
          nama_bergelar: "Arahman arahman"
        },
        {
          username: "aa443",
          nama_bergelar: "Ari Athfin, S.T."
        },
        {
          username: "aa831",
          nama_bergelar: "dr. Ashan Azis, Sp.I."
        },
        {
          username: "aa858",
          nama_bergelar: "Azmi Amalia, S.K.M."
        },
        {
          username: "aafr597",
          nama_bergelar: "Ns. Alfi Ari Fakhrur Rizal, S.Kep, M.Kep"
        },
        {
          username: "aan210",
          nama_bergelar: "Annisa A'yun Nursania, S.I.Kom."
        },
        {
          username: "aap284",
          nama_bergelar: "Ahmad Azmi Perkasa Alam, BA., MA"
        },
        {
          username: "ab783",
          nama_bergelar: "Ns. Arief Budiman, S.Kep, M.Kep"
        },
        {
          username: "abs564",
          nama_bergelar: "Annaas Budi Setyawan, AMD, S.Kep, M.Si.Med"
        },
        {
          username: "acs150",
          nama_bergelar: "Adde Currie Siregar, S.T., M.T"
        },
        {
          username: "ad979",
          nama_bergelar: "Andi Daramusseng, S.KM, M.Kes."
        },
        {
          username: "ade228",
          nama_bergelar: "Adenan, S.Sos"
        },
        {
          username: "ade997",
          nama_bergelar: "apt. Asri Dwi Endah Dewi Pramesthi, S.Farm., M.Pharm."
        },
        {
          username: "ae614",
          nama_bergelar: "Achmad Efendi, S.Pd., M.E., M.A.P"
        },
        {
          username: "af219",
          nama_bergelar: "Ahmad Faqih, S.S"
        },
        {
          username: "af339",
          nama_bergelar: "Almira Fahrinda, S.Ked., M.Biomed"
        },
        {
          username: "af907",
          nama_bergelar: "Apriyal Fakih, S.PdI., M.Pd."
        },
        {
          username: "af954",
          nama_bergelar: "Azhar Fathoni, S.T"
        },
        {
          username: "afa301",
          "nama_bergelar": null
        },
        {
          username: "afa302",
          nama_bergelar: "Prof. Dr. Aidul Fitriciada Azhari, S.H., M.Hum"
        },
        {
          username: "afa714",
          nama_bergelar: "dr. Achmad Ferdinan Amrullah"
        },
        {
          username: "afh467",
          nama_bergelar: "Alfiza Fakhriya Haq, S.Psi., M.Psi., Psikolog"
        },
        {
          username: "ah445",
          nama_bergelar: "Abd Hallim, S.ST., M.Kom."
        },
        {
          username: "ah545",
          nama_bergelar: "Anis Haerunisa, S.Tr.Ak."
        },
        {
          username: "ah552",
          nama_bergelar: "Dr. Abdul Halim, S.E., M.M., Ak"
        },
        {
          username: "ah918",
          nama_bergelar: "Abdul Halim, S.Pd., M.Pd., Ph.D"
        },
        {
          username: "ahbh186",
          nama_bergelar: "Aniq Hudiyah Bil Haq, S.Psi., M.A."
        },
        {
          username: "ahn597",
          nama_bergelar: "dr. Abdul Haris Nur, Sp.I."
        },
        {
          username: "ahr811",
          nama_bergelar: "Asmadhini Handayani Rahmah, S.Si, M.M."
        },
        {
          username: "air550",
          nama_bergelar: "Ade Ismail Ramadhan Hamid, S.S., M.Pd"
        },
        {
          username: "ais171",
          nama_bergelar: "Asih Isni Saputri, S.K.M."
        },
        {
          username: "aiw505",
          nama_bergelar: "Ayu Ida Wardani, S.KM"
        },
        {
          username: "ajl722",
          nama_bergelar: "Asslia Johar Latipah, S.Kom, M.Cs"
        },
        {
          username: "ak180",
          nama_bergelar: "Abdul Khoiri, S.E., M.Si"
        },
        {
          username: "ak411",
          nama_bergelar: "dr. Anton Komala, Sp.PD."
        },
        {
          username: "al824",
          nama_bergelar: "Azhar Latief, S.E., CA., M.Ak."
        },
        {
          username: "am315",
          nama_bergelar: "Prof. Dr. dr. Ambar Mudigdo, S.Ked., Sp.PA(K)"
        },
        {
          username: "am469",
          nama_bergelar: "Ahmad Musyafa, S.K.M., M.P.H."
        },
        {
          username: "am713",
          nama_bergelar: "Agus Mujianto, S.T, M.T"
        },
        {
          username: "am764",
          nama_bergelar: "Asnawi Mubarok, S.H, M.Si, M.Kn"
        },
        {
          username: "ami620",
          nama_bergelar: "dr. Andi Muhammad Idris, M.Si., Sp.I."
        },
        {
          username: "amm697",
          nama_bergelar: "dr. Andi Mustika M.Haras, M.Biomed."
        },
        {
          username: "amt545",
          nama_bergelar: "Anugrahita Melina Tri Haksami, S.T., M.Si."
        },
        {
          username: "an859",
          nama_bergelar: "Andi Nugroho, S.T., M.T"
        },
        {
          username: "anr955",
          nama_bergelar: "Azi Nur Rahmasita, S.IP., M.M"
        },
        {
          username: "ap412",
          nama_bergelar: "Aldi Pebrian, S.H."
        },
        {
          username: "ap749",
          nama_bergelar: "Abi Prakasa, S.Kep."
        },
        {
          username: "apk295",
          nama_bergelar: "Amallia Putri Kartika Sari, S.Psi., M.Psi., Psikolog"
        },
        {
          username: "aps273",
          nama_bergelar: "Ns. Andri Praja Satria, S.Kep, M.Biomed."
        },
        {
          username: "apw561",
          nama_bergelar: "Asriani Permata Wahid, S.M."
        },
        {
          username: "ar152",
          nama_bergelar: "Ainur Rachman, S.K.M, M.Kes"
        },
        {
          username: "ar622",
          nama_bergelar: "Abdul Rahim, S.Kom., M.Cs"
        },
        {
          username: "ar784",
          nama_bergelar: "Abdul Rachim, S.P., M.P."
        },
        {
          username: "arb381",
          nama_bergelar: "Arbansyah Arbansyah, S.Kom, M.T.I."
        },
        {
          username: "ard512",
          nama_bergelar: "Ardiansyah, S.T, M.T"
        },
        {
          username: "ard597",
          nama_bergelar: "Ardaniah ardaniah, S.P., M.P."
        },
        {
          username: "arr700",
          nama_bergelar: "dr. Aditya Rahman Rosean Yudistira"
        },
        {
          username: "aru387",
          nama_bergelar: "Dr. Dra. Anis Rachma Utary, S.E., M.Si., Ak., CA."
        },
        {
          username: "as169",
          nama_bergelar: "dr. Agus Sukaca, M.Kes."
        },
        {
          username: "as272",
          nama_bergelar: "Prof. Ali Saukah, M.A., Ph.D"
        },
        {
          username: "as350",
          nama_bergelar: "Anita Shalehah, S.IP, M.A"
        },
        {
          username: "as610",
          nama_bergelar: "Ajis Saputra"
        },
        {
          username: "as663",
          nama_bergelar: "Agus Subroto, S.Pd"
        },
        {
          username: "as888",
          nama_bergelar: "Ahmad Suparno, S.H"
        },
        {
          username: "as892",
          nama_bergelar: "Arip Saripudin, S.Pd.I., M.A"
        },
        {
          username: "as922",
          nama_bergelar: "Dr. dr. Amir Syafruddin, M.Med.Ed."
        },
        {
          username: "asb407",
          nama_bergelar: ""
        },
        {
          username: "ash538",
          nama_bergelar: "Dr. dr. Andi Sofyan Hasdam, Sp.N."
        },
        {
          username: "ask888",
          nama_bergelar: "Askiah askiah, S.E, M.Si."
        },
        {
          username: "asm267",
          nama_bergelar: "Asman Asman, S.P., M.P."
        },
        {
          username: "asn826",
          nama_bergelar: "Anis Siti Nurrohkayati, S.T, M.T."
        },
        {
          username: "at391",
          nama_bergelar: "Agus Trikoriantono"
        },
        {
          username: "at646",
          nama_bergelar: "Agus Trisihnyo, S.E, M.M"
        },
        {
          username: "atd409",
          nama_bergelar: "Alifia Trisna Dheyanti, S.E"
        },
        {
          username: "atr105",
          nama_bergelar: "Andri Tria Raharja, S.Pd, M.Pd"
        },
        {
          username: "avy598",
          nama_bergelar: "Dr. Aullia Vivi Yulianingrum, S.H, M.H"
        },
        {
          username: "aw375",
          nama_bergelar: "Arif Widiyanto, S.P., M.P."
        },
        {
          username: "ay867",
          nama_bergelar: "Ahmad Yulianto, S.KM"
        },
        {
          username: "bap254",
          nama_bergelar: "Bayu Ananda Perdana Hakim, S.Ak"
        },
        {
          username: "bi799",
          nama_bergelar: "Dr. Budi Irwansyah, S.KM., M.Kes"
        },
        {
          username: "bin279",
          nama_bergelar: "Binyamin binyamin, ST, M.T"
        },
        {
          username: "bp996",
          nama_bergelar: "Bayu Prasetyo, S.H., M.H"
        },
        {
          username: "bs260",
          nama_bergelar: "Prof. Bambang Setiaji"
        },
        {
          username: "bs858",
          nama_bergelar: "Dr. Bambang Setiawan, S.H., M.H"
        },
        {
          username: "bud759",
          nama_bergelar: "Budiman, S.Kom"
        },
        {
          username: "bur871",
          nama_bergelar: "Burhanto, S.ST, S.Pd, M.Kes"
        },
        {
          username: "by711",
          nama_bergelar: "Dr.M. Bun Yamin, S.E, M.Si"
        },
        {
          username: "cam179",
          nama_bergelar: "Ns. Camellia, S.Kep"
        },
        {
          username: "cfm782",
          nama_bergelar: "Chaerul Fadly Mochtar Luthfi M, S.Farm., M.Biomed"
        },
        {
          username: "cmd709",
          nama_bergelar: "Citra Mutiara Dewi, S.Psi."
        },
        {
          username: "cr625",
          nama_bergelar: "dr. Candra Ramadhanny, M.H., Sp.KJ."
        },
        {
          username: "dal528",
          nama_bergelar: "Dyah Ayu Lestari, S.M."
        },
        {
          username: "dam155",
          nama_bergelar: "Damingun, S.E, M.M"
        },
        {
          username: "dan295",
          nama_bergelar: "Daniatun, A.Md"
        },
        {
          username: "dap313",
          nama_bergelar: "Dea Anggraini Putri, S.M."
        },
        {
          username: "dar927",
          nama_bergelar: "DR. Darani, S.E, M.P"
        },
        {
          username: "dau475",
          nama_bergelar: "Deddy Alif Utama, S.KM, M.Kes"
        },
        {
          username: "dda721",
          nama_bergelar: "Devi Dwi Aryani, S.Pd"
        },
        {
          username: "ddd821",
          nama_bergelar: ""
        },
        {
          username: "ddd822",
          nama_bergelar: "Desita Dyah Damayanti, S.Psi, M.Psi"
        },
        {
          username: "dim417",
          nama_bergelar: "dr. Delvi Indera Mayasari, Sp.M."
        },
        {
          username: "dip923",
          nama_bergelar: "Devy Indah Paramitha, S.IP, M.Han"
        },
        {
          username: "dk658",
          nama_bergelar: "Deny Kurniawan, S.Hut, M.P"
        },
        {
          username: "dk939",
          nama_bergelar: "Dewi Kamaratih, S.Psi., M.Psi"
        },
        {
          username: "dl792",
          nama_bergelar: "Dr. Apt. Dwi Lestari, S.Farm, M.Si"
        },
        {
          username: "dmp267",
          nama_bergelar: "Dwi Murdaningsih Pangestuty, S.T, M.Cs"
        },
        {
          username: "dms843",
          nama_bergelar: ""
        },
        {
          username: "dnc332",
          nama_bergelar: "Deasy Nur Chairin Hanifa, S.Farm, Apt, M.Clin.Pha"
        },
        {
          username: "dne964",
          nama_bergelar: "Denni Nugroho Endra Maryanto, S.E."
        },
        {
          username: "dp657",
          nama_bergelar: "Desti Purnamasari, S.Psi., M.Psi."
        },
        {
          username: "dp958",
          nama_bergelar: "Dian Putriana, S.Psi., M.Psi., Psikolog"
        },
        {
          username: "dr356",
          nama_bergelar: "Dahlan Rais"
        },
        {
          username: "dr594",
          nama_bergelar: "dr. Desy Rosyiana"
        },
        {
          username: "dr650",
          nama_bergelar: "Dzul Rachman, S.Pd, M.Pd, Ph.D"
        },
        {
          username: "drf397",
          nama_bergelar: "Ns. Dwi Rahmah Fitriani, S.Kep, M.Kep"
        },
        {
          username: "ds106",
          nama_bergelar: "Dewi Sartika, S.ST, M.M"
        },
        {
          username: "ds508",
          nama_bergelar: "apt. Dedi Setiawan, S.Farm"
        },
        {
          username: "dsh452",
          nama_bergelar: "apt. Dwi Sri Handayani, M.Farm."
        },
        {
          username: "dsp746",
          nama_bergelar: "Dheka Shara Pratiwi, S.T., M.T"
        },
        {
          username: "dw530",
          nama_bergelar: "Ns. Dwi Widyastuti, S.Kep., M.Kep"
        },
        {
          username: "ea338",
          nama_bergelar: "Dr. Ery Arifullah, S.T., M.T."
        },
        {
          username: "ea820",
          nama_bergelar: "apt. Erfan Abdissalam, S.Farm., M.Farm."
        },
        {
          username: "eas126",
          nama_bergelar: "Erwin Ady Surya, S.E"
        },
        {
          username: "ee701",
          nama_bergelar: "Dr. Elviandri elviandri, S.HI., M.Hum"
        },
        {
          username: "eks194",
          nama_bergelar: ""
        },
        {
          username: "em156",
          nama_bergelar: "Eka Malada, S.Psi."
        },
        {
          username: "erw163",
          nama_bergelar: "Erindyah Retno Wikantyasning"
        },
        {
          username: "es202",
          nama_bergelar: "Ns. Enok Sureskiarti, S.Kep, M.Kep"
        },
        {
          username: "es572",
          nama_bergelar: "Eko Saputra"
        },
        {
          username: "esw796",
          nama_bergelar: "Eko Setya Wahyudi, S.E"
        },
        {
          username: "etp272",
          nama_bergelar: "Elda Trialisa Putri, S.Psi., M.Psi., Psikolog"
        },
        {
          username: "eu949",
          nama_bergelar: "Ermi Utami, S.E, M.E"
        },
        {
          username: "ews936",
          nama_bergelar: "Erni Wingki Susanti, S.K.M, M.Kes"
        },
        {
          username: "ey438",
          nama_bergelar: ""
        },
        {
          username: "ey439",
          nama_bergelar: "Eka Yulianti, A.Md.Kes."
        },
        {
          username: "fa142",
          nama_bergelar: "Ns. Fitroh Asriyadi, S.Kep, M.Kep."
        },
        {
          username: "fa428",
          nama_bergelar: "Ir. Fajar Alam, S.T, M.Ling"
        },
        {
          username: "fa444",
          nama_bergelar: "Fitriyati Agustina, S.T, M.T"
        },
        {
          username: "fal146",
          nama_bergelar: "Faldi, S.Kom, M.Ti"
        },
        {
          username: "fds844",
          nama_bergelar: "Fajar Dwi Saputro, S.T"
        },
        {
          username: "feb823",
          nama_bergelar: "Febiyani, S.Pd"
        },
        {
          username: "ff160",
          nama_bergelar: "dr. Fatmawati Fatmawati"
        },
        {
          username: "ff230",
          nama_bergelar: "Dr. Fenty Fauziah, S.E, Ak, M.Si"
        },
        {
          username: "ffr607",
          nama_bergelar: "Ferry Fadzlul Rahman, Ph.D"
        },
        {
          username: "fh732",
          nama_bergelar: "Fredi Handoko, S.M"
        },
        {
          username: "fi109",
          nama_bergelar: "Fitriana Indah, S.H, M.H"
        },
        {
          username: "fir162",
          nama_bergelar: "Ir. Firdaus firdaus, M.M."
        },
        {
          username: "fir421",
          nama_bergelar: "Firman firman, S.P., M.Ling."
        },
        {
          username: "fit217",
          nama_bergelar: "Dr.M. Fitriansyah, S.T, M.M"
        },
        {
          username: "fj755",
          nama_bergelar: "Fikri Jufri"
        },
        {
          username: "fk674",
          nama_bergelar: "Fandi Kharisma, S.E, Ak, M.Sc"
        },
        {
          username: "fma840",
          nama_bergelar: "dr. Fatma Maulida Abiya, MMR."
        },
        {
          username: "fp562",
          nama_bergelar: "dr. Fajar Prabowo, Sp.S."
        },
        {
          username: "fr221",
          nama_bergelar: "Fauzi Rahman, S.Farm, Apt"
        },
        {
          username: "frh934",
          nama_bergelar: "Ns. Faried Rahman Hidayat, S.Kep, M.Kes"
        },
        {
          username: "fs316",
          nama_bergelar: "Fazri Suhada"
        },
        {
          username: "fy415",
          nama_bergelar: "Fendy Yulianto, S.Kom., M.Kom."
        },
        {
          username: "fz658",
          nama_bergelar: "Ns. Fatma Zulaikha, S.Kep, M.Kep."
        },
        {
          username: "ga154",
          nama_bergelar: "Gunawan Ariyanto, S.T., M.Comp.Sc., Ph.D"
        },
        {
          username: "gm760",
          nama_bergelar: "Ghozali MH, M.Kes, Ph.D"
        },
        {
          username: "gmr651",
          nama_bergelar: "Gilang Mukti Rukmana, S.IP, M.Hub.Int"
        },
        {
          username: "gnh192",
          nama_bergelar: "Gusti Nurul Husna, S.KM"
        },
        {
          username: "gp681",
          nama_bergelar: "Dr. Galih Priyambada, S.Pd, M.Pd"
        },
        {
          username: "gr167",
          nama_bergelar: "apt. Gina Ruqbah, S.Farm., M.FarmKlin"
        },
        {
          username: "gtw675",
          nama_bergelar: "dr. Giena Tiara Werdhianti, Sp.GK."
        },
        {
          username: "ham807",
          nama_bergelar: "Hamka hamka, S.Psi., M.Psi., Ph.D., Psikolog"
        },
        {
          username: "han440",
          nama_bergelar: "Hansen Hansen, S.KM, M.KL."
        },
        {
          username: "har186",
          nama_bergelar: "Harun "
        },
        {
          username: "has386",
          nama_bergelar: "Hudyah Astuti Sudirman, S.ST., M.M"
        },
        {
          username: "hbp606",
          nama_bergelar: "dr. Heryadi Bawono Putro, Sp.FM"
        },
        {
          username: "hdy603",
          nama_bergelar: ""
        },
        {
          username: "her638",
          nama_bergelar: "Herlina, A.Md."
        },
        {
          username: "hh241",
          nama_bergelar: "Dr. Hasyrul Hamzah, S.Farm., M.Sc"
        },
        {
          username: "hh457",
          nama_bergelar: "Hermawati Hermawati, S.M."
        },
        {
          username: "hms937",
          nama_bergelar: "dr. Hajar Mar'atussholikah Supriyanto"
        },
        {
          username: "hp112",
          nama_bergelar: "Hari Prasetyo"
        },
        {
          username: "hr625",
          nama_bergelar: "Hellina Rahmisari, S.P., M.P."
        },
        {
          username: "hs048",
          nama_bergelar: "Hendra Saputra, S.Kom., M.Kom"
        },
        {
          username: "hs472",
          nama_bergelar: "Hendra Santoso, S.T., M.Eng"
        },
        {
          username: "ht194",
          nama_bergelar: "Husni Thamrin, M.T., Ph.D."
        },
        {
          username: "htt546",
          nama_bergelar: "dr. Haken Tennizar Toena, Sp.DV."
        },
        {
          username: "htw182",
          nama_bergelar: "Hery Tri Waloyo, S.T, M.T"
        },
        {
          username: "hz831",
          nama_bergelar: "Hamada Zein, S.Kom"
        },
        {
          username: "ia114",
          nama_bergelar: "dr. Ikhsan Ali, M.Kes., Sp.A."
        },
        {
          username: "iam856",
          nama_bergelar: "Ika Ayu Mentari, S.Farm, Apt, M.Farm"
        },
        {
          username: "ibr195",
          nama_bergelar: "Ibrahim ibrahim, S.Pd, M.TESOL"
        },
        {
          username: "ic356",
          nama_bergelar: "Idham Cholid, S.E, M.M"
        },
        {
          username: "ie716",
          nama_bergelar: "Ilma Elpandari, S.P., M.P."
        },
        {
          username: "ih759",
          nama_bergelar: "Idris Harta"
        },
        {
          username: "ih787",
          nama_bergelar: "Indah Hairunisa, S.Farm, Apt, M.Biotech"
        },
        {
          username: "ikd713",
          nama_bergelar: "Intan Kinanthi Damarin Tyas, S.IP, M.A"
        },
        {
          username: "im671",
          nama_bergelar: "Ikhwanul Muslim, S.H, M.H"
        },
        {
          username: "inf427",
          nama_bergelar: "Ichsan Noor Fahmi, A.Md"
        },
        {
          username: "iny773",
          nama_bergelar: "Indiana Nawang Yuli Safitri, S.Pd."
        },
        {
          username: "is291",
          nama_bergelar: "Ihwan Susila"
        },
        {
          username: "is763",
          nama_bergelar: "dr. Isti Sundari, M.Gz."
        },
        {
          username: "isn391",
          nama_bergelar: "Isnaniah, S.E."
        },
        {
          username: "ist758",
          nama_bergelar: "Istimaroh Istimaroh, S.E., M.M., Ph.D."
        },
        {
          username: "iwn496",
          nama_bergelar: "Indah Widyah Ningsih Paloloan, S.M."
        },
        {
          username: "iz890",
          nama_bergelar: "Isnaini Zulkarnain, S.T, M.T"
        },
        {
          username: "jas970",
          nama_bergelar: "Januar Abdilah Santoso, S.Pd., M.Or"
        },
        {
          username: "jb300",
          nama_bergelar: "Dr. Ir. Joko Budiyanto, M.M."
        },
        {
          username: "jbk567",
          nama_bergelar: "Jeane Betty Kurnia Jusuf, S.Pd, M.Pd"
        },
        {
          username: "js667",
          nama_bergelar: "Dr. Joko Sabtohadi, S.E., M.M"
        },
        {
          username: "js765",
          nama_bergelar: "Jaka Subrata, S.Si"
        },
        {
          username: "jub141",
          nama_bergelar: "Jubaidi Jubaidi, SE"
        },
        {
          username: "jul196",
          nama_bergelar: "Julianur Julianur, S.Pd, M.Pd"
        },
        {
          username: "jwh794",
          nama_bergelar: "Ns. Joanggi Wiriatarina Harianto, S.Kep., M.Kep., Ph.D"
        },
        {
          username: "ka163",
          nama_bergelar: "Khoirul Amin, S.IP, M.A"
        },
        {
          username: "ka667",
          nama_bergelar: "DR. Khairul Anam, S.E, M.M"
        },
        {
          username: "kf365",
          nama_bergelar: "Kresna Febriyanto, S.KM, M.PH"
        },
        {
          username: "kh753",
          nama_bergelar: "Kuncoro Hadi, S.Kom."
        },
        {
          username: "kk645",
          nama_bergelar: "Khusnul Khatimah, S.Pd, M.Pd"
        },
        {
          username: "kn961",
          nama_bergelar: "Kardila Nurmasitah, S.K.M."
        },
        {
          username: "knt712",
          nama_bergelar: "Khodijah Nur Tsalis, Lc., MIRKH"
        },
        {
          username: "kpa520",
          nama_bergelar: "Karina Putri Alamanda, S.Psi, M.Psi"
        },
        {
          username: "kr366",
          nama_bergelar: "Kun Rawansari, S.P., M.P."
        },
        {
          username: "ks366",
          nama_bergelar: "Khanif Setiyawan, S.T, M.T"
        },
        {
          username: "ksp680",
          nama_bergelar: "Ns. Kartika Setia Purdani, S.Kep, M.Kep"
        },
        {
          username: "kum538",
          nama_bergelar: "Prof. Drs. Kumaidi, M.A., Ph.D"
        },
        {
          username: "kw245",
          nama_bergelar: "Kelik Wardiono"
        },
        {
          username: "ky732",
          nama_bergelar: "Kartika Yuniarti, S.Psi."
        },
        {
          username: "ky737",
          nama_bergelar: "Karin Yuliani, S.M."
        },
        {
          username: "la397",
          nama_bergelar: "Lincolin Arsyad"
        },
        {
          username: "lap236",
          nama_bergelar: "Lintang Aji Pangestu"
        },
        {
          username: "ldn365",
          nama_bergelar: "Linda Dwi Novial, S.Kp, M.Kep, Sp.J"
        },
        {
          username: "ldp998",
          nama_bergelar: "Listyana Dewi Prastiwi, S.Farm., M.Farm."
        },
        {
          username: "lh654",
          nama_bergelar: "Lukman Hakim, Lc., M.Pd"
        },
        {
          username: "lk959",
          nama_bergelar: "Lia Kurniasari, S.K.M, M.Kes"
        },
        {
          username: "lkn217",
          nama_bergelar: "Lorine Kalista Noor, S.M., M.M"
        },
        {
          username: "lmh927",
          nama_bergelar: ""
        },
        {
          username: "lna140",
          nama_bergelar: "Lili Nur Azizah, S.K.M."
        },
        {
          username: "lnh535",
          nama_bergelar: "apt. Lukman Nul Hakim, S.Farm., M.FarmKlin"
        },
        {
          username: "lrd504",
          nama_bergelar: "dr. Lisa Retno Dewi, M.Kes., Sp.THT-KL."
        },
        {
          username: "lwo827",
          nama_bergelar: "Lisa Wahidatul Oktaviani, S.K.M, M.P.H, Ph.D"
        },
        {
          username: "ma364",
          nama_bergelar: "Marsha Anindita, S.E, M.S.M"
        },
        {
          username: "mab402",
          nama_bergelar: "Dra. Mabruroh, M.M."
        },
        {
          username: "mab990",
          nama_bergelar: "Maryam Abu Bakar"
        },
        {
          username: "mad866",
          nama_bergelar: "Mira Ayu Dian"
        },
        {
          username: "mah364",
          nama_bergelar: "Muhammad Ali Hasim Asyari, S.P., M.Agb."
        },
        {
          username: "mar348",
          nama_bergelar: "Mardiana, S.KM, M.Kes"
        },
        {
          username: "mar850",
          nama_bergelar: "Mardiana, S.Pd.I, M.S.I"
        },
        {
          username: "mas676",
          nama_bergelar: "Mohamat Adil Sentosa, S.Kom."
        },
        {
          username: "mbs143",
          nama_bergelar: "Ns. M Bachtiar Safrudin, M.Kep, Sp.Kep.Kom"
        },
        {
          username: "md322",
          nama_bergelar: "Mariman Darto"
        },
        {
          username: "md356",
          nama_bergelar: "Ns. Mukhripah Damaiyanti, S.Kep, M.NS"
        },
        {
          username: "mda803",
          nama_bergelar: "Mady Deka Apriliya, S.ST"
        },
        {
          username: "mdaa343",
          nama_bergelar: "Mohamad Dziqie Aulia Alfarauqi, S.IP, M.A"
        },
        {
          username: "mdm974",
          nama_bergelar: "apt. Muthia Dewi Marthilia Alim, S.Farm, M.Farm"
        },
        {
          username: "me626",
          nama_bergelar: "apt. Mustika Erlinaningrum, S.Farm., M.Farm."
        },
        {
          username: "mf841",
          nama_bergelar: "Muhammad Fahrizal"
        },
        {
          username: "mfw303",
          nama_bergelar: "M. Farid Wajdi, S.E., M.M., Ph.D"
        },
        {
          username: "mh997",
          nama_bergelar: "Muhammad Habibi, S.K.M, M.KL."
        },
        {
          username: "mhr367",
          nama_bergelar: "Dr.M. Muhammad Harry Rahmadi, S.Pi., M.M"
        },
        {
          username: "mi243",
          nama_bergelar: "Muhammad Irvan Sy, S.T"
        },
        {
          username: "mib705",
          nama_bergelar: "apt. Muh. Irham Bakhtiar, S.Farm., M.Clin.Pha"
        },
        {
          username: "mif644",
          nama_bergelar: "Moh. Imam Fauzi"
        },
        {
          username: "mil668",
          nama_bergelar: "Ns. Milkhatun, S.Kep, M.Kep"
        },
        {
          username: "mip733",
          nama_bergelar: "Muhammad Iqbal Pribadi, S.M., MBA"
        },
        {
          username: "mj223",
          nama_bergelar: "Drs. Muhammad Jafron, M.Si"
        },
        {
          username: "mj337",
          nama_bergelar: "M. Jufri"
        },
        {
          username: "mj965",
          nama_bergelar: "Prof. Dr. Drs. Mifedwil Jandra, M.Ag"
        },
        {
          username: "mjh823",
          nama_bergelar: "Prof. Dr. Muhammad Jafar Haruna, S.S., M.S"
        },
        {
          username: "mm377",
          nama_bergelar: "Musjaidah musjaidah, S.I.P"
        },
        {
          username: "mm601",
          nama_bergelar: "Dr. Muhammad Musiyam, M.T."
        },
        {
          username: "mmb958",
          nama_bergelar: "Prof. Dr. Mohamed Mustafa Bin Ishak"
        },
        {
          username: "mmd506",
          nama_bergelar: "Ns. Maridi, S.Kep, M.Kep"
        },
        {
          username: "mn275",
          nama_bergelar: "Muslimin Nulipata, S.Psi., M.Psi., Psikolog"
        },
        {
          username: "mn325",
          nama_bergelar: "Ns. Misbah Nurjannah, S.Kep., M.Kep"
        },
        {
          username: "mn874",
          nama_bergelar: "Dr. Mursidah Nurfadillah, S.E, M.M"
        },
        {
          username: "mna266",
          nama_bergelar: "Dr. Muhammad Nurcholis Alhadi, SH, M.H.Li"
        },
        {
          username: "mna985",
          nama_bergelar: "Ir. Muhammad Noor Asnan, S.T, M.T."
        },
        {
          username: "mnas955",
          nama_bergelar: ""
        },
        {
          username: "mnb762",
          nama_bergelar: "Mohd Noh Bin Dalimin"
        },
        {
          username: "mr259",
          nama_bergelar: "Dr. M. RISAL, S.E., M.Si"
        },
        {
          username: "mr624",
          nama_bergelar: "Muhamad Ridwan, S.Kom"
        },
        {
          username: "mra441",
          nama_bergelar: "Muhammad Rizky Adinugroho, S.Tr.Kom"
        },
        {
          username: "mrp318",
          nama_bergelar: "Maulina Rahmawati Putri, S.Farm."
        },
        {
          username: "mrs371",
          nama_bergelar: "M. Riadhus Sholihin, S.Kom."
        },
        {
          username: "ms186",
          nama_bergelar: "Muhammad Saputra"
        },
        {
          username: "ms597",
          nama_bergelar: "Mochamad Solikin"
        },
        {
          username: "ms750",
          nama_bergelar: "Muhammad Sukri, A.Md, S.I.Pust"
        },
        {
          username: "ms817",
          nama_bergelar: "Meta Surachman, S.I.Kom"
        },
        {
          username: "msy245",
          nama_bergelar: "Meisi Surta Yosi, S.K.M."
        },
        {
          username: "mts653",
          nama_bergelar: "Muhammad Taufiq Sumadi, S.Tr.Kom., M.Tr.Kom"
        },
        {
          username: "muh478",
          nama_bergelar: "Muhamad muhamad, S.Pdi"
        },
        {
          username: "mw339",
          nama_bergelar: "Muhammad Wahyuddin"
        },
        {
          username: "mw894",
          nama_bergelar: "Marjan Wahyuni, S.KM, M.Si"
        },
        {
          username: "my800",
          nama_bergelar: "Dr. dr. Martina Yulianti, MARS., Sp.PD., FINASIM"
        },
        {
          username: "mya472",
          nama_bergelar: "Muhammad Yusril Ade Mahendra, S.Sos"
        },
        {
          username: "myi480",
          nama_bergelar: "Menik Yuni Indriati, S.E., M.Sc."
        },
        {
          username: "na173",
          nama_bergelar: "dr. Nurita Aziza"
        },
        {
          username: "na332",
          nama_bergelar: "Nur Annisa, S.Psi."
        },
        {
          username: "na553",
          nama_bergelar: "Nurul Azizah, S.Kom."
        },
        {
          username: "na844",
          nama_bergelar: "Nida Amalia, S.K.M, M.PH"
        },
        {
          username: "nam791",
          nama_bergelar: "Nanda Alfian Mahardhika, S.Pd, M.Pd"
        },
        {
          username: "nar674",
          nama_bergelar: "Ni'matul Azizah Raharjanti, S.T., M.Eng"
        },
        {
          username: "nat861",
          nama_bergelar: "Niken Agus Tianingrum, S.KM, M.K.M."
        },
        {
          username: "nav651",
          nama_bergelar: "Naufal Azmi Verdhika, S.Kom, M.Eng"
        },
        {
          username: "ner746",
          nama_bergelar: "Nur Endah Ramayanti, S.Sos., M.CIL"
        },
        {
          username: "nes556",
          nama_bergelar: "Nurfan Eka Saputra H. Isa, S.Kep."
        },
        {
          username: "nes850",
          nama_bergelar: ""
        },
        {
          username: "nfi573",
          nama_bergelar: "Ns. Nur Fithriyanti Imamah, S.Kep, M.B.A, Ph.D"
        },
        {
          username: "nh183",
          nama_bergelar: "DR. Nunung Herlina, M.Pd, S.Kep"
        },
        {
          username: "nk201",
          nama_bergelar: "Nur Khalida, S.Pd"
        },
        {
          username: "nl313",
          nama_bergelar: "Nila Lestari, S.K.M."
        },
        {
          username: "nl876",
          nama_bergelar: "dr. Nur Linda, Sp.PA."
        },
        {
          username: "nma816",
          nama_bergelar: "Novia Misnawati Aisyiyah, S.Farm."
        },
        {
          username: "nn994",
          nama_bergelar: "Nardi nardi, S.Tr.Kom., M.M."
        },
        {
          username: "noo305",
          nama_bergelar: "apt. Noorlina, S.Farm., M.Farm."
        },
        {
          username: "nrna169",
          nama_bergelar: "Nisa Rachmah Nur Anganthi"
        },
        {
          username: "nt618",
          nama_bergelar: "Nadia Tajudin, S.K.M."
        },
        {
          username: "nts953",
          nama_bergelar: "dr. Nurulita Tunjung Sari, Sp.KJ."
        },
        {
          username: "nur849",
          nama_bergelar: "Nuraini nuraini, S.E., M.M."
        },
        {
          username: "nww131",
          nama_bergelar: "Ns. Ni Wayan Wiwin Asthiningsih, S.Kep., M.Pd"
        },
        {
          username: "nz464",
          nama_bergelar: "dr. Nuratifah Zahra"
        },
        {
          username: "nzf883",
          nama_bergelar: "Nur Zatil Fadilah, S.M."
        },
        {
          username: "pa465",
          nama_bergelar: "Primadiyanti Arsela, S.Hut., M.Si"
        },
        {
          username: "pef531",
          nama_bergelar: "Putri Elmira Fadyatulina Anshary"
        },
        {
          username: "pf561",
          nama_bergelar: "Ns. Pipit Feriani, S.Kep, MARS"
        },
        {
          username: "phs778",
          nama_bergelar: "Praja Hadi Saputra, S.E, Ak, M.Sc"
        },
        {
          username: "pit223",
          nama_bergelar: "Pitoyo, S.T, M.Sc"
        },
        {
          username: "pmk195",
          nama_bergelar: "Paula Mariana Kustiawan, S.Hut., M.Sc., Ph.D"
        },
        {
          username: "psn882",
          nama_bergelar: "Purwo Setiyo Nugroho, S.K.M, M.Epid"
        },
        {
          username: "ra694",
          nama_bergelar: "Rabiatul Adawiyah, A.Md.Kes."
        },
        {
          username: "ra894",
          nama_bergelar: "Dr. Rahmatullah Ayu Hasmiati, S.Pd, M.H"
        },
        {
          username: "ra932",
          nama_bergelar: "Riri Apriani, S.KM"
        },
        {
          username: "ra940",
          nama_bergelar: "Rahman Anshari, S.E, M.A"
        },
        {
          username: "ra995",
          nama_bergelar: "Rizky Amalia, S.E"
        },
        {
          username: "rali438",
          nama_bergelar: ""
        },
        {
          username: "rap791",
          nama_bergelar: "Rio Arif Pratama, SH, M.H.LI"
        },
        {
          username: "rat114",
          nama_bergelar: "Dra. Ratnawati, M.Psi"
        },
        {
          username: "rca239",
          nama_bergelar: "Ricko Caesar Aprilla Tiaka"
        },
        {
          username: "rdh494",
          nama_bergelar: "Ns. Rahmatika Dwi Hartiti, S.Kep"
        },
        {
          username: "re840",
          nama_bergelar: "Rini Ernawati, S.Pd, M.Kes"
        },
        {
          username: "rf203",
          nama_bergelar: "Rahayu Farida, S.Psi., M.Psi."
        },
        {
          username: "rfn882",
          nama_bergelar: "Rasyid Fajar Nugraha, S.Kom"
        },
        {
          username: "rh146",
          nama_bergelar: "Rahmandani Herlambang, S.Kom"
        },
        {
          username: "rh219",
          nama_bergelar: "Rofilde Hasudungan, S.Kom, M.Sc"
        },
        {
          username: "rh720",
          nama_bergelar: "Reny Hidayah, S.Psi."
        },
        {
          username: "rhp546",
          nama_bergelar: "Rani Herning Puspita, S.Hum, M.Hum"
        },
        {
          username: "ri984",
          nama_bergelar: "Ns. Ramdhany Ismahmudi, S.Kep, M.P.H."
        },
        {
          username: "rin366",
          nama_bergelar: ""
        },
        {
          username: "rjs174",
          nama_bergelar: "Rioza Jakalia Saputra, S.Pd."
        },
        {
          username: "rks488",
          nama_bergelar: "Rizky Kurniawan Syamat, S.E"
        },
        {
          username: "rm180",
          nama_bergelar: "Rusni Masnina, S.Kep, M.PH"
        },
        {
          username: "rn903",
          nama_bergelar: "Rusandi Noor, S.T, M.T., Ph.D"
        },
        {
          username: "rna121",
          nama_bergelar: "Rizki Nur Azmi, S.Farm, Apt, M.Farm"
        },
        {
          username: "rp230",
          nama_bergelar: "Rahmawati Pratiwi, S.Psi, M.Psi"
        },
        {
          username: "rs663",
          nama_bergelar: "Reni Suhelmi, S.KM, M.Kes"
        },
        {
          username: "rs743",
          nama_bergelar: "Renny Septiana, S.Farm, Apt"
        },
        {
          username: "rsk934",
          nama_bergelar: "Dr. Rinda Sandayani Karhab, S.Hut, M.Si"
        },
        {
          username: "rts711",
          nama_bergelar: "Rizki Tegar Sembada, S.IP, M.H.I"
        },
        {
          username: "rud959",
          nama_bergelar: "Rudiman, S.Kom, M.Sc"
        },
        {
          username: "rus756",
          nama_bergelar: "Rusdi, S.Si, M.Si"
        },
        {
          username: "ry190",
          nama_bergelar: "Ratna Yuliawati, S.KM, M.Kes"
        },
        {
          username: "rzb165",
          nama_bergelar: "Prof. Dr. Rushami Zein Bin Yusoff"
        },
        {
          username: "sa573",
          nama_bergelar: "Sofyan Anif"
        },
        {
          username: "sa770",
          nama_bergelar: "Samsul Alam, S.I.P"
        },
        {
          username: "saj101",
          nama_bergelar: "Sajab"
        },
        {
          username: "sam558",
          nama_bergelar: "Syayid Al Manar, S.Sos"
        },
        {
          username: "sar118",
          nama_bergelar: "Ir. Sarjito sarjito, M.T., Ph.D"
        },
        {
          username: "sf797",
          nama_bergelar: "Serly Febriani, S.Pd., MATESL"
        },
        {
          username: "sf926",
          nama_bergelar: "Siti Fashikhulisan, A.Md.Kes."
        },
        {
          username: "sha850",
          nama_bergelar: "Shafiah Shafiah, SHI"
        },
        {
          username: "shp904",
          nama_bergelar: "Sigiet Haryo Pranoto, S.T, M.Eng"
        },
        {
          username: "shs500",
          nama_bergelar: "Sayekti Harits Suryawan, S.Kom, M.Kom"
        },
        {
          username: "shw929",
          nama_bergelar: "Sofian Dwi Hadiwinata, S.Kom"
        },
        {
          username: "sis612",
          nama_bergelar: "Siswanto"
        },
        {
          username: "sk859",
          nama_bergelar: "Ns. Siti Kotijah, S.Kep"
        },
        {
          username: "skm251",
          nama_bergelar: "Ns. Siti Khoiroh Muflihatin, S.Kep, S.Pd., M.Kep."
        },
        {
          username: "sm501",
          nama_bergelar: "Sri Maslianawati, S.Kep, Ns, M.Kes"
        },
        {
          username: "sms104",
          nama_bergelar: "dr. Syifa Mahmud Syukran Akbar, Sp.J.P."
        },
        {
          username: "smt192",
          nama_bergelar: "Siti Malikhah Towaf, Ph.D"
        },
        {
          username: "sn170",
          nama_bergelar: "apt. Syaadatun Nadiah, S.Farm., M.Si."
        },
        {
          username: "sn911",
          nama_bergelar: "Siti Nurjanah"
        },
        {
          username: "sna774",
          nama_bergelar: "SAS Noor Adhadianawaty, S.E."
        },
        {
          username: "snn478",
          nama_bergelar: "Ns. Siti Nur Ngaisah, S.Kep., M.Kep., Sp.Kep.A."
        },
        {
          username: "sp377",
          nama_bergelar: "Ns. Slamet Purnomo, S.Kep, M.Kep"
        },
        {
          username: "sr430",
          nama_bergelar: "Syaiful Rohman"
        },
        {
          username: "sr843",
          nama_bergelar: "Siti Rohmatun, S.E."
        },
        {
          username: "srd143",
          nama_bergelar: "apt. Sinta Ratna Dewi, S.Farm, M.Si"
        },
        {
          username: "ss175",
          nama_bergelar: "Dr. H. Surahman, S.HI., M.H."
        },
        {
          username: "ss281",
          nama_bergelar: "Saharuddin saharuddin"
        },
        {
          username: "ss430",
          nama_bergelar: "Dr. Siti Soekiswati"
        },
        {
          username: "ss678",
          nama_bergelar: "Sri Sunarti, S.K.M, M.P.H"
        },
        {
          username: "ss942",
          nama_bergelar: "Sarifah Syahnas, S.E."
        },
        {
          username: "ssa633",
          nama_bergelar: "Ns. Sitti Shoimatul Azizah, S.Kep., M.Kep., Sp.Kep.MB."
        },
        {
          username: "ssm340",
          nama_bergelar: "Syamsidar Sutan Malim Polawan, S.T, M.T"
        },
        {
          username: "ssr730",
          nama_bergelar: "Sylvan Septian Ressandy, S.Farm, M.Farm"
        },
        {
          username: "sud100",
          nama_bergelar: "Sudirman, S.T, M.KKK"
        },
        {
          username: "sud520",
          nama_bergelar: "Sudarman, S.Pd, M.Ed"
        },
        {
          username: "sueh433",
          nama_bergelar: "Sofia Ulfa Eka Hadiyanti, S.E, M.Si"
        },
        {
          username: "suh362",
          nama_bergelar: "Ns. Suhardiansyah, S.Kep"
        },
        {
          username: "suh703",
          nama_bergelar: "Suharman, S.Ag, M.Ag"
        },
        {
          username: "suh764",
          nama_bergelar: "Suhermanto"
        },
        {
          username: "sun377",
          nama_bergelar: "Sunarti, S.Pd, M.Pd"
        },
        {
          username: "sun408",
          nama_bergelar: "Sunarso, S.E, M.M"
        },
        {
          username: "sun487",
          nama_bergelar: "Sunariyo, S.H, M.H"
        },
        {
          username: "sup391",
          nama_bergelar: "Drs. Suprayitno, M.Kes"
        },
        {
          username: "sut366",
          nama_bergelar: "Sutoyo "
        },
        {
          username: "sut562",
          nama_bergelar: "Sutarnyoto"
        },
        {
          username: "suw936",
          nama_bergelar: "Suwoko, S.E, M.M"
        },
        {
          username: "suy137",
          nama_bergelar: "Suyatman, S.Pd, M.Si"
        },
        {
          username: "sw420",
          nama_bergelar: "Susilo Wahono"
        },
        {
          username: "sw814",
          nama_bergelar: "dr. Sri Wahyuni, M.Biomed."
        },
        {
          username: "swj579",
          nama_bergelar: "Sri Wahyuni Jamal, S.E, M.M"
        },
        {
          username: "sy998",
          nama_bergelar: "Santi Yatnikasari, S.T, M.T"
        },
        {
          username: "sya809",
          nama_bergelar: "Syamsir, S.KM, M.Kes."
        },
        {
          username: "sz311",
          nama_bergelar: "Syarifah Zulfatirahmi, S.I.Kom"
        },
        {
          username: "tah281",
          nama_bergelar: "Ns. Taharuddin, S.Kep, M.Kep"
        },
        {
          username: "tap238",
          nama_bergelar: "Titiek Aryati Prihathin, S.E., M.M."
        },
        {
          username: "taw965",
          nama_bergelar: "Ns. Thomas Ari Wibowo, S.Kep, M.Kep"
        },
        {
          username: "tay758",
          nama_bergelar: "Taghfirul Azhima Yoga Siswa, S.Kom, M.Kom"
        },
        {
          username: "tbj470",
          nama_bergelar: "Tri Budi Julianti, S.Si, M.Si"
        },
        {
          username: "thr638",
          nama_bergelar: "Tiara Halidah Ratnasari, S.Si."
        },
        {
          username: "tif451",
          nama_bergelar: "Trisna Ika Fitri, S.KM"
        },
        {
          username: "tn970",
          nama_bergelar: "Tajudin Noor"
        },
        {
          username: "tri233",
          nama_bergelar: "Triyono, SE"
        },
        {
          username: "tri497",
          nama_bergelar: "Tri Astuti, S.Psi, M.Psi, Ph.D"
        },
        {
          username: "ts553",
          nama_bergelar: "Ns. Taufik Septiawan, S.Kep, M.Kep"
        },
        {
          username: "tw634",
          nama_bergelar: "Ns. Tri Wijayanti, S.Kep, M.Kep"
        },
        {
          username: "tw879",
          nama_bergelar: "Ns. Tri Wahyuni, M.Kep, Sp.Kep.Mat, Ph.D"
        },
        {
          username: "tws625",
          nama_bergelar: "Prof. Dr. dr. Teguh Wahju Sardjono, DTM&H., M.Sc., Sp.ParK."
        },
        {
          username: "ub506",
          nama_bergelar: "Umar Battong, S.P., M.P."
        },
        {
          username: "um207",
          nama_bergelar: "Ns. Ulfatul Muflihah, S.Kep, M.N.S."
        },
        {
          username: "um636",
          nama_bergelar: "Ulfatul Muflihah"
        },
        {
          username: "unr499",
          nama_bergelar: "Ulfah Nur Ramadhayani, S.K.M."
        },
        {
          username: "ur437",
          nama_bergelar: "Uut Rahayuningsih, S.H., M.H."
        },
        {
          username: "ur489",
          nama_bergelar: "dr. Ullifannuri Rachmi, Sp.PK."
        },
        {
          username: "uu850",
          nama_bergelar: "Usma usma"
        },
        {
          username: "uwm216",
          nama_bergelar: "Ulwiyah Wahdah Mufassirin Liana, S.T, M.T"
        },
        {
          username: "va674",
          nama_bergelar: "Dr. Vera Anitra, S.E, M.M"
        },
        {
          username: "vah356",
          nama_bergelar: "Ventirana Adhi Helma, S.E."
        },
        {
          username: "ven546",
          nama_bergelar: "Ns. Vivi Ekawati Ningtyas, S.Kep."
        },
        {
          username: "vnw866",
          nama_bergelar: "dr. Vierga Nanda Wiqa"
        },
        {
          username: "vp799",
          nama_bergelar: "Dr. Vita Pramaningsih, S.T, M.Eng"
        },
        {
          username: "waa928",
          nama_bergelar: "Weldy Andy Arif, S.KM., M.Kes"
        },
        {
          username: "wah363",
          nama_bergelar: "Drs. Wahadi"
        },
        {
          username: "war870",
          nama_bergelar: "Dr. Warsino, S.H., M.H."
        },
        {
          username: "was123",
          nama_bergelar: "Ir. Waluyo Adi Siswanto, M.Eng, PhD"
        },
        {
          username: "wf270",
          nama_bergelar: "Widya Fajariani, S.Si"
        },
        {
          username: "wi507",
          nama_bergelar: "Wisnu Ismunandar, S.T., M.T"
        },
        {
          username: "wir234",
          nama_bergelar: "Wirnawati wirnawati, S.Farm, Apt, M.Si"
        },
        {
          username: "wjp337",
          nama_bergelar: "Wawan Joko Pranoto, S.Kom, M.Ti"
        },
        {
          username: "ww927",
          nama_bergelar: "Wahyuni Wahyuni, A.Md.Kep"
        },
        {
          username: "yaf442",
          nama_bergelar: "dr. Yusran Ady Fitrah, Ph.D."
        },
        {
          username: "yf681",
          nama_bergelar: "Yasnita Fatmawati, S.Tr.T"
        },
        {
          username: "yi407",
          nama_bergelar: "Dr. Yannie Isworo, S.KM, M.Kes"
        },
        {
          username: "yp161",
          nama_bergelar: "Yeny Prastiwi"
        },
        {
          username: "yp379",
          nama_bergelar: "Yoga Priantama, S.Kom."
        },
        {
          username: "yr173",
          nama_bergelar: "Yeni Rahmawati, S.Pd, M.Sc"
        },
        {
          username: "ys178",
          nama_bergelar: "Yuli Setiowati, S.P., M.P."
        },
        {
          username: "ys828",
          nama_bergelar: "Dr. Yudhi Satria, S.E, S.Psi, M.Si"
        },
        {
          username: "ytk716",
          nama_bergelar: "Yulia Tri Kusumawati, S.E, Ak, M.Sc"
        },
        {
          username: "yw399",
          nama_bergelar: "Yuliani Winarti, S.KM, M.PH"
        },
        {
          username: "za530",
          nama_bergelar: "Zaenal Arifin, S.Pd.I., M.Pd.I"
        },
        {
          username: "za722",
          nama_bergelar: "Prof. Zakaria Abas, M.A., Ph.D"
        },
        {
          username: "za757",
          nama_bergelar: "Zainal Arifin, S.E, M.M"
        },
        {
          username: "za874",
          nama_bergelar: "Ns. Zulmah Astuti, S.Kep., M.Kep"
        },
        {
          username: "zh463",
          nama_bergelar: "Zulkifli Hasan"
        },
        {
          username: "zul158",
          nama_bergelar: "Zulkifly, S.E"
        }
      ]
      //     console.log(resp.data.rows.map(item => ({
      //       username: item.user[0].username,
      //       nama_bergelar: item.nama_bergelar
      //     })))
      this.dosenfilter = this.dosenlist
      //   })
    },
    getAllThread: function () {
      this.form.comment = null
      this.form.images = []
      this.form.title = null
      this.activeImage = false
      axios.post(process.env.VUE_APP_BIMBINGAN_URL + "/bimbingan/bimbingan_pa/all_thread/?page=" + this.page, {
        user: this.$store.state.auth.uid
      })
        .catch(e => console.log(e))
        .then(resp => {
          console.log(resp.data)
          this.datamail = resp.data
          this.dosen.id = resp.data.results[0].dosen_pembimbing.id
          this.dosen.name = resp.data.results[0].dosen_pembimbing.name
          this.trash = this.datamail.results.filter((item) => item.soft_delete)
          this.sent = this.datamail.results.filter((item) => item.sent_recieve === true && item.soft_delete == null)
          this.inbox = this.datamail.results.filter((item) => item.sent_recieve === false && item.soft_delete == null)

        })
    },
    showData: function (tab) {
      this.detailmail.title = null
      this.showdata = tab
    },
    getDosen: function () {
      axios.post(process.env.VUE_APP_BIMBINGAN_URL + "/bimbingan/bimbingan_pa/all_thread/?page=" + this.page, {
        user: this.$store.state.auth.uid
      })
        .catch(e => console.log(e))
        .then(resp => {
          this.datamail = resp.data
        })
    },
    getThread: function (id) {
      this.form.comment = null
      this.form.images = []
      this.form.title = null
      this.activeImage = false
      axios.post(process.env.VUE_APP_BIMBINGAN_URL + "/bimbingan/bimbingan_pa/get_thread/", {
        user: this.$store.state.auth.uid,
        id: id
      })
        .catch(e => console.log(e))
        .then(resp => {
          this.detailmail.id = resp.data.id
          this.detailmail.title = resp.data.title
          this.detailmail.from_user.id = resp.data.mahasiswa.id
          this.detailmail.from_user.name = resp.data.mahasiswa.name
          this.detailmail.from_user.image = resp.data.mahasiswa.image
          this.detailmail.comments = resp.data.comments
          this.detailmail.created_at = resp.data.created_at
        })
    },
    activeImages: function () {
      this.activeImage = this.activeImage === false;
    },
    activeFormThread: function () {
      this.detailmail.id = null
      this.detailmail.title = null
      this.detailmail.from_user.id = null
      this.detailmail.from_user.name = null
      this.detailmail.from_user.image = null
      this.detailmail.comments = []
      this.activeImageItem = null
      this.formThread = true
      this.$store.commit('openModal', null)
    },
    activeFormPastThread: function () {
      this.pastThread = true
      this.detailmail.id = null
      this.detailmail.title = null
      this.detailmail.from_user.id = null
      this.detailmail.from_user.name = null
      this.detailmail.from_user.image = null
      this.detailmail.comments = []
      this.detailmail.date = null
      this.activeImageItem = null
      this.formThread = true
      this.$store.commit('openModal', null)
    },
    insertFoto: function (input) {
      this.form.images = input
    },
    deleteThread: function (id) {
      axios.post(process.env.VUE_APP_BIMBINGAN_URL + "/bimbingan/bimbingan_pa/delete_thread/", {
        user: this.$store.state.auth.uid,
        id: id
      })
        .catch(e => console.log(e))
    },
    insertComment: function (id) {
      let formData = new FormData()

      formData.append('id', id)
      formData.append('user', this.$store.state.auth.uid)
      formData.append('title', this.form.title)
      formData.append('comment', this.form.comment)
      for (let i in this.form.images) {
        formData.append('image' + i, this.form.images[i])
      }
      axios.post(process.env.VUE_APP_BIMBINGAN_URL + "/bimbingan/bimbingan_pa/comment_thread/", formData)
        .catch(e => console.log(e))
        .then(() => {
          this.getThread(id)
          this.getAllThread()
        })
    },
    openImage: function (item) {
      this.formThread = false
      this.activeImageItem = item
      this.openImages = true
      this.$store.commit('openModal', null)
    },
    startThread: function () {
      this.loadingThread = true
      let formData = new FormData()

      formData.append('user', this.$store.state.auth.uid)
      formData.append('dosen', this.selectedOption)
      formData.append('title', this.form.title)
      formData.append('comment', this.form.comment)
      formData.append('type', this.form.type)
      if (this.selectedOption && this.form.title && this.form.title && this.form.comment && this.form.type) {
        for (let i in this.form.images) {
          formData.append('image' + i, this.form.images[i])
        }
        if (this.form.date) {
          formData.append('date', this.form.date)
        }
        axios.post(process.env.VUE_APP_BIMBINGAN_URL + "/bimbingan/bimbingan_pa/create_thread/", formData)
          .catch(() => {
            this.loadingThread = false
            this.$store.commit('addNotification', { type: 'error', message: "Gagal menyimpan bimbingan" })
            this.showdata = 'sent'
          })
          .then(resp => {
            this.loadingThread = false
            this.datamail = resp.data
            this.showdata = 'sent'
            this.getAllThread()
            this.$store.commit('closeModal')
          })
      } else {
        this.loadingThread = false
        this.$store.commit('addNotification', { type: 'error', message: "Pastikan data telah terisi." })
      }
    },
    defaultErase: function () {
      this.erase = false
    }
  },
  mounted() {
    this.getAllThread()
    this.getListDosen()
  },
}
</script>

<style scoped></style>