import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardLayout from "@/views/layout/DashboardLayout";
import DashboardPage from "@/views/dashboard/DashboardPage";
import PenghargaanPage from "@/views/dashboard/prestasi/PenghargaanPage";
import WorkshopPage from "@/views/dashboard/prestasi/WorkshopPage";
import OrganisasiPage from "@/views/dashboard/prestasi/OrganisasiPage";
import LoginPage from "@/views/auth/LoginPage";
import store from '../store/vuex';
import ListPrestasiPage from "@/views/dashboard/prestasi/ListPrestasiPage";
import vuex from "../store/vuex";
import PengajuanPrestasiBeasiswa from "@/views/dashboard/beasiswa/PengajuanPrestasiBeasiswa";
import pengajuanKIP from "@/views/dashboard/beasiswa/PengajuanKIP";
import BeasiswaSKKMigas from "@/views/dashboard/beasiswa/BeasiswaSKKMigas";
import BeasiswaDaerah from "@/views/dashboard/beasiswa/BeasiswaDaerah";
import BeasiswaBankaltimtara from "@/views/dashboard/beasiswa/BeasiswaBankaltimtara";
import ListBeasiswa from "@/views/dashboard/beasiswa/ListBeasiswa";
import BimbinganPage from "@/views/dashboard/bimbingan/BimbinganPage";
import AdministrasiPage from "@/views/dashboard/administrasi/AdministrasiPage";
import KegiatanPage from "@/views/dashboard/ormawa/KegiatanPage";
import ProgramKerjaPage from "@/views/dashboard/ormawa/ProgramKerjaPage";
import SkKepengurusanPage from "@/views/dashboard/ormawa/SkKepengurusanPage";
import OrganisasiOrmawaPage from "@/views/dashboard/ormawa/OrganisasiOrmawaPage";
import HomePage from "@/views/HomePage";

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        component: HomePage,
        meta: {requiresAuth: false}
    },
    {
        path: '/',
        name: 'dashboard',
        component: DashboardLayout,
        children: [
            {
                path: 'dashboard',
                component: DashboardPage,
                meta: {requiresAuth: true},
            },
            {
                path: 'prestasi-penghargaan',
                component: PenghargaanPage,
                meta: {requiresAuth: true},
            },
            {
                path: 'prestasi-seminar',
                component: WorkshopPage,
                meta: {requiresAuth: true},
            },
            {
                path: 'prestasi-organisasi',
                component: OrganisasiPage,
                meta: {requiresAuth: true},
            },
            {
                path: 'list-prestasi',
                component: ListPrestasiPage,
                meta: {requiresAuth: true}
            },
            {
                path: 'pengajuan-beasiswa-prestasi',
                component: PengajuanPrestasiBeasiswa,
                meta: {requiresAuth: true}
            },
            {
                path: 'pengajuan-kip',
                component: pengajuanKIP,
                meta: {requiresAuth: true}
            },
            {
                path: 'beasiswa-skk-migas',
                component: BeasiswaSKKMigas,
                meta: {requiresAuth: true}
            },
            {
                path: 'beasiswa-daerah',
                component: BeasiswaDaerah,
                meta: {requiresAuth: true}
            },
            {
                path: 'beasiswa-bankaltimtara',
                component: BeasiswaBankaltimtara,
                meta: {requiresAuth: true}
            },
            {
                path: 'list-beasiswa',
                component: ListBeasiswa,
                meta: {requiresAuth: true}
            },
            {
                path: 'bimbingan-pa',
                component: BimbinganPage,
                meta: {requiresAuth: true}
            },
            {
                path: 'administrasi',
                component: AdministrasiPage,
                meta: {requiresAuth: true}
            },
            {
                path: 'kegiatan-ormawa/:id',
                component: KegiatanPage,
                meta: {requiresAuth: true}
            },
            {
                path: 'proker-ormawa',
                component: ProgramKerjaPage,
                meta: {requiresAuth: true}
            },
            {
                path: 'sk-kepengurusan-ormawa',
                component: SkKepengurusanPage,
                meta: {requiresAuth: true}
            },
            {
                path: 'organisasi',
                component: OrganisasiOrmawaPage,
                meta: {requiresAuth: true}
            }
            // {
            //   path: '/form',
            //   component: FormPage,
            //   meta: { requiresAuth: true },
            // },
            // {
            //   path: '/approval',
            //   component: SearchPage,
            //   meta: { requiresAuth: true },
            // },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
        meta: {requiresAuth: false}
    }
]

const router = new VueRouter({
    routes,
    mode: 'history',
    linkActiveClass: 'is-active'
})

router.beforeEach((to, from, next) => {
    store.commit('closeModal')
    if (vuex.state.modal.status) {
        vuex.state.modal.status = false
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.auth.access_token && store.state.auth.refresh_token) {
            next()
        } else {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })
        }
    } else {
        next()
    }
})

export default router
