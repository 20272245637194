<template>
  <div class="rounded-xl">
    <div class="grid grid-cols-12 gap-x-5 gap-y-5 my-5">
      <MacCardComponent cardsname="Staff | Status" class="col-span-12 overflow-hidden">
        <table class="w-full text-xs md:text-base mb-4">
              <thead class="text-left border-b bg-gray-100">
              <tr>
                <th class="pl-7 py-3 border-r">Nama Organisasi</th>
                <th class="pl-3.5 py-3 w-10">#</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, key) in this.organisasi" :key="key" class="border-y border-gray-200 h-10 py-3">
                <td class="truncate pl-4 border-r">
                  {{ item.nama }}
                </td>
                <td class="pl-1">
                  <button class="hover:bg-gray-200 text-center rounded-lg w-7 h-7" @click="openModal(item.id)">
                    <font-awesome-icon icon="fa-solid fa-ellipsis-vertical"/>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
<!--        <div class="grid grid-cols-4 gap-x-5 gap-y-7">-->
<!--          <InputComponent class="col-span-2" v-model="form.nama_organisasi" name="nama_organisasi" label="Nama Organisasi"-->
<!--                          type="text"/>-->
<!--          <MultipleSelectGroupComponent class="col-start-1 col-span-4" @pilih="itemChoose"/>-->
<!--        </div>-->
<!--        <button @click="submitForm"-->
<!--                class="bg-emerald-500 mr-3 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">-->
<!--          Insert-->
<!--        </button>-->
      </MacCardComponent>
    </div>
    <ModalComponent width="w-[60rem]">
      <div class="grid grid-cols-4 gap-x-5 gap-y-7" v-if="form.jenis_prestasi != 'kejuaraan'">
        <InputComponent :disabled="disabled" class="col-span-2" v-model="form.nama" :value="form.nama"
                          name="nama" label="Nama Organisasi" type="text"/>
        <router-link class="bg-transparent" :to="'kegiatan-ormawa/' + this.form.id">
          <button :disabled="form.file_proker ? form.file_proker[0].status_approval == 3 & form.file_sk_kepengurusan[0].status_approval == 3 ? false : true : true" :class="form.file_proker ? form.file_proker[0].status_approval == 3 & form.file_sk_kepengurusan[0].status_approval == 3 ? 'bg-yellow-500' : 'bg-gray-400' : false" class="mr-3 text-lg py-1 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
            Kegiatan Organisasi
          </button>
        </router-link >
        <InputFileComponent :disabled="disabled" class="col-start-1 col-span-1" @file="insertProker" name="file_proker"
                              label="Program kerja"/>
        <ToolTipStatusComponent v-if="form.file_proker" :status="form.file_proker ? form.file_proker[0].status_approval : false" :pesan="form.file_proker ? form.file_proker[0].pesan_approval : false" />
        <InputFileComponent :disabled="disabled" class="col-span-1" @file="insertSk" name="file_sk_kepengurusan"
                              label="SK Kepengurusan"/>
        <ToolTipStatusComponent v-if="form.file_sk_kepengurusan" :status="form.file_proker ? form.file_sk_kepengurusan[0].status_approval : false" :pesan="form.file_proker ? form.file_sk_kepengurusan[0].pesan_approval : false" />
        <MultipleSelectGroupComponent class="col-start-1 col-span-4" @pilih="itemChoose" :itemdeselect="this.form.anggota"/>
      </div>
      <div class="grid grid-cols-2 gap-5" v-if="disabled">
        <button v-if="periode" @click="activeData"
                class="bg-yellow-500 mr-3 text-lg py-1 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
          Edit
        </button>
<!--        <button @click="deleteForm"-->
<!--            class="bg-red-500 transform text-lg py-1 focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">-->
<!--          Delete-->
<!--        </button>-->
      </div>
      <div class="grid grid-cols-2 gap-5" v-if="!disabled">
        <button @click="submitForm"
                class="bg-green-500 mr-3 text-lg py-1 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
          Submit
        </button>
        <button @click="inactiveData"
            class="bg-gray-100 transform text-lg py-1 focus:scale-110 transition duration-200 text-black text-center rounded-md shadow-md focus:shadow-lg px-1.5">
          Cancel
        </button>
      </div>
    </ModalComponent>
  </div>
</template>

<script>
import axios from "axios";

import MacCardComponent from "@/components/widget/MacCardComponent";
import InputComponent from "@/components/widget/form/InputComponent";
import InputFileComponent from "@/components/widget/form/InputFileComponent";
import MultipleSelectGroupComponent from "@/components/widget/form/MultipleSelectGroupComponent";
import ModalComponent from "@/components/widget/Modal/ModalComponent";
import ToolTipStatusComponent from "@/components/widget/ToolTipStatusComponent";

export default {
  name: "OrganisasiPage",
  components: {
    MacCardComponent,
    InputComponent,
    InputFileComponent,
    MultipleSelectGroupComponent,
    ModalComponent,
    ToolTipStatusComponent
  },
  data() {
    return {
      disabled: true,
      form: {
        nama_organisasi: null,
        mahasiswa: null
      },
      organisasi: [],
      periode: false
    }
  },
  mounted() {
    this.getOrganisasi()
  },
  methods: {
    inactiveData: function () {
      this.disabled = true
    },
    activeData: function () {
      this.disabled = false
    },
    openModal: function (url) {
      this.form = {}
      this.disabled = true
      this.$store.commit('openModal', url)
      // this.$store.dispatch('getDataModal', process.env.VUE_APP_BASE_URL + '/ormawa/organisasi-ormawa/' + url + '/')
      axios.get(process.env.VUE_APP_BASE_URL + '/ormawa/organisasi-ormawa/' + url + '/')
          .catch(e => console.log(e))
          .then(resp => {
            this.form = resp.data
          })
    },
    // submitAgenda: function () {
    //   axios.put(process.env.VUE_APP_BASE_URL + "/ormawa/kegiatan-ormawa/updateKegiatan/",
    //       this.form)
    //       .then(resp => {
    //         console.log(resp)
    //       })
    // },
    getOrganisasi: function () {
      axios.post(process.env.VUE_APP_BASE_URL + '/ormawa/organisasi-ormawa/getMahasiswaOrganisasi/',{
            nim : this.$store.state.auth.uid
          })
          .then(resp => {
            this.organisasi = resp.data
            // console.log(resp.data)
          })
      axios.get(process.env.VUE_APP_BASE_URL + '/ormawa/periode-ormawa/getLastPeriode/',{
            nim : this.$store.state.auth.uid
          })
          .then(resp => {
            var today = new Date();
            var start_at = new Date(resp.data.start_at)
            var end_at = new Date(resp.data.end_at)
            console.log(today >= start_at, today <= end_at)
            this.periode = !!(today > start_at & today < end_at);
          })
    },
    itemChoose: function (input) {
      this.form.mahasiswa = input
    },
    submitForm: function () {
      const formData = new FormData()
      formData.append('id_organisasi', this.form.id)
      formData.append('nama_organisasi', this.form.nama)
      if (this.form.file_proker) {
        formData.append('file_proker', this.form.file_proker, this.form.file_proker.name)
      }
      if (this.form.file_sk_kepengurusan) {
        formData.append('file_sk_kepengurusan', this.form.file_sk_kepengurusan, this.form.file_sk_kepengurusan.name)
      }
      if (this.form.mahasiswa) {
        formData.append('mahasiswa', JSON.stringify(this.form.mahasiswa))
      }
      formData.append('token', this.$store.state.auth.refresh_token)
      axios.put(process.env.VUE_APP_BASE_URL + "/ormawa/organisasi-ormawa/updateOrganisasi/", formData, {})
          .then(resp => {
            // console.log(resp)
            this.$store.commit('addNotification', resp.data)
          })
          .catch(e => this.$store.commit('addNotification', {type: 'error', message: e.message}))
    },
    insertProker: function (input) {
      this.form.file_proker = input
    },
    insertSk: function (input) {
      this.form.file_sk_kepengurusan = input
    },
  }
}
</script>

<style scoped>

</style>