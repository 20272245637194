<template>
  <div>
    <div class="grid grid-cols-12 gap-x-5 gap-y-5 my-5">
      <MacCardComponent cardsname="Prestasi | Organisasi" class="col-span-12 overflow-hidden">
        <div class="px-5">
          <div class="">
            <div class="grid grid-cols-4 gap-x-5 gap-y-7 mb-5">
              <InputComponent class="col-span-2" v-model="form.nama_organisas" name="nama_organisasi" label="Nama Organisasi" type="text"/>
            </div>
            <div class="col-span-2 col-start-1">
              <button @click="submitForm"
                      class="bg-emerald-500 mr-3 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
                Insert
              </button>
              <button @click="cancelForm"
                      class="bg-emerald-500 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </MacCardComponent>
    </div>
  </div>
</template>

<script>
import MacCardComponent from "@/components/widget/MacCardComponent";
import axios from "axios";
import InputComponent from "@/components/widget/form/InputComponent";

export default {
  name: "OrganisasiPage",
  data() {
    return {
      form: {
        nama_event: null,
        url_kegiatan: null,
        penyelenggara: null,
        lingkup_tingkat: null,
        jumlah_peserta: null,
        jumlah_wilayah: null,
        jumlah_pt: null,
        kategori: null,
        prestasi_diraih: null,
        ekuivalensi: null,
        tempat: null,
        tanggal_mulai: null,
        tanggal_selesai: null,
        tahun_kegiatan: null,
        deskripsi: null,
        bukti: null,
        foto_kegiatan: null,
        tim_individu: null,
        jenis_prestasi: null,
        type_kejuaraan: null,
        anggota: []
      },
      selectForm: 'kejuaraan'
    }
  },
  components: {
    MacCardComponent,
    InputComponent,
  },
  mounted() {
    this.$store.commit('refreshMahasiswa')
  },
  methods: {
    addMahasiswa: function () {
      this.$store.commit('pushMahasiswa', {nim: null, nama: null})
    },
    insertNim: function (key, nim) {
      if (!this.form.anggota.includes(nim)) {
        this.form.anggota[key] = nim
        axios.post(process.env.VUE_APP_BASE_URL + "/api/insert-nim/", {
          nim: nim
        }, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
            .then(resp => {
              console.log(resp)
              this.$store.state.mahasiswa[key].nama = resp.data.name
            })
            .catch(e => {
              console.log(e)
              this.$store.state.mahasiswa[key].nama = null
            })
            .finally({})
      } else {
        alert('anda sudah memasukkan NIM tersebut')
      }
      // for (let i in this.$store.state.mahasiswa) {
      //   if (nim == this.$store.state.mahasiswa[i].nim) {
      //     if (key == i) {
      //       axios.post(process.env.VUE_APP_BASE_URL + "/api/insert-nim/", {
      //         nim: nim
      //       })
      //           .then(resp => {
      //             console.log(resp)
      //             this.$store.state.mahasiswa[key].nama = resp.data.name
      //             this.form.anggota.push(nim)
      //           })
      //           .catch(e => {
      //             console.log(e)
      //             this.$store.state.mahasiswa[key].nama = null
      //           })
      //     } else {
      //       alert('anda sudah memasukkan NIM tersebut')
      //       break
      //     }
      //   } else {
      //     alert('anda sudah memasukkan NIM tersebut')
      //     break
      //   }
      // }
    },
    deleteMahasiswa: function (key) {
      if (key != 0) {
        this.$store.commit('deleteMahasiswa', key)
      }
    },
    submitForm: function () {
      console.log('eeh')
      const formData = new FormData()
      formData.append('nama_event', this.form.nama_event)
      formData.append('url_kegiatan', this.form.url_kegiatan)
      formData.append('penyelenggara', this.form.penyelenggara)
      formData.append('lingkup_tingkat', this.form.lingkup_tingkat)
      formData.append('jumlah_peserta', this.form.jumlah_peserta)
      formData.append('jumlah_wilayah', this.form.jumlah_wilayah)
      formData.append('jumlah_pt', this.form.jumlah_pt)
      formData.append('kategori', this.form.kategori)
      formData.append('prestasi_diraih', this.form.prestasi_diraih)
      formData.append('ekuivalensi', this.form.ekuivalensi)
      formData.append('tempat', this.form.tempat)
      formData.append('tanggal_mulai', this.form.tanggal_mulai)
      formData.append('tanggal_selesai', this.form.tanggal_selesai)
      formData.append('tahun_kegiatan', this.form.tahun_kegiatan)
      formData.append('deskripsi', this.form.deskripsi)
      formData.append('tim_individu', this.form.tim_individu)
      formData.append('type_kejuaraan', this.form.type_kejuaraan)
      if (this.form.foto_kegiatan) {
        formData.append('foto_kegiatan', this.form.foto_kegiatan, this.form.foto_kegiatan.name)
      }
      if (this.form.bukti) {
        formData.append('bukti', this.form.bukti, this.form.bukti.name)
      }
      formData.append('anggota', JSON.stringify(this.form.anggota))
      formData.append('jenis_prestasi', 'organisasi')
      formData.append('token', this.$store.state.auth.refresh_token)
      axios.post(process.env.VUE_APP_BASE_URL + "/prestasi/prestasi-mahasiswa/insertPrestasi/", formData, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
          .then(resp => {
            // console.log(resp)
            this.$store.commit('addNotification', resp.data)
          })
          .catch(e => this.$store.commit('addNotification', {type: 'error', message: e.message}))
    },
    insertFotoKegiatan: function (input) {
      this.form.foto_kegiatan = input
    },
    insertBukti: function (input) {
      this.form.bukti = input
    },
    cancelForm: function () {
      this.form.nama_event = null,
          this.form.url_kegiatan = null,
          this.form.penyelenggara = null,
          this.form.lingkup_tingkat = null,
          this.form.jumlah_peserta = null,
          this.form.jumlah_wilayah = null,
          this.form.jumlah_pt = null,
          this.form.kategori = null,
          this.form.prestasi_diraih = null,
          this.form.ekuivalensi = null,
          this.form.tempat = null,
          this.form.tanggal_mulai = null,
          this.form.tanggal_selesai = null,
          this.form.tahun_kegiatan = null,
          this.form.deskripsi = null,
          this.form.bukti = null,
          this.form.foto_kegiatan = null,
          this.form.tim_individu = null,
          this.form.jenis_prestasi = null,
          this.form.type_kejuaraan = null,
          this.form.anggota = []
    }
  }
}
</script>

<style scoped>

</style>