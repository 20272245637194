<template>
  <div>
    <div v-if="$store.state.auth.status == 'mahasiswa'" class="grid grid-cols-3 gap-2 my-5">
      <div class="col-span-3 flex relative rounded-xl overflow-hidden border shadow-md">
        <img class="w-full h-96 object-cover" src="https://images.wallpaperscraft.com/image/single/sea_buildings_castle_1003554_3840x2400.jpg" />
        <div class="absolute w-full bottom-0 py-2 bg-white">
          <div class="flex relative w-full">
            <div class="w-40 flex">
              <img class="bg-gray-300 border-white m-auto border-8 w-32 h-32 rounded-full transform -translate-y-1/2 object-cover object-top" :src="$store.state.auth.profile.foto">
            </div>
            <div class="w-4/6 pr-10">
              <h1 class="text-2xl font-bold">{{ $store.state.auth.profile.nama }}</h1>
              <h2 class="">{{ $store.state.auth.profile.nim }}</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white shadow-md rounded-xl row-span-2 border">
        <div class="p-3">
          <div class="flex flex-col divide divide-y text-sm">
            <div class="p-1 px-3">
              <span class="font-light text-gray-500">Jenis Kelamin</span>
              <span class="block font-medium">{{ $store.state.auth.profile.jk }}</span>
            </div>
            <div class="p-1 px-3">
              <span class="font-light text-gray-500">Tempat, Tanggal Lahir</span>
              <span class="block font-medium">{{ $store.state.auth.profile.ttl }}</span>
            </div>
            <div class="p-1 px-3">
              <span class="font-light text-gray-500">Alamat</span>
              <span class="block font-medium">{{ $store.state.auth.profile.alamat }}</span>
            </div>
            <div class="p-1 pb-3 px-3">
              <span class="font-light text-gray-500">Email</span>
              <span class="block font-medium">{{ $store.state.auth.profile.email }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-2 bg-white shadow-md rounded-xl row-span-2 border">
        <div class="rounded-t-xl py-1 bg-gray-100 text-center text-gray-500">
          Profile Kuliah
        </div>
        <div class="p-3">
          <div class="flex flex-col divide divide-y text-sm">
            <div class="p-1 px-3">
              <span class="font-light text-gray-500">Angkatan</span>
              <span class="block font-medium">{{ $store.state.auth.profile.angkatan }}</span>
            </div>
            <div class="p-1 px-3">
              <span class="font-light text-gray-500">Prodi / Fakultas</span>
              <span class="block font-medium">{{ $store.state.auth.profile.prodi }}</span>
            </div>
            <div class="p-1 px-3">
              <span class="font-light text-gray-500">Dosen Pembimbing Akademik</span>
              <span class="block font-medium">{{ $store.state.auth.profile.dosen }}</span>
            </div>
            <div class="p-1 pb-3 px-3">
              <span class="font-light text-gray-500">Status Mahasiswa</span>
              <span class="block font-medium">{{ $store.state.auth.profile.status }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DashboardPage",
  data() {
    return {
      labeldata: null,
      prestasidata: null,
      jenisdata: null,
      prodidata: null,
      ajuanbeasiswa: {
        jenis_beasiswa: null,
        status_beasiswa: null,
        riwayat_beasiswa: []
      }
    }
  },
  components: {
  },
  mounted() {
    this.getProfile()
  },
  methods: {
    getProfile: function () {
      axios.post(process.env.VUE_APP_BASE_URL + "/api/profile-mahasiswa/", {
        nim: this.$store.state.auth.uid
      })
      .then(resp => {
        this.$store.commit('profile', resp.data)
      })
    },
    getAjuanBeasiswa: function () {
      axios.post(process.env.VUE_APP_BASE_URL + "/beasiswa/beasiswa-mahasiswa/getAjuanBeasiswa/", {
          nim: this.$store.state.auth.uid
        })
      .then(resp => {
        console.log(this.$store.state.auth.nim)
        console.log(resp.data)
        this.ajuanbeasiswa.jenis_beasiswa = resp.data.jenis_beasiswa
        this.ajuanbeasiswa.status_beasiswa = resp.data.riwayat_status[resp.data.riwayat_status.length - 1].status
        this.ajuanbeasiswa.riwayat_beasiswa = resp.data.riwayat_status
      })
      .catch(e => console.log(e))
    }
  }
}
</script>

<style scoped>

</style>