<template>
  <div>
    <div class="grid grid-cols-12 gap-x-5 gap-y-5 my-5">
      <MacCardComponent v-if="ajuanbeasiswa.status_beasiswa != null ? ajuanbeasiswa.status_beasiswa != 4 : false"
                        cardsname="Pengajuan Beasiswa Prestas | Beasiswa"
                        class="col-span-12 overflow-hidden">
        <div class="flex justify-between p-5">
          <div class="flex">
            <span class="mr-5 m-auto">Beasiswa yang di ajukan :</span>
            <div class="bg-blue-500 text-white rounded-lg px-2 py-1">
              Beasiswa Prestasi
            </div>
          </div>
          <div class="flex">
            <div class="flex">
              <span class="mr-5 m-auto">Status ajuan beasiswa :</span>
              <div class="text-white rounded-lg px-2 py-1"
                   :class="this.ajuanbeasiswa.status_beasiswa == 0 ? 'bg-indigo-500' : this.ajuanbeasiswa.status_beasiswa == 1 ? 'bg-amber-500' : this.ajuanbeasiswa.status_beasiswa == 2 ? 'bg-green-500' : this.ajuanbeasiswa.status_beasiswa == 3 ? 'bg-red-500' : this.ajuanbeasiswa.status_beasiswa == 4 ? 'bg-sky-500' : ''">
                {{
                  this.ajuanbeasiswa.status_beasiswa == 0 ? 'Tahap Validasi' : this.ajuanbeasiswa.status_beasiswa == 1 ? 'Proses Seleksi' : this.ajuanbeasiswa.status_beasiswa == 2 ? 'Disetujui' : this.ajuanbeasiswa.status_beasiswa == 3 ? 'Ditolak' : this.ajuanbeasiswa.status_beasiswa == 4 ? 'Selesai' : ''
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col p-5 px-10">
          <div v-for="(item, index, key) in this.ajuanbeasiswa.riwayat_beasiswa" :key="key" class="flex space-x-5">
            <div>
              <div class="w-5 h-5 bg-white border-4 rounded-full"
                   :class="item.status == 0 ? 'border-indigo-500' : item.status == 1 ? 'border-amber-500' : item.status == 2 ? 'border-green-500' : item.status == 3 ? 'border-red-500' : item.status == 4 ? 'border-sky-500' : ''"></div>
              <div class="flex h-full">
                <div class="h-full bg-gray-300 w-0.5 m-auto"></div>
              </div>
            </div>
            <div class="bg-white rounded-xl border border-gray-100 shadow-lg w-full my-5 p-3 py-4">
                <span class="text-white rounded-lg px-2 py-1"
                      :class="item.status == 0 ? 'bg-indigo-500' : item.status == 1 ? 'bg-amber-500' : item.status == 2 ? 'bg-green-500' : item.status == 3 ? 'bg-red-500' : item.status == 4 ? 'bg-sky-500' : ''">
                  {{
                    item.status == 0 ? 'Tahap Validasi' : item.status == 1 ? 'Proses Seleksi' : item.status == 2 ? 'Disetujui' : item.status == 3 ? 'Ditolak' : item.status == 4 ? 'Selesai' : ''
                  }}
                </span>
              <p class="my-3 text-sm bg-indigo-100 p-5 rounded-lg">{{ item.deskripsi }}</p>
              <span class="text-xs">{{ item.created_at | formatDate }}</span>
            </div>
          </div>
        </div>
      </MacCardComponent>
      <MacCardComponent v-if="ajuanbeasiswa.status_beasiswa != null ? ajuanbeasiswa.status_beasiswa == 4 : true"
                        cardsname="Pengajuan Beasiswa Prestas | Beasiswa"
                        class="col-span-12 overflow-hidden">
        <div class="px-5 mb-5">
          <div class="">
            <div class="grid grid-cols-4 gap-x-5 gap-y-7">
              <div class="col-span-1 col-start-1 flex space-x-2">
                <InputComponent v-model="form.prestasi.name" name="prestasi"
                                label="Prestasi"/>
                <button @click="openModal" class="bg-green-400 hover:bg-emerald-400 rounded-md px-2">Pilih</button>
              </div>
              <!--              <SelectComponent class="col-span-2 col-start-1" v-model="form.prestasi" name="prestasi"-->
              <!--                               label="Prestasi" type="text">-->
              <!--                <option v-for="(item, index) in this.prestasi" :key="index" :value="item.id">-->
              <!--                  {{ item.nama_event }}-->
              <!--                </option>-->
              <!--              </SelectComponent>-->
              <InputFileComponent class="col-span-1 col-start-1" @file="insertFilePernyataan" name="file_pernyataan"
                                  label="File Pernyataan"/>
              <InputFileComponent class="col-span-1" name="file_surat_tugas" @file="insertFileSuratTugas"
                                  label="File Surat Tugas"/>
              <div class="col-span-2 col-start-1">
                <button @click="submitForm"
                        class="bg-emerald-500 mr-3 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
                  Insert
                </button>
                <button @click="cancelForm"
                        class="bg-emerald-500 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </MacCardComponent>
    </div>
    <ModalComponent width="w-[70rem]">
      <div>
        <table class="w-full text-xs md:text-base mb-4">
          <thead class="text-left border-b bg-gray-100">
          <tr>
            <th class="pl-7 py-3 border-r">Prestasi</th>
            <th class="pl-4 py-3 border-r">Event</th>
            <th class="pl-4 py-3 border-r">Penyelenggara</th>
            <th class="pl-3.5 py-3 w-10">#</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, key) in this.prestasi" :key="key" class="border-y border-gray-200 h-10 py-3">
            <td class="truncate pl-7 py-3 border-r font-semibold">
              {{ item.prestasi_diraih }}
            </td>
            <td class="truncate pl-4 border-r">
              {{ item.nama_event }}
            </td>
            <td class="truncate pl-4 border-r">
              {{ item.penyelenggara }}
            </td>
            <td class="pl-1">
              <button class="hover:bg-gray-200 text-center rounded-lg w-7 h-7"
                      @click="pilihPrestasi(item.id, item.nama_event)">
                <font-awesome-icon icon="fa-solid fa-ellipsis-vertical"/>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ModalComponent>
  </div>
</template>

<script>
import MacCardComponent from "@/components/widget/MacCardComponent";
import axios from "axios";
// import SelectComponent from "@/components/widget/form/SelectComponent";
import InputFileComponent from "@/components/widget/form/InputFileComponent";
import InputComponent from "@/components/widget/form/InputComponent";
import ModalComponent from "@/components/widget/Modal/ModalComponent";

export default {
  name: "PengajuanPrestasiBeasiswa",
  data() {
    return {
      form: {
        prestasi: {
          id: null,
          name: null
        },
        mahasiswa: null,
        file_pernyataan: null,
        file_surat_tugas: null
      },
      ajuan: false,
      prestasi: [],
      ajuanbeasiswa: {
        jenis_beasiswa: null,
        status_beasiswa: null,
        riwayat_beasiswa: []
      }
    }
  },
  components: {
    InputComponent,
    MacCardComponent,
    // SelectComponent,
    InputFileComponent,
    ModalComponent
  },
  mounted() {
    this.$store.commit('refreshMahasiswa')
    this.checkAjuanBeasiswa()
    this.insertNim()
  },
  methods: {
    checkAjuanBeasiswa: function () {
      axios.post(process.env.VUE_APP_BASE_URL + "/beasiswa/beasiswa-mahasiswa/getAjuanBeasiswa/", {
        nim: this.$store.state.auth.uid
      }, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
          .then(resp => {
            if (resp.data) {
              this.ajuan = true
              this.getAjuanBeasiswa()
            }
          })
          .catch(e => {
            if (e.response.data.code === 'token_not_valid') {
              this.$store.commit('authLogout')
            }
          })
    },
    pilihPrestasi: function (id, nama_event) {
      this.form.prestasi.id = id
      this.form.prestasi.name = nama_event
      this.$store.commit('closeModal')
    },
    insertNim: function () {
      axios.post(process.env.VUE_APP_BASE_URL + '/prestasi/prestasi-mahasiswa/get_list/', {
        nim: this.$store.state.auth.uid
      }, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
          .catch(e => console.log(e))
          .then(resp => {
            this.prestasi = resp.data
          })
    },
    deleteMahasiswa: function (key) {
      if (key != 0) {
        this.$store.commit('deleteMahasiswa', key)
      }
    }
    ,
    submitForm: function () {
      const formData = new FormData()
      formData.append('type', 'prestasi')
      formData.append('prestasi', this.form.prestasi.id)
      formData.append('mahasiswa', this.$store.state.auth.uid)
      formData.append('file_pernyataan', this.form.file_pernyataan)
      formData.append('file_surat_tugas', this.form.file_surat_tugas)
      formData.append('token', this.$store.state.auth.token)
      axios.post(process.env.VUE_APP_BASE_URL + "/beasiswa/beasiswa-mahasiswa/insertBeasiswa/", formData, {headers: {'Authorization' : `Bearer   ${this.$store.state.auth.access_token}`}})
          .then(resp => {
            // console.log(resp)
            this.$store.commit('addNotification', resp.data)
          })
          .catch(e => this.$store.commit('addNotification', {type: 'error', message: e.message}))
    },
    insertFilePernyataan: function (input) {
      this.form.file_pernyataan = input
    },
    insertFileSuratTugas: function (input) {
      this.form.file_surat_tugas = input
    },
    cancelForm: function () {
      this.form.nama_event = null,
          this.form.url_kegiatan = null,
          this.form.penyelenggara = null,
          this.form.lingkup_tingkat = null,
          this.form.jumlah_peserta = null,
          this.form.jumlah_wilayah = null,
          this.form.jumlah_pt = null,
          this.form.kategori = null,
          this.form.prestasi_diraih = null,
          this.form.ekuivalensi = null,
          this.form.tempat = null,
          this.form.tanggal_mulai = null,
          this.form.tanggal_selesai = null,
          this.form.tahun_kegiatan = null,
          this.form.deskripsi = null,
          this.form.bukti = null,
          this.form.foto_kegiatan = null,
          this.form.tim_individu = null,
          this.form.jenis_prestasi = null,
          this.form.type_kejuaraan = null,
          this.form.anggota = []
    },
    openModal: function () {
      this.$store.commit('openModal', 'test')
    },
    getAjuanBeasiswa: function () {
      axios.post(process.env.VUE_APP_BASE_URL + "/beasiswa/beasiswa-mahasiswa/getAjuanBeasiswa/", {
        nim: this.$store.state.auth.uid
      }, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
          .then(resp => {
            console.log(this.$store.state.auth.uid)
            console.log(resp.data)
            this.ajuanbeasiswa.jenis_beasiswa = resp.data.jenis_beasiswa
            this.ajuanbeasiswa.status_beasiswa = resp.data.riwayat_status[resp.data.riwayat_status.length - 1].status
            this.ajuanbeasiswa.riwayat_beasiswa = resp.data.riwayat_status
          })
          .catch(e => console.log(e))
    }
  },
}
</script>

<style scoped>

</style>