<template>
  <div class="flex space-x-5 w-[50rem]">
    <div class="border h-96 w-full rounded-xl p-2 bg-gray-50 overflow-auto">
      <div class="flex flex-col space-y-1">
        <button v-for="(item, index, key) in this.deselectItem" @click="item.active = item.active == false" :key="key"
                :class="item.active ? 'bg-gray-300' : 'bg-white'"
                class="w-full text-left p-0.5 px-2 rounded-lg hover:bg-gray-200 transition-all ease-in-out duration-200">
          {{ item.first_name }} {{ item.last_name }} >
        </button>

      </div>
    </div>
    <div class="flex">
      <div class="m-auto text-center">
        <button @click="filterMahasiswaActive" class="m-auto bg-blue-400 px-2 py-0.5 text-white rounded-lg">Choose
        </button>
      </div>
    </div>
    <div class="border h-96 w-full rounded-xl p-2 bg-gray-50 overflow-auto">
      <div class="flex flex-col space-y-1">
        <button v-for="(item, index, key) in this.selectItem" @click="item.active = item.active == false"
                :key="key" :class="item.active ? 'bg-gray-300' : 'bg-white'"
                class="w-full text-left p-0.5 px-2 rounded-lg hover:bg-gray-200 transition-all ease-in-out duration-200">
          {{ item.first_name }} {{ item.last_name }} >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MultipleSelectGroupComponent",
  data() {
    return {
      item: [],
      selectItem: [],
      deselectItem: [],
    }
  },
  props: {
    itemdeselect: Array
  },
  mounted() {
    this.getMahasiswa()
    this.resetActive()

    // for (var i in this.selectItem) {
    //   this.item[i].active = true
    //   // console.log(i)
    // }
  },
  watch: {
    itemdeselect(val) {
      this.selectItem = val
      for (var i in this.itemdeselect) {
        for (var n in this.item) {
          if (this.item[n].id == this.itemdeselect[i].id) {
            // console.log(this.item[n], this.itemdeselect[i])
            this.item[n].active = true

          }
        }
      }
      this.filterMahasiswaActive()
    }
  },
  methods: {
    filterMahasiswaActive: function () {
      this.selectItem = this.item.filter((item) => {
        return item.active
      })
      this.deselectItem = this.item.filter((item) => {
        return !item.active
      })
      // this.resetActive()
      this.$emit('pilih', this.selectItem)
    },
    resetActive: function () {
      for (var i in this.item) {
        this.item[i].active = false
      }
    },
    getMahasiswa: function () {
      axios.get(process.env.VUE_APP_BASE_URL + "/api/users/")
          .then(resp => {
            // console.log(resp)
            this.item = resp.data
            for (var i in this.item) {
              this.item[i].active = false
            }
            for (var n in this.item) {
              for (var x in this.selectItem) {
                if (this.item[n].id === this.selectItem[x].id) {
                  this.item[n].push({active: false})
                }
              }
            }
            this.deselectItem = this.item.filter((item) => {
              return !item.active
            })
            // this.$store.commit('addNotification', resp.data)
          })
          .catch(e => this.$store.commit('addNotification', {type: 'error', message: e.message}))
    }
  }
}
</script>

<style scoped>

</style>