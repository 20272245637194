import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";

Vue.use(Vuex)

const state = {
    auth: {
        access_token: null,
        refresh_token: null,
        nama: null,
        nim: null,
        status: null,
        profile: {
            nama: null,
            nik: null,
            nim: null,
            ponsel: null,
            jk: null,
            angkatan: null,
            semester_ini: null,
            semester_awal: null,
            ipk: null,
            sks: null,
            prodi: null,
            fakultas: null,
            jenjang_program: null,
            ttl: null,
            alamat: null,
            email: null,
            dosen: null,
            status: null,
            foto: null
        }
    },
    mahasiswa: [],
    modal: {
        status: false,
        url: null
    },
    notification: [],
    chat: {
        comments: null,
        id: null,
        mahasiswa: {
            name: null
        }
    },
}

const store = new Vuex.Store({
    state,
    getters: {},
    mutations: {
        pushMahasiswa(state, item) {
            state.mahasiswa.push(item)
        },
        refreshMahasiswa(state) {
            state.mahasiswa = [
                {
                    nama: null,
                    nim: null
                }
            ];
        },
        deleteMahasiswa(state, item) {
            state.mahasiswa.splice(item, 1);
        },
        auth(state, data) {
            state.auth.access_token = data.access
            state.auth.refresh_token = data.refresh
            state.auth.uid = data.username
            state.auth.nama = data.first_name
            state.auth.status = data.status
        },
        profile(state, data) {
            state.auth.profile.foto = data.fotoContentUrl
            state.auth.profile.nama = data.nama
            state.auth.profile.nik = data.nik
            state.auth.profile.nim = data.idMahasiswa
            state.auth.profile.ponsel = data.ponsel
            state.auth.profile.jk = data.kodeGender
            state.auth.profile.angkatan = data.angkatan
            state.auth.profile.prodi = data.prodi.nama
            state.auth.profile.ttl = data.tempatLahir + ', ' + data.tanggalLahir
            state.auth.profile.alamat = data.alamat
            state.auth.profile.email = data.email
            state.auth.profile.dosen = data.dosen.nama
            state.auth.profile.status = data.kodeStatus
            state.auth.profile.semester_ini = data.semester_ini
            state.auth.profile.tahun_akademik = data.semesterAwal
            state.auth.profile.fakultas = data.prodi.fakultas.nama
            state.auth.profile.jenjang_program = data.prodi.tingkat
        },
        authLogout(state) {
            state.auth.access_token = null
            state.auth.refresh_token = null
            state.auth.nama = null
            state.auth.uid = null
            state.auth.nim = null
            state.auth.profile.foto = null
            state.auth.profile.nama = null
            state.auth.profile.nik = null
            state.auth.profile.nim = null
            state.auth.profile.ponsel = null
            state.auth.profile.jk = null
            state.auth.profile.angkatan = null
            state.auth.profile.prodi = null
            state.auth.profile.ttl = null
            state.auth.profile.alamat = null
            state.auth.profile.email = null
            state.auth.profile.dosen = null
            state.auth.profile.status = null
            state.auth.profile.semester_ini = null
            state.auth.profile.tahun_akademik = null
            state.auth.profile.fakultas = null
            state.auth.profile.jenjang_program = null
            state.auth.profile.ipk = null
            state.auth.profile.sks = null
        },
        closeModal(state) {
            state.modal.status = false
            document.body.classList.remove('removescrollbar', 'overflow-hidden')
        },
        openModal(state, url) {
            state.modal.url = url
            state.modal.status = true
            document.body.classList.add('removescrollbar', 'overflow-hidden')
        },
        getDataModal(state) {
            axios.get(state.modal.url)
                .catch(e => console.log(e))
                .then(resp => {
                    state.modal.data = resp.data
                })
        },
        closeNotification(state, key) {
            state.notification.splice(key, 1)
        },
        addNotification(state, data) {
            state.notification.push({type: data.type, message: data.message})
        },
        addChat(state, data) {
            state.chat = data
        },
        removeChat(state) {
            state.chat = null
        }
    },
    actions: {
        // getDataModal (context, url) {
        //     context.commit('urlModal', url)
        //     context.commit('getDataModal')
        // }
    },
    plugins: [createPersistedState()],
})

export default store