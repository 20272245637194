<template>
  <div class="flex h-full relative">
    <input @input="searchInput" :name="name" :id="'rdev-'+name" :type="type ? type : 'text'" v-model="input" placeholder="" :class="color ? color : 'bg-gray-200'" class="w-full text-md px-1.5 pt-2 pb-1.5 font-thin rounded-md peer outline-none border-b-2 border-b-blue-800/ focus:border-b-blue-800/100 transition-all ease-in-out">
    <label tabindex="1" :for="'rdev-'+name" :class="input ? 'text-black -translate-y-8' : 'select-none text-gray-400 cursor-text peer-focus:text-black peer-focus:-translate-y-8'" class="text-sm absolute px-2 h-full flex items-center transition-all ease-in-out">{{label}}</label>
  </div>
</template>

<script>
export default {
  name: "SearchInputComponent",
  data() {
    return {
      input: null
    }
  },
  props: [
    'label',
    'type',
    'name',
    'color'
  ],
  methods: {
    searchInput: function () {
      this.$emit('search', this.input)
    }
  }
}
</script>

<style scoped>

</style>