<template>
  <div>
    <div class="grid grid-cols-12 gap-x-5 gap-y-5 my-5">
      <MacCardComponent cardsname="Prestasi | Workshop" class="col-span-12 overflow-hidden">
        <div class="px-5">
          <form @submit="submitForm" class="">
            <div class="grid grid-cols-4 gap-x-5 gap-y-7 mb-5">
              <InputComponent class="col-span-2" v-model="form.nama_event" name="kegiatan" label="Event" type="text"/>
              <InputComponent class="col-span-1" v-model="form.penyelenggara" name="penyelenggara" label="Penyelenggara"
                              type="text"/>
              <InputComponent class="col-span-2 col-start-1" v-model="form.url_kegiatan" name="url_kegiatan"
                              label="URL Kegiatan" type="text"/>
              <SelectComponent class="col-span-1 col-start-1" v-model="form.lingkup_tingkat" name="lingkup_tingkat"
                               label="Lingkup Tingkat" type="text">
                <option value="provinsi">
                  Provinsi
                </option>
                <option value="wilayah">
                  Wilayah
                </option>
                <option value="nasional">
                  Nasional
                </option>
                <option value="internasional">
                  Internasional
                </option>
              </SelectComponent>
              <InputComponent class="col-span-1" v-model="form.jumlah_peserta" name="jumlah_peserta"
                              label="Jumlah Peserta" type="number"/>
              <InputComponent class="col-span-1" v-model="form.kategori" name="kategori"
                              label="Kategori" type="text"/>
              <SelectComponent class="col-span-2 col-start-1" v-model="form.prestasi_diraih" name="capaian_prestasi"
                               label="Capaian Prestasi" type="text">
                <option value="juara 1">
                  Juara 1
                </option>
                <option value="juara 2">
                  Juara 2
                </option>
                <option value="juara 3">
                  Juara 3
                </option>
                <option value="jaura harapan">
                  Juara Harapan
                </option>
                <option value="apresiasi">
                  Apresiasi
                </option>
                <option value="partisipasi">
                  Partisipasi
                </option>
              </SelectComponent>
              <InputComponent class="col-span-1" v-model="form.ekuivalensi" name="ekuvalensi"
                              label="Ekuivalensi" type="text"/>
              <InputComponent class="col-span-1" v-model="form.tempat" name="tempat"
                              label="Tempat" type="text"/>
              <InputComponent class="col-span-1" v-model="form.tanggal_mulai" name="tanggal_mulai" label="Tanggal Mulai"
                              type="date"/>
              <InputComponent class="col-span-1" v-model="form.tanggal_selesai" name="tanggal_selesai"
                              label="Tanggal Selesai" type="date"/>
              <InputComponent class="col-span-2" v-model="form.deskripsi" name="deskripsi"
                              label="Deskripsi" type="text"/>
              <InputFileComponent class="col-span-1" @file="insertBukti" name="sertifikat" label="Sertifikat"/>
              <InputFileComponent class="col-span-1" @file="insertFotoKegiatan" name="foto_kegiatan"
                                  label="Foto Kegiatan"/>
              <SelectComponent class="col-span-1 col-start-1" v-model="form.tim_individu" name="tim_individu"
                               label="Jenis Kepesertaan" type="text">
                <option value="tim">
                  Kelompok
                </option>
                <option value="individu">
                  Individu
                </option>
              </SelectComponent>
            </div>
          </form>
          <div class="col-start-1 col-span-2" v-if="form.tim_individu === 'tim'">
            <label>Anggota Tim</label>
            <div class="rounded-md py-4 bg-white">
              <table class="table-auto w-full">
                <thead class="border-b border-gray-300 rounded-xl">
                <tr>
                  <th>NIM</th>
                  <th>Nama</th>
                  <th class="w-9">Aksi</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in this.$store.state.mahasiswa" v-bind:key="index">
                  <td>
                    <form>
                      <input @keyup.enter="insertNim(index, item.nim)"
                             class="bg-gray-100 focus:bg-white w-full py-2 pl-5 pr-3 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 font-semibold"
                             type="text" v-model="item.nim">
                    </form>
                  </td>
                  <td>
                    <input disabled
                           class="bg-gray-100 focus:bg-white w-full py-2 pl-5 pr-3 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 font-semibold"
                           type="text" v-model="item.nama">
                  </td>
                  <td class="px-1">
                    <button @click="deleteMahasiswa(index)"
                            class="bg-rose-500 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
                      <font-awesome-icon icon="fas fa-minus"/>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <!--                    <input class="bg-gray-100 focus:bg-white w-full py-2 pl-5 pr-3 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 font-semibold" type="text" name="tim_individu">-->
                  </td>
                  <td>
                    <!--                    <input class="bg-gray-100 focus:bg-white w-full py-2 pl-5 pr-3 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 font-semibold" type="text" name="tim_individu">-->
                  </td>
                  <td class="px-1">
                    <button @click="addMahasiswa"
                            class="bg-emerald-500 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
                      <font-awesome-icon icon="fas fa-plus"/>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-span-2 col-start-1 mt-5">
            <button form="form_penghargaan"
                    class="bg-emerald-500 mr-3 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-2">
              Ajukan
            </button>
            <button @click="cancelForm"
                    class="bg-red-500 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-2">
              Batal
            </button>
          </div>
        </div>
      </MacCardComponent>
    </div>
  </div>
</template>

<script>
import MacCardComponent from "@/components/widget/MacCardComponent";
import axios from "axios";
import SelectComponent from "@/components/widget/form/SelectComponent";
import InputComponent from "@/components/widget/form/InputComponent";
import InputFileComponent from "@/components/widget/form/InputFileComponent";

export default {
  name: "WorkshopPage",
  data() {
    return {
      form: {
        nama_event: null,
        url_kegiatan: null,
        penyelenggara: null,
        lingkup_tingkat: null,
        jumlah_peserta: null,
        jumlah_wilayah: null,
        jumlah_pt: null,
        kategori: null,
        prestasi_diraih: null,
        ekuivalensi: null,
        tempat: null,
        tanggal_mulai: null,
        tanggal_selesai: null,
        tahun_kegiatan: null,
        deskripsi: null,
        bukti: null,
        foto_kegiatan: null,
        tim_individu: null,
        jenis_prestasi: null,
        type_kejuaraan: null,
        anggota: []
      },
      selectForm: 'kejuaraan'
    }
  },
  components: {
    MacCardComponent,
    SelectComponent,
    InputComponent,
    InputFileComponent
  },
  mounted() {
    this.$store.commit('refreshMahasiswa')
  },
  methods: {
    addMahasiswa: function () {
      this.$store.commit('pushMahasiswa', {nim: null, nama: null})
    },
    insertNim: function (key, nim) {
      if (!this.form.anggota.includes(nim)) {
        this.form.anggota[key] = nim
        axios.post(process.env.VUE_APP_BASE_URL + "/api/insert-nim/", {
          nim: nim
        }, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
            .then(resp => {
              console.log(resp)
              this.$store.state.mahasiswa[key].nama = resp.data.name
            })
            .catch(e => {
              console.log(e)
              this.$store.state.mahasiswa[key].nama = null
            })
            .finally({})
      } else {
        alert('anda sudah memasukkan NIM tersebut')
      }
      // for (let i in this.$store.state.mahasiswa) {
      //   if (nim == this.$store.state.mahasiswa[i].nim) {
      //     if (key == i) {
      //       axios.post(process.env.VUE_APP_BASE_URL + "/api/insert-nim/", {
      //         nim: nim
      //       })
      //           .then(resp => {
      //             console.log(resp)
      //             this.$store.state.mahasiswa[key].nama = resp.data.name
      //             this.form.anggota.push(nim)
      //           })
      //           .catch(e => {
      //             console.log(e)
      //             this.$store.state.mahasiswa[key].nama = null
      //           })
      //     } else {
      //       alert('anda sudah memasukkan NIM tersebut')
      //       break
      //     }
      //   } else {
      //     alert('anda sudah memasukkan NIM tersebut')
      //     break
      //   }
      // }
    },
    deleteMahasiswa: function (key) {
      if (key != 0) {
        this.$store.commit('deleteMahasiswa', key)
      }
    },
    submitForm: function () {
      if (this.form.tim_individu == 'individu') {
        this.form.anggota[0] = this.$store.state.auth.uid
        axios.post(process.env.VUE_APP_BASE_URL + "/api/insert-nim/", {
          nim: this.$store.state.auth.uid
        }, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
            .then(resp => {
              console.log(resp)
              this.$store.state.mahasiswa[0].nama = resp.data.name
            })
            .catch(e => {
              if (e.response.data.code === 'token_not_valid') {
                this.$store.commit('authLogout')
              }
              console.log(e)
              this.$store.state.mahasiswa[0].nama = null
            })
            .finally({})
      }
      const formData = new FormData()
      formData.append('nama_event', this.form.nama_event)
      formData.append('url_kegiatan', this.form.url_kegiatan)
      formData.append('penyelenggara', this.form.penyelenggara)
      formData.append('lingkup_tingkat', this.form.lingkup_tingkat)
      formData.append('jumlah_peserta', this.form.jumlah_peserta)
      formData.append('jumlah_wilayah', this.form.jumlah_wilayah)
      formData.append('jumlah_pt', this.form.jumlah_pt)
      formData.append('kategori', this.form.kategori)
      formData.append('prestasi_diraih', this.form.prestasi_diraih)
      formData.append('ekuivalensi', this.form.ekuivalensi)
      formData.append('tempat', this.form.tempat)
      formData.append('tanggal_mulai', this.form.tanggal_mulai)
      formData.append('tanggal_selesai', this.form.tanggal_selesai)
      formData.append('tahun_kegiatan', this.form.tahun_kegiatan)
      formData.append('deskripsi', this.form.deskripsi)
      formData.append('tim_individu', this.form.tim_individu)
      formData.append('type_kejuaraan', this.form.type_kejuaraan)
      if (this.form.foto_kegiatan) {
        formData.append('foto_kegiatan', this.form.foto_kegiatan, this.form.foto_kegiatan.name)
      }
      if (this.form.bukti) {
        formData.append('bukti', this.form.bukti, this.form.bukti.name)
      }
      formData.append('anggota', JSON.stringify(this.form.anggota))
      formData.append('jenis_prestasi', 'seminar')
      formData.append('token', this.$store.state.auth.refresh_token)
      axios.post(process.env.VUE_APP_BASE_URL + "/prestasi/prestasi-mahasiswa/insertPrestasi/", formData, {headers: {'Authorization': `Bearer   ${this.$store.state.auth.access_token}`}})
          .then(resp => {
            // console.log(resp)
            this.$store.commit('addNotification', resp.data)
          })
          .catch(e => this.$store.commit('addNotification', {type: 'error', message: e.message}))
    },
    insertFotoKegiatan: function (input) {
      this.form.foto_kegiatan = input
    },
    insertBukti: function (input) {
      this.form.bukti = input
    },
    cancelForm: function () {
      this.form.nama_event = null,
          this.form.url_kegiatan = null,
          this.form.penyelenggara = null,
          this.form.lingkup_tingkat = null,
          this.form.jumlah_peserta = null,
          this.form.jumlah_wilayah = null,
          this.form.jumlah_pt = null,
          this.form.kategori = null,
          this.form.prestasi_diraih = null,
          this.form.ekuivalensi = null,
          this.form.tempat = null,
          this.form.tanggal_mulai = null,
          this.form.tanggal_selesai = null,
          this.form.tahun_kegiatan = null,
          this.form.deskripsi = null,
          this.form.bukti = null,
          this.form.foto_kegiatan = null,
          this.form.tim_individu = null,
          this.form.jenis_prestasi = null,
          this.form.type_kejuaraan = null,
          this.form.anggota = []
    }
  }
}
</script>

<style scoped>

</style>