<template>
  <div class="flex relative group w-96">
    <div class="flex space-x-1">
      <div class="w-4 h-4 m-auto rounded-full" :class="status == 1 ? 'bg-blue-500' : status == 2 ? 'bg-rose-500' : 'bg-green-500'"></div>
      <div class="m-auto">{{status == 1 ? 'Diproses' : status == 2 ? 'Ditolak' : 'Disetujui'}}</div>
    </div>
    <div class="absolute p-1 px-2 bg-white border rounded-lg shadow bottom-0 group-hover:bottom-10 transition-all opacity-0 group-hover:opacity-100 ease-in-out duration-200">{{pesan}}</div>
  </div>
</template>

<script>
export default {
  name: "ToolTipStatusComponent",
  props: ['status', 'pesan']
}
</script>

<style scoped>

</style>