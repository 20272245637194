<template>
  <div class="flex w-full">
    <div id="mobile-menu"
         class="h-full md:h-auto text-white z-40 fixed duration-300 bg-cyan-900 border-r md:static overflow-hidden"
         @mouseenter="CollapsedHover()" @mouseleave="CollapsedHover()"
         :class="collapsed ? 'w-0 md:w-80 lg:w-80' : 'w-80 md:w-11 lg:w-11 md:hover:w-80'">
      <div class="font-bold text-xl h-16 md:pr-0 flex relative z-50">
        <div class="flex relative transition-all ease-in-out duration-500 w-full">
          <img class="h-9 w-9 ml-1 mr-3 my-auto" src="@/assets/logo-umkt.png" alt="">
          <div class="flex w-full">
            <div class="m-auto">
              <h1
                  class="my-auto text-2xl align-middle overflow-hidden truncate font-bold">
                SIKEMAS
              </h1>
              <h2 class="text-xs font-thin truncate">
                Sistem Informasi Kemahasiswaan
              </h2>
            </div>
            <button @click="CloseBar()" type="button"
                    class="transition-all shrink-0 mr-0 w-10 h-10 text-black rounded-lg ease-in-out duration-500 z-50 m-auto md:hidden">
              <!-- Heroicon name: outline/x -->
              <font-awesome-icon class="text-white" :icon="collapsed ? 'fas fa-bars' : 'fas fa-times'"/>
            </button>
          </div>
        </div>
      </div>
      <div class="text-lg font-normal tracking-tight">
        <div class="py-5 px-1 py-2 grid grid-cols-1">
          <router-link to="/dashboard" class="py-0.5 rounded-xl overflow-hidden truncate cursor-pointer">
            <div class="hover:bg-cyan-700 px-2 pt-1 rounded-lg">
              <div class="flex w-52">
                <div class="w-1/6">
                  <font-awesome-icon icon="fa fa-tachometer-alt"/>
                </div>
                <span>Dashboard</span>
              </div>
            </div>
          </router-link>
          <NavbarDropdownComponent>
            <template #group-icon>
              <font-awesome-icon icon="fas fa-graduation-cap"/>
            </template>
            <template #group>
              <div>Prestasi Mahasiswa</div>
            </template>
            <template #item>
              <router-link to="/prestasi-penghargaan" class="px-2 rounded-md overflow-hidden">
                <button class="my-1 cursor-pointer flex w-52">
                  <div class="mr-2">
                    <font-awesome-icon icon="fas fa-medal"/>
                  </div>
                  <span class="">Penghargaan</span>
                </button>
              </router-link>
              <router-link to="/prestasi-seminar" class="px-2 rounded-md overflow-hidden">
                <button class="my-1 cursor-pointer flex w-52">
                  <div class="mr-2">
                    <font-awesome-icon icon="fas fa-chalkboard-teacher"/>
                  </div>
                  <span>Seminar/Workshop/Keahlian</span></button>
              </router-link>
              <!--              <router-link to="/prestasi-organisasi" class="px-2 rounded-md overflow-hidden">-->
              <!--                <button class="my-1 cursor-pointer flex w-52">-->
              <!--                  <div class="mr-2">-->
              <!--                    <font-awesome-icon icon="fas fa-sitemap"/>-->
              <!--                  </div>-->
              <!--                  <span>Organisasi/Kepanitiaan</span></button>-->
              <!--              </router-link>-->
              <router-link to="/list-prestasi" class="px-2 rounded-md overflow-hidden">
                <button class="my-1 cursor-pointer flex w-52">
                  <div class="mr-2">
                    <font-awesome-icon icon="fas fa-sitemap"/>
                  </div>
                  <span>List Prestasi</span></button>
              </router-link>
            </template>
          </NavbarDropdownComponent>
          <NavbarDropdownComponent>
            <template #group-icon>
              <font-awesome-icon icon="fa fa-certificate"/>
            </template>
            <template #group>
              <div>Beasiswa</div>
            </template>
            <template #item>
              <router-link to="/pengajuan-beasiswa-prestasi" class="px-2 rounded-md overflow-hidden">
                <button class="my-1 cursor-pointer flex w-52">
                  <div class="mr-2">
                    <font-awesome-icon icon="fas fa-splotch"/>
                  </div>
                  <span class="">Pengajuan Beasiswa Prestasi</span>
                </button>
              </router-link>
              <router-link to="/pengajuan-kip" class="px-2 rounded-md overflow-hidden">
                <button class="my-1 cursor-pointer flex w-52">
                  <div class="mr-2">
                    <font-awesome-icon icon="fas fa-splotch"/>
                  </div>
                  <span>Pengajuan KIP Kuliah bantuan UKT Semester 3, 5, 7</span></button>
              </router-link>
              <router-link to="/beasiswa-bankaltimtara" class="px-2 rounded-md overflow-hidden">
                <button class="my-1 cursor-pointer flex w-52">
                  <div class="mr-2">
                    <font-awesome-icon icon="fas fa-splotch"/>
                  </div>
                  <span>Pengajuan Beasiswa Bankaltimtara</span></button>
              </router-link>
              <router-link to="/beasiswa-daerah" class="px-2 rounded-md overflow-hidden">
                <button class="my-1 cursor-pointer flex w-52">
                  <div class="mr-2">
                    <font-awesome-icon icon="fas fa-splotch"/>
                  </div>
                  <span>Pengajuan Beasiswa Daerah</span></button>
              </router-link>
              <router-link to="/beasiswa-skk-migas" class="px-2 rounded-md overflow-hidden">
                <button class="my-1 cursor-pointer flex w-52">
                  <div class="mr-2">
                    <font-awesome-icon icon="fas fa-splotch"/>
                  </div>
                  <span>Beasiswa SKK Migas</span></button>
              </router-link>
              <!--              <router-link to="/list-beasiswa" class="px-2 rounded-md overflow-hidden">-->
              <!--                <button class="my-1 cursor-pointer flex w-52">-->
              <!--                  <div class="mr-2">-->
              <!--                    <font-awesome-icon icon="fas fa-splotch"/>-->
              <!--                  </div>-->
              <!--                  <span>List Pengajuan Beasiswa</span></button>-->
              <!--              </router-link>-->
            </template>
          </NavbarDropdownComponent>
          <router-link to="/administrasi" class="py-0.5 rounded-xl overflow-hidden truncate cursor-pointer">
            <div class="hover:bg-cyan-700 px-2 pt-1 rounded-lg">
              <div class="flex w-52">
                <div class="w-1/6">
                  <font-awesome-icon icon="fa fa-toolbox"/>
                </div>
                <span>Administrasi</span>
              </div>
            </div>
          </router-link>
          <router-link to="/organisasi" class="py-0.5 rounded-xl overflow-hidden truncate cursor-pointer">
            <div class="hover:bg-cyan-700 px-2 pt-1 rounded-lg">
              <div class="flex w-52">
                <div class="w-1/6">
                  <font-awesome-icon icon="fa fa-toolbox"/>
                </div>
                <span>Ormawa</span>
              </div>
            </div>
          </router-link>
          <router-link to="/bimbingan-pa" class="py-0.5 rounded-xl overflow-hidden truncate cursor-pointer">
            <div class="hover:bg-cyan-700 px-2 pt-1 rounded-lg">
              <div class="flex w-52">
                <div class="w-1/6">
                  <font-awesome-icon icon="fa fa-person-chalkboard"/>
                </div>
                <span>Bimbingan</span>
              </div>
            </div>
          </router-link>
          <div @click="logout" class="py-0.5 rounded-xl overflow-hidden truncate cursor-pointer">
            <div class="hover:bg-cyan-700 px-2 pt-1 rounded-lg">
              <div class="flex w-52">
                <div class="w-1/6">
                  <font-awesome-icon icon="fas fa-sign-out"/>
                </div>
                <span>Logout</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full min-h-screen">
      <div class="bg-white border-b w-full h-14">
        <button @click="CloseBar()" type="button"
                class="transition-all shrink-0 -mr-3 w-14 h-14 text-xl text-black ease-in-out duration-500 z-50 m-auto -mr-5">
          <!-- Heroicon name: outline/x -->
          <font-awesome-icon :icon="collapsed ? 'fas fa-bars' : 'fas fa-times'"/>
        </button>
      </div>
      <div class="w-full">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarDropdownComponent from "@/components/layout/widget/NavbarDropdownComponent";

export default {
  name: "NavbarDashComponent",
  components: {
    NavbarDropdownComponent,
  },
  data() {
    return {
      collapsed: true,
      hover: true
    }
  },
  methods: {
    CloseBar: function () {
      if (this.collapsed === true) {
        this.hover = false
        this.collapsed = false
        document.body.classList.add('removescrollbar', 'overflow-hidden')
      } else {
        this.hover = true
        this.collapsed = true
        document.body.classList.remove('removescrollbar', 'overflow-hidden')
      }
    },
    CollapsedHover: function () {
      if (this.hover === true) {
        return 'nothing';
      } else {
        this.collapsed = false;
      }
    },
    logout: function () {
      this.$store.commit('authLogout')
      window.location.href = 'https://sso.umkt.ac.id/cas/logout?service=' + window.location.href
      // axios.post(process.env.VUE_APP_BASE_URL + '/api/auth/logout/')
      //     .then(resp => {
      //       console.log(resp)
      //       // this.$store.commit('auth', resp.data)
      //       // this.$router.push({ path: '/' })
      //     })
      //     .catch(e => console.log(e))
      // this.$store.commit('authLogout')
      // this.$router.push({ name: 'login', query: { redirect: '/login' } });
    }
  }
}
</script>

<style scoped>

</style>