<template>
  <div class="relative">
    <label tabindex="1"
           class="absolute px-2 -translate-y-6 text-sm flex items-center transition-all ease-in-out">{{ label }}</label>
    <div tabindex="1"
         class="h-full bg-gray-200 rounded-md flex relative border-b-2 border-b-blue-800/ hover:border-b-blue-800/100 transition-all ease-in-out">
      <label type="button" :for="'rdev-'+name"
             class="cursor-pointer px-3 rounded-l-md text-center flex hover:bg-gray-300 transition-all ease-in-out">
        <font-awesome-icon class="m-auto" icon="fa-solid fa-arrow-up-from-bracket"/>
      </label>
      <input :disabled="disabled" @change="insertFotoKegiatan" :name="name" :id="'rdev-'+name" type="file" class="cursor-pointer w-full text-md px-1.5 pt-2 pb-1.5 font-thin peer outline-none
      file:hidden
    " :required="required"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputFileComponent",
  data() {
    return {
      input: null
    }
  },
  props: {
    label: String,
    type: String,
    name: String,
    disabled: Boolean,
    required: Boolean
  },
  methods: {
    insertFotoKegiatan: function () {
      this.input = event.target.files[0]
      this.fileInput()
    },
    fileInput: function () {
      this.$emit('file', this.input)
    },
  }
}
</script>

<style scoped>

</style>