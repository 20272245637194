<template>
  <div class="flex group">
    <img class="w-10 h-10 z-0 group-hover:z-10 rounded-full border-2 border-white shadow-lg object-cover object-top" :src="mahasiswa.image">
    <div class="absolute mt-7 z-10 m-auto bg-gray-800 rounded-lg text-white text-xs p-2 truncate border border-white w-40 h-0 group-hover:h-9 opacity-0 group-hover:opacity-100 transition-all ease-in-out">
      {{mahasiswa.name}}
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MahasiswaAvatar",
  data() {
    return {
      mahasiswa: {
        name: null,
        image: null
      }
    }
  },
  props: [
      'nim'
  ],
  methods: {
    getMahasiswaProfile: function () {
      axios
          .post(process.env.VUE_APP_BASE_URL + "/api/insert-nim/", {
            nim: this.$props.nim
          })
          .then(resp => {
            this.mahasiswa.name = resp.data.name
            this.mahasiswa.image = resp.data.image
          })
    },
    // getGravatar: function () {
    //   const address = String(this.$props.nim).trim().toLowerCase()
    //   const hash = md5(address)
    //
    //   this.mahasiswa.image = `https://www.gravatar.com/avatar/${ hash }`
    //
    // }
  },
  mounted() {
    this.getMahasiswaProfile()
    // this.getGravatar()
  }
}
</script>

<style scoped>

</style>