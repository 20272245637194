<template>
  <div>
    <div class="grid grid-cols-12 gap-x-5 gap-y-5 my-5">
      <MacCardComponent cardsname="Pengajuan Beasiswa Prestas | Beasiswa" class="col-span-12 overflow-hidden">
        <div class="px-5 mb-5">
          <div class="">
            <div class="grid grid-cols-4 gap-x-5 gap-y-7 pt-4">
              <InputFileComponent class="col-span-1 col-start-1" @file="insertFilePernyataan" name="file_pernyataan"
                                  label="File Pernyataan"/>
              <div class="col-span-2 col-start-1">
                <button @click="submitForm"
                        class="bg-emerald-500 mr-3 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
                  Insert
                </button>
                <button @click="cancelForm"
                        class="bg-emerald-500 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </MacCardComponent>
    </div>
  </div>
</template>

<script>
import MacCardComponent from "@/components/widget/MacCardComponent";
import axios from "axios";
import InputFileComponent from "@/components/widget/form/InputFileComponent";

export default {
  name: "SkKepengurusanPage",
  data() {
    return {
      form: {
        semester: null,
        nominal_spp_tetap: null,
        nominal_spp_variabel: null,
        file_formulir_pendaftaran: null,
        file_surat_pernyataan_wali: null,
        file_ktp: null,
        foto_rumah: [],
        file_keanggotaan_phk: null,
        mahasiswa: null,
        file_pernyataan: null,
      },
      prestasi: []
    }
  },
  components: {
    MacCardComponent,
    InputFileComponent,
  },
  mounted() {
    this.$store.commit('refreshMahasiswa')
  },
  methods: {
    getLaporanKeuangan: function () {
      axios.post(process.env.VUE_APP_BASE_URL + '/beasiswa/beasiswa-mahasiswa/getLaporanKeuangan/', {
        nim: this.$store.state.auth.nim,
        semester: this.form.semester
      })
          .catch(e => console.log(e))
          .then(resp => {
            console.log(resp.data)
            this.form.nominal_spp_tetap = resp.data.rekap[0].biaya
            this.form.nominal_spp_variabel = resp.data.rekap[1].biaya
          })
    },
    // insertNim: function () {
    //   axios.post(process.env.VUE_APP_BASE_URL + '/prestasi/prestasi-mahasiswa/get_list/', {
    //     nim: this.$store.state.auth.uid
    //   })
    //       .catch(e => console.log(e))
    //       .then(resp => {
    //         console.log(resp.data)
    //         this.prestasi = resp.data
    //       })
    // },
    deleteMahasiswa: function (key) {
      if (key != 0) {
        this.$store.commit('deleteMahasiswa', key)
      }
    }
    ,
    submitForm: function () {
      const formData = new FormData()
      formData.append('type', 'daerah')
      formData.append('mahasiswa', this.$store.state.auth.nim)
      formData.append('file_pernyataan', this.form.file_pernyataan)
      formData.append('token', this.$store.state.auth.refresh_token)
      axios.post(process.env.VUE_APP_BASE_URL + "/beasiswa/beasiswa-mahasiswa/insertBeasiswa/",
        formData
      ,)
          .then(resp => {
            // console.log(resp)
            this.$store.commit('addNotification', resp.data)
          })
          .catch(e => this.$store.commit('addNotification', {type: 'error', message: e.message}))
    },
    insertFilePernyataan: function (input) {
      this.form.file_pernyataan = input
    },
    insertFileFormulirPendaftaran: function (input) {
      this.form.file_formulir_pendaftaran = input
    },
    insertFileSuratPernyataanWali: function (input) {
      this.form.file_surat_pernyataan_wali = input
    },
    insertFileKeanggotaanPHK: function (input) {
      this.form.file_keanggotaan_phk = input
    },
    insertFileSuratTugas: function (input) {
      this.form.file_surat_tugas = input
    },
    insertFotoRumah: function (uploaded) {
      this.form.foto_rumah = uploaded
    },
    cancelForm: function () {
      this.form.nama_event = null,
          this.form.url_kegiatan = null,
          this.form.penyelenggara = null,
          this.form.lingkup_tingkat = null,
          this.form.jumlah_peserta = null,
          this.form.jumlah_wilayah = null,
          this.form.jumlah_pt = null,
          this.form.kategori = null,
          this.form.prestasi_diraih = null,
          this.form.ekuivalensi = null,
          this.form.tempat = null,
          this.form.tanggal_mulai = null,
          this.form.tanggal_selesai = null,
          this.form.tahun_kegiatan = null,
          this.form.deskripsi = null,
          this.form.bukti = null,
          this.form.foto_kegiatan = null,
          this.form.tim_individu = null,
          this.form.jenis_prestasi = null,
          this.form.type_kejuaraan = null,
          this.form.anggota = []
    }
  },
}
</script>

<style scoped>

</style>