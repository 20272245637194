<template>
  <div class="flex relative">
    <button @click="activeButton" class="hover:bg-gray-200 rounded-lg w-8 h-8 text-center">
      <font-awesome-icon icon="fa-solid fa-ellipsis-vertical"/>
    </button>
    <div v-if="active" class="absolute bg-white rounded-lg shadow-lg border right-5 top-3 flex-row space-y-1 divide-y">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuDropdown",
  data() {
    return {
      active: false
    }
  },
  methods: {
    activeButton: function () {
      this.active = this.active === false;
    }
  }
}
</script>

<style scoped>

</style>