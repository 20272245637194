<template>
  <div>
    <div class="grid grid-cols-12 gap-x-5 gap-y-5 my-5">
      <MacCardComponent cardsname="Beasiswa | Table" class="col-span-12 overflow-hidden">
        <div class="p-5">
          <div class="mb-5 w-96">
            <SearchInputComponent :disabled="disabled" @search="searchInput" v-model="search" class="col-span-2"
                                  name="search" label="Search"
                                  type="text" color="bg-color-white"/>
          </div>
          <div class="rounded-lg border-2 bg-white">
            <table class="w-full text-xs md:text-base mb-4">
              <thead class="text-left border-b bg-gray-100">
              <tr>
                <th class="pl-7 py-3 border-r">Mahasiswa</th>
                <th class="pl-4 py-3 border-r">Jenis Beasiswa</th>
                <th class="pl-3.5 py-3 w-10">#</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, key) in this.datafilter" :key="key" class="border-y border-gray-200 h-10 py-3">
                <td class="truncate pl-7 py-3 border-r font-semibold">
                  <div class="flex">
                    <MahasiswaAvatar class="-ml-4" :nim="item.mahasiswa.nim"/>
                  </div>
                </td>
                <td class="truncate pl-4 border-r">
                  {{ item.jenis_beasiswa }}
                </td>
                <td class="pl-1">
                  <button class="hover:bg-gray-200 text-center rounded-lg w-7 h-7" @click="openModal(item.id)">
                    <font-awesome-icon icon="fa-solid fa-ellipsis-vertical"/>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </MacCardComponent>
    </div>
    <ModalComponent>
      <div class="grid grid-cols-4 gap-x-5 gap-y-7" v-if="form.jenis_prestasi != 'kejuaraan'">
        <InputComponent :disabled="disabled" class="col-span-2" v-model="form.nama_event" :value="form.nama_event"
                        name="kegiatan" label="Kegiatan" type="text"/>
        <InputComponent :disabled="disabled" class="col-span-1"
                        name="penyelenggara"
                        label="Penyelenggara"
                        type="text"/>
        <InputComponent :disabled="disabled" class="col-span-2 col-start-1"
                        v-model="form.url_kegiatan" :value="form.url_kegiatan"
                        name="url_kegiatan"
                        label="URL Kegiatan" type="text"/>
        <SelectComponent :disabled="disabled" class="col-span-1 col-start-1"
                         v-model="form.lingkup_tingkat" :value="form.lingkup_tingkat"
                         name="lingkup_tingkat"
                         label="Lingkup Tingkat" type="text">
          <option value="provinsi">
            Provinsi
          </option>
          <option value="wilayah">
            Wilayah
          </option>
          <option value="nasional">
            Nasional
          </option>
          <option value="internasional">
            Internasional
          </option>
        </SelectComponent>
        <InputComponent :disabled="disabled" class="col-span-1" v-model="form.jumlah_peserta" :value="form.jumlah_peserta"
                        name="jumlah_peserta"
                        label="Jumlah Peserta" type="number"/>
        <InputComponent :disabled="disabled" class="col-span-1" v-model="form.kategori" :value="form.kategori"
                        name="kategori"
                        label="Kategori" type="text"/>
        <SelectComponent :disabled="disabled" class="col-span-2 col-start-1"
                         v-model="form.prestasi_diraih" :value="form.prestasi_diraih"
                         name="capaian_prestasi"
                         label="Capaian Prestasi" type="text">
          <option value="juara 1">
            Juara 1
          </option>
          <option value="juara 2">
            Juara 2
          </option>
          <option value="juara 3">
            Juara 3
          </option>
          <option value="jaura harapan">
            Juara Harapan
          </option>
          <option value="apresiasi">
            Apresiasi
          </option>
          <option value="partisipasi">
            Partisipasi
          </option>
        </SelectComponent>
        <InputComponent :disabled="disabled" class="col-span-1" v-model="form.ekuivalensi" :value="form.ekuivalensi"
                        name="ekuvalensi"
                        label="Ekuivalensi" type="text"/>
        <InputComponent :disabled="disabled" class="col-span-1" v-model="form.tempat" :value="form.tempat" name="tempat"
                        label="Tempat" type="text"/>
        <InputComponent :disabled="disabled" class="col-span-1" v-model="form.tanggal_mulai" :value="form.tanggal_mulai"
                        name="tanggal_mulai"
                        label="Tanggal Mulai"
                        type="date"/>
        <InputComponent :disabled="disabled" class="col-span-1" v-model="form.tanggal_selesai" :value="form.tanggal_selesai"
                        name="tanggal_selesai"
                        label="Tanggal Selesai" type="date"/>
        <InputComponent :disabled="disabled" class="col-span-2" v-model="form.deskripsi" :value="form.deskripsi"
                        name="deskripsi"
                        label="Deskripsi" type="text"/>
        <InputFileComponent :disabled="disabled" class="col-span-1" @file="insertBukti" name="sertifikat"
                            label="Sertifikat"/>
        <a class="text-xs text-blue-500" target="_blank" :href="form.bukti">lihat Sertifikat</a>
        <InputFileComponent :disabled="disabled" class="col-span-1" @file="insertFotoKegiatan" name="foto_kegiatan"
                            label="Foto Kegiatan"/>
        <a class="text-xs text-blue-500" target="_blank" :href="form.foto_kegiatan">lihat Bukti
          Foto</a>
        <SelectComponent :disabled="disabled" class="col-span-1 col-start-1"
                         v-model="form.tim_individu" :value="form.tim_individu"
                         name="tim_individu"
                         label="Jenis Kepesertaan" type="text">
          <option value="tim">
            Kelompok
          </option>
          <option value="individu">
            Individu
          </option>
        </SelectComponent>
      </div>
      <div class="" v-if="form.jenis_prestasi == 'kejuaraan'">
        <div class="grid grid-cols-4 gap-x-5 gap-y-7" v-if="form.type_kejuaraan == 'kejuaraan'">
          <InputComponent :disabled="disabled" class="col-span-2" v-model="form.nama_event" :value="form.nama_event"
                          name="kegiatan" label="Kegiatan" type="text"/>
          <InputComponent :disabled="disabled" class="col-span-1" v-model="form.penyelenggara" :value="form.penyelenggara"
                          name="penyelenggara" label="Penyelenggara"
                          type="text"/>
          <InputComponent :disabled="disabled" class="col-span-2 col-start-1"
                          v-model="form.url_kegiatan" :value="form.url_kegiatan" name="url_kegiatan"
                          label="URL Kegiatan" type="text"/>
          <SelectComponent :disabled="disabled" class="col-span-1 col-start-1"
                           v-model="form.lingkup_tingkat" :value="form.lingkup_tingkat" name="lingkup_tingkat"
                           label="Lingkup Tingkat" type="text">
            <option value="provinsi">
              Provinsi
            </option>
            <option value="wilayah">
              Wilayah
            </option>
            <option value="nasional">
              Nasional
            </option>
            <option value="internasional">
              Internasional
            </option>
          </SelectComponent>
          <SelectComponent :disabled="disabled" class="col-span-1" v-model="form.jumlah_wilayah" :value="form.jumlah_wilayah"
                           name="jumlah-provinsi"
                           label="Jumlah Provinsi" type="text">
            <option value="1 sd 2 provinsi">
              1 s/d 2 Provinsi
            </option>
            <option value="3 sd 4 provinsi">
              3 s/d 4 Provinsi
            </option>
            <option value="lebih 5 provinsi">
              Lebih dari 5 Provinsi
            </option>
            <option value="1 sd 2 negara">
              1 s/d 2 Negara
            </option>
            <option value="3 sd 4 negara">
              3 s/d 4 Negara
            </option>
            <option value="lebih 5 negara">
              Lebih dari 5 Negara
            </option>
          </SelectComponent>
          <SelectComponent :disabled="disabled" class="col-span-1" v-model="form.jumlah_pt" :value="form.jumlah_pt"
                           name="jumlah-pt" label="Jumlah PT" type="text">
            <option value="> 21 pt">
              > 21 PT
            </option>
            <option value="10-20 pt">
              10-20 PT
            </option>
            <option value="5-10 pt">
              5-10 PT
            </option>
            <option value="< 5 pt">
              5 PT
            </option>
          </SelectComponent>
          <SelectComponent :disabled="disabled" class="col-span-1 col-start-1"
                           v-model="form.tim_individu" :value="form.tim_individu" name="tim_individu"
                           label="Jenis Kepesertaan" type="text">
            <option value="tim">
              Kelompok
            </option>
            <option value="individu">
              Individu
            </option>
          </SelectComponent>
          <InputComponent :disabled="disabled" class="col-span-1" v-model="form.jumlah_peserta" :value="form.jumlah_peserta"
                          name="jumlah_peserta"
                          label="Jumlah Peserta" type="number"/>
          <SelectComponent :disabled="disabled" class="col-span-2 col-start-1"
                           v-model="form.prestasi_diraih" :value="form.prestasi_diraih" name="capaian_prestasi"
                           label="Capaian Prestasi" type="text">
            <option value="juara 1">
              Juara 1
            </option>
            <option value="juara 2">
              Juara 2
            </option>
            <option value="juara 3">
              Juara 3
            </option>
            <option value="jaura harapan">
              Juara Harapan
            </option>
            <option value="apresiasi">
              Apresiasi
            </option>
            <option value="partisipasi">
              Partisipasi
            </option>
          </SelectComponent>
          <InputComponent :disabled="disabled" class="col-span-1" v-model="form.tanggal_mulai" :value="form.tanggal_mulai"
                          name="tanggal_mulai" label="Tanggal Mulai"
                          type="date"/>
          <InputComponent :disabled="disabled" class="col-span-1" v-model="form.tanggal_selesai" :value="form.tanggal_selesai"
                          name="tanggal_selesai"
                          label="Tanggal Selesai" type="date"/>
          <InputFileComponent :disabled="disabled" class="col-span-1" @file="insertBukti" name="sertifikat"
                              label="Sertifikat"/>
          <a class="text-xs text-blue-500" target="_blank" :href="form.bukti">lihat Sertifikat</a>
          <InputFileComponent :disabled="disabled" class="col-span-1" @file="insertFotoKegiatan" name="foto_kegiatan"
                              label="Foto Kegiatan"/>
          <a class="text-xs text-blue-500" target="_blank" :href="form.foto_kegiatan">lihat Bukti
            Foto</a>
        </div>
      </div>
      <div class="col-start-1 col-span-2">
        <label>Anggota Tim</label>
        <div class="rounded-md py-4 bg-white">
          <table class="table-auto w-full">
            <thead class="border-b border-gray-300 rounded-xl">
            <tr>
              <th>NIM</th>
              <th>Nama</th>
            </tr>
            </thead>
            <tbody>
            <ListMahasiswa v-for="(item, index) in this.form.mahasiswa" v-bind:key="index"
                           :nim="item.nim"/>
            </tbody>
          </table>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-5" v-if="disabled">
        <button @click="activeData"
                class="bg-yellow-500 mr-3 text-lg py-1 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
          Edit
        </button>
        <button @click="deleteForm"
            class="bg-red-500 transform text-lg py-1 focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
          Delete
        </button>
      </div>
      <div class="grid grid-cols-2 gap-5" v-if="!disabled">
        <button @click="submitForm"
                class="bg-green-500 mr-3 text-lg py-1 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
          Submit
        </button>
        <button @click="inactiveData"
            class="bg-gray-100 transform text-lg py-1 focus:scale-110 transition duration-200 text-black text-center rounded-md shadow-md focus:shadow-lg px-1.5">
          Cancel
        </button>
      </div>
    </ModalComponent>
  </div>
</template>

<script>
import MacCardComponent from "@/components/widget/MacCardComponent";
import axios from "axios";
import MahasiswaAvatar from "@/components/widget/MahasiswaAvatar";
import ModalComponent from "@/components/widget/Modal/ModalComponent";
import InputComponent from "@/components/widget/form/InputComponent";
import InputFileComponent from "@/components/widget/form/InputFileComponent";
import SearchInputComponent from "@/components/widget/form/SearchInputComponent";
import SelectComponent from "@/components/widget/form/SelectComponent";
import ListMahasiswa from "@/components/widget/form/ListMahasiswa";

export default {
  name: "ListBeasiswa",
  components: {
    SearchInputComponent,
    MacCardComponent,
    MahasiswaAvatar,
    ModalComponent,
    InputComponent,
    InputFileComponent,
    SelectComponent,
    ListMahasiswa
  },
  data() {
    return {
      disabled: true,
      data: null,
      datafilter: null,
      search: null,
      form: {}
    }
  },
  methods: {
    getList: function () {
      axios.get(process.env.VUE_APP_BASE_URL + "/beasiswa/beasiswa-mahasiswa/getList/")
          .then(resp => {
            this.data = resp.data
            console.log(resp.data)
            this.datafilter = this.data
          })
    },
    activeData: function () {
      this.disabled = false
    },
    inactiveData: function () {
      this.disabled = true
    },
    editData: function () {
      axios.put(process.env.VUE_APP_BASE_URL + "/prestasi/prestasi-mahasiswa/")
          .catch(e => console.log(e))
          .finally(resp => {
            console.log(resp)
          })
    },
    openModal: function (url) {
      this.form = {}
      this.disabled = true
      this.$store.commit('openModal', url)
      // this.$store.dispatch('getDataModal', process.env.VUE_APP_BASE_URL + '/prestasi/prestasi-mahasiswa/' + url + '/')
      axios.get(process.env.VUE_APP_BASE_URL + '/prestasi/prestasi-mahasiswa/' + url + '/')
          .catch(e => console.log(e))
          .then(resp => {
            this.form = resp.data
          })
    },
    addMahasiswa: function () {
      this.$store.commit('pushMahasiswa', {nim: null, nama: null})
    },
    insertNim: function (key, nim) {
      for (let i in this.$store.state.mahasiswa) {
        if (nim === this.$store.state.mahasiswa[i].nim) {
          if (key == i) {
            axios.post(process.env.VUE_APP_BASE_URL + "/api/insert-nim/", {
              nim: nim
            })
                .then(resp => {
                  console.log(resp)
                  this.$store.state.mahasiswa[key].nama = resp.data.name
                  this.form.mahasiswa[key].nim = nim
                })
                .catch(e => {
                  console.log(e)
                  this.$store.state.mahasiswa[key].nama = null
                })
          } else {
            alert('anda sudah memasukkan NIM tersebut')
            break
          }
        }
      }
    },
    deleteMahasiswa: function (key) {
      if (key != 0) {
        this.$store.commit('deleteMahasiswa', key)
      }
    },
    searchInput: function (input) {
      this.search = input
      if (this.search != '' && this.search) {
        this.datafilter = this.data.filter((item) => {
          return item.nama_event.toUpperCase().includes(this.search.toUpperCase()) || item.mahasiswa.some(item => item.nim.toUpperCase().includes(this.search.toUpperCase())) || item.mahasiswa.some(item => item.prodi.toUpperCase().includes(this.search.toUpperCase()))
        })
      } else {
        return this.datafilter = this.data
      }
    },
    insertFotoKegiatan: function (input) {
      this.form.foto_kegiatan = input
    },
    insertBukti: function (input) {
      this.form.bukti = input
    },
    submitForm: function () {
      const formData = new FormData()
      formData.append('nama_event', this.form.nama_event)
      formData.append('url_kegiatan', this.form.url_kegiatan)
      formData.append('penyelenggara', this.form.penyelenggara)
      formData.append('lingkup_tingkat', this.form.lingkup_tingkat)
      formData.append('jumlah_peserta', this.form.jumlah_peserta)
      formData.append('jumlah_wilayah', this.form.jumlah_wilayah)
      formData.append('jumlah_pt', this.form.jumlah_pt)
      formData.append('kategori', this.form.kategori)
      formData.append('prestasi_diraih', this.form.prestasi_diraih)
      formData.append('ekuivalensi', this.form.ekuivalensi)
      formData.append('tempat', this.form.tempat)
      formData.append('tanggal_mulai', this.form.tanggal_mulai)
      formData.append('tanggal_selesai', this.form.tanggal_selesai)
      formData.append('tahun_kegiatan', this.form.tahun_kegiatan)
      formData.append('deskripsi', this.form.deskripsi)
      formData.append('tim_individu', this.form.tim_individu)
      formData.append('type_kejuaraan', this.form.type_kejuaraan)
      if (this.form.foto_kegiatan === 'File') {
        formData.append('foto_kegiatan', this.form.foto_kegiatan, this.form.foto_kegiatan.name)
      }
      if (this.form.bukti === 'File') {
        formData.append('bukti', this.form.bukti, this.form.bukti.name)
      }
      // formData.append('anggota', JSON.stringify(this.form.anggota))
      formData.append('jenis_prestasi', 'kejuaraan')
      formData.append('token', this.$store.state.auth.token)
      axios.put(process.env.VUE_APP_BASE_URL + "/prestasi/prestasi-mahasiswa/"+this.$store.state.modal.url+'/updatePrestasi/', formData, {})
          .then(resp => {
            console.log(resp)
          })
          .catch(e => console.log(e))
          .finally(() => {
            this.getList()
            this.form = {}
            this.$store.commit('closeModal')
          })
    },
    deleteForm: function () {
      axios.delete(process.env.VUE_APP_BASE_URL + "/prestasi/prestasi-mahasiswa/"+this.$store.state.modal.url+'/deletePrestasi/')
          .then(resp => {
            console.log(resp)
          })
          .catch(e => console.log(e))
          .finally(() => {
            this.getList()
            this.form = {}
            this.$store.commit('closeModal')
          })
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>

</style>