<template>
<!--  <tr v-for="(item, index) in this.$store.state.mahasiswa" v-bind:key="index">-->
  <tr>
    <td>
      <input disabled="disabled"
             class="bg-gray-100 focus:bg-white w-full py-2 pl-5 pr-3 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 font-semibold"
             type="text" v-model="item.nim">
    </td>
    <td>
      <input disabled
             class="bg-gray-100 focus:bg-white w-full py-2 pl-5 pr-3 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 font-semibold"
             type="text" v-model="item.nama">
    </td>
  </tr>
</template>

<script>
import axios from "axios";

export default {
  name: "ListMahasiswa",
  data() {
    return {
      item: {
        nim: this.$props.nim,
        nama: null
      }
    }
  },
  props: [
    'nim',
      'disabled'
  ],
  methods: {
    getMahasiswaProfile: function () {
      axios
          .post(process.env.VUE_APP_BASE_URL + "/api/insert-nim/", {
            nim: this.$props.nim
          })
          .then(resp => {
            this.item.nama = resp.data.name
          })
    },
  },
  mounted() {
    this.getMahasiswaProfile()
  }
}
</script>

<style scoped>

</style>