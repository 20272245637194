<template>
  <div class="flex">
    <NavbarDashComponent>
      <div class="w-full px-4 py-5">
      <router-view></router-view>
    </div>
    </NavbarDashComponent>
<!--    <ChatThreadsComponent />-->
    <div class="fixed right-0 bottom-5 z-50 flex flex-col space-y-1">
      <ToastsComponent v-for="(item, index, key) in this.$store.state.notification" :type="item.type" :message="item.message" :index="index" :key="key" />
    </div>
  </div>
</template>

<script>
import NavbarDashComponent from "@/components/layout/NavbarDashComponent";
import ToastsComponent from "@/components/layout/ToastsComponent";
// import ChatThreadsComponent from "@/components/widget/Chat/ChatThreadsComponent.vue";

export default {
  name: "DashboardLayout",
  components: {
    // ChatThreadsComponent,
    NavbarDashComponent,
    ToastsComponent
  }

}
</script>

<style scoped>

</style>