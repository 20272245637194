<template>
  <div>
    <div class="grid grid-cols-12 gap-x-5 gap-y-5 my-5">
      <MacCardComponent v-if="ajuanbeasiswa.status_beasiswa != null ? ajuanbeasiswa.status_beasiswa != 4 : false" cardsname="Pengajuan Beasiswa Prestas | Beasiswa"
                        class="col-span-12 overflow-hidden">
        <div class="flex justify-between p-5">
          <div class="flex">
            <span class="mr-5 m-auto">Beasiswa yang di ajukan :</span>
            <div class="bg-blue-500 text-white rounded-lg px-2 py-1">
              Beasiswa Prestasi
            </div>
          </div>
          <div class="flex">
            <div class="flex">
              <span class="mr-5 m-auto">Status ajuan beasiswa :</span>
              <div class="text-white rounded-lg px-2 py-1"
                   :class="this.ajuanbeasiswa.status_beasiswa == 0 ? 'bg-indigo-500' : this.ajuanbeasiswa.status_beasiswa == 1 ? 'bg-amber-500' : this.ajuanbeasiswa.status_beasiswa == 2 ? 'bg-green-500' : this.ajuanbeasiswa.status_beasiswa == 3 ? 'bg-red-500' : this.ajuanbeasiswa.status_beasiswa == 4 ? 'bg-sky-500' : ''">
                {{ this.ajuanbeasiswa.status_beasiswa == 0 ? 'Tahap Validasi' : this.ajuanbeasiswa.status_beasiswa == 1 ? 'Proses Seleksi' : this.ajuanbeasiswa.status_beasiswa == 2 ? 'Disetujui' : this.ajuanbeasiswa.status_beasiswa == 3 ? 'Ditolak' : this.ajuanbeasiswa.status_beasiswa == 4 ? 'Selesai' : '' }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col p-5 px-10">
          <div v-for="(item, index, key) in this.ajuanbeasiswa.riwayat_beasiswa" :key="key" class="flex space-x-5">
            <div>
              <div class="w-5 h-5 bg-white border-4 rounded-full"
                   :class="item.status == 0 ? 'border-indigo-500' : item.status == 1 ? 'border-amber-500' : item.status == 2 ? 'border-green-500' : item.status == 3 ? 'border-red-500' : item.status == 4 ? 'border-sky-500' : ''"></div>
              <div class="flex h-full">
                <div class="h-full bg-gray-300 w-0.5 m-auto"></div>
              </div>
            </div>
            <div class="bg-white rounded-xl border border-gray-100 shadow-lg w-full my-5 p-3 py-4">
                <span class="text-white rounded-lg px-2 py-1"
                      :class="item.status == 0 ? 'bg-indigo-500' : item.status == 1 ? 'bg-amber-500' : item.status == 2 ? 'bg-green-500' : item.status == 3 ? 'bg-red-500' : item.status == 4 ? 'bg-sky-500' : ''">
                  {{ item.status == 0 ? 'Tahap Validasi' : item.status == 1 ? 'Proses Seleksi' : item.status == 2 ? 'Disetujui' : item.status == 3 ? 'Ditolak' : item.status == 4 ? 'Selesai' : '' }}
                </span>
              <p class="my-3 text-sm bg-indigo-100 p-5 rounded-lg">{{ item.deskripsi }}</p>
              <span class="text-xs">{{ item.created_at | formatDate }}</span>
            </div>
          </div>
        </div>
      </MacCardComponent>
      <MacCardComponent v-if="ajuanbeasiswa.status_beasiswa != null ? ajuanbeasiswa.status_beasiswa == 4 : true" cardsname="Pengajuan Beasiswa Prestas | Beasiswa" class="col-span-12 overflow-hidden">
        <div class="px-5 mb-5">
          <div class="">
            <div class="grid grid-cols-4 gap-x-5 gap-y-7 pt-4">
              <InputFileComponent class="col-span-1 col-start-1" @file="insertFilePernyataan" name="file_pernyataan"
                                  label="File Pernyataan"/>
              <InputFileComponent class="col-span-1 col-start-1" @file="insertFileRekomendasi" name="file_rekomendasi"
                                  label="File Surat Rekomendasi"/>
              <div class="col-span-2 col-start-1">
                <button @click="submitForm"
                        class="bg-emerald-500 mr-3 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
                  Insert
                </button>
                <button @click="cancelForm"
                        class="bg-emerald-500 transform focus:scale-110 transition duration-200 text-white text-center rounded-md shadow-md focus:shadow-lg px-1.5">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </MacCardComponent>
    </div>
  </div>
</template>

<script>
import MacCardComponent from "@/components/widget/MacCardComponent";
import axios from "axios";
import InputFileComponent from "@/components/widget/form/InputFileComponent";

export default {
  name: "BeasiswaSKKMigas",
  data() {
    return {
      form: {
        semester: null,
        mahasiswa: null,
        file_pernyataan: null,
        file_rekomendasi: null,
      },
      ajuan: false,
      prestasi: [],
      ajuanbeasiswa: {
        jenis_beasiswa: null,
        status_beasiswa: null,
        riwayat_beasiswa: []
      }
    }
  },
  components: {
    MacCardComponent,
    InputFileComponent,
  },
  mounted() {
    this.checkAjuanBeasiswa()
    this.$store.commit('refreshMahasiswa')
  },
  methods: {
    checkAjuanBeasiswa: function () {
      axios.post(process.env.VUE_APP_BASE_URL + "/beasiswa/beasiswa-mahasiswa/getAjuanBeasiswa/", {
        nim: this.$store.state.auth.uid
      },{headers: {'Authorization' : `Bearer   ${this.$store.state.auth.access_token}`}})
          .then(resp => {
            if (resp.data) {
              this.ajuan = true
              this.getAjuanBeasiswa()
            }
          })
          .catch(e => {
            if (e.response.data.code === 'token_not_valid') {
              this.$store.commit('authLogout')
            }
          })
    },
    getLaporanKeuangan: function () {
      axios.post(process.env.VUE_APP_BASE_URL + '/beasiswa/beasiswa-mahasiswa/getLaporanKeuangan/', {
        nim: this.$store.state.auth.uid,
        semester: this.form.semester
      },{headers: {'Authorization' : `Bearer   ${this.$store.state.auth.access_token}`}})
          .catch(e => {
            if (e.response.data.code === 'token_not_valid') {
              this.$store.commit('authLogout')
            }
          })
          .then(resp => {
            console.log(resp.data)
            this.form.nominal_spp_tetap = resp.data.rekap[0].biaya
            this.form.nominal_spp_variabel = resp.data.rekap[1].biaya
          })
    },
    // insertNim: function () {
    //   axios.post(process.env.VUE_APP_BASE_URL + '/prestasi/prestasi-mahasiswa/get_list/', {
    //     nim: this.$store.state.auth.uid
    //   })
    //       .catch(e => console.log(e))
    //       .then(resp => {
    //         console.log(resp.data)
    //         this.prestasi = resp.data
    //       })
    // },
    deleteMahasiswa: function (key) {
      if (key != 0) {
        this.$store.commit('deleteMahasiswa', key)
      }
    }
    ,
    submitForm: function () {
      const formData = new FormData()
      formData.append('type', 'skkmigas')
      formData.append('mahasiswa', this.$store.state.auth.uid)
      formData.append('file_pernyataan', this.form.file_pernyataan)
      formData.append('file_rekomendasi', this.form.file_rekomendasi)
      formData.append('token', this.$store.state.auth.refresh_token)
      axios.post(process.env.VUE_APP_BASE_URL + "/beasiswa/beasiswa-mahasiswa/insertBeasiswa/",
        formData
      ,{headers: {'Authorization' : `Bearer   ${this.$store.state.auth.access_token}`}})
          .then(resp => {
            // console.log(resp)
            this.$store.commit('addNotification', resp.data)
          })
          .catch(e => {
            if (e.response.data.code === 'token_not_valid') {
              this.$store.commit('authLogout')
            } else {
              this.$store.commit('addNotification', {type: 'error', message: e.message})
            }
          })
    },
    insertFilePernyataan: function (input) {
      this.form.file_pernyataan = input
    },
    insertFileRekomendasi: function (input) {
      this.form.file_rekomendasi = input
    },
    cancelForm: function () {
      this.form.nama_event = null,
          this.form.url_kegiatan = null,
          this.form.penyelenggara = null,
          this.form.lingkup_tingkat = null,
          this.form.jumlah_peserta = null,
          this.form.jumlah_wilayah = null,
          this.form.jumlah_pt = null,
          this.form.kategori = null,
          this.form.prestasi_diraih = null,
          this.form.ekuivalensi = null,
          this.form.tempat = null,
          this.form.tanggal_mulai = null,
          this.form.tanggal_selesai = null,
          this.form.tahun_kegiatan = null,
          this.form.deskripsi = null,
          this.form.bukti = null,
          this.form.foto_kegiatan = null,
          this.form.tim_individu = null,
          this.form.jenis_prestasi = null,
          this.form.type_kejuaraan = null,
          this.form.anggota = []
    },
    getAjuanBeasiswa: function () {
      axios.post(process.env.VUE_APP_BASE_URL + "/beasiswa/beasiswa-mahasiswa/getAjuanBeasiswa/", {
          nim: this.$store.state.auth.uid
        },{headers: {'Authorization' : `Bearer   ${this.$store.state.auth.access_token}`}})
      .then(resp => {
        console.log(this.$store.state.auth.uid)
        console.log(resp.data)
        this.ajuanbeasiswa.jenis_beasiswa = resp.data.jenis_beasiswa
        this.ajuanbeasiswa.status_beasiswa = resp.data.riwayat_status[resp.data.riwayat_status.length - 1].status
        this.ajuanbeasiswa.riwayat_beasiswa = resp.data.riwayat_status
      })
      .catch(e => {
            if (e.response.data.code === 'token_not_valid') {
              this.$store.commit('authLogout')
            }
          })
    }
  },
}
</script>

<style scoped>

</style>