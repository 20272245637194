<template>
  <div class="flex w-screen h-screen">
    <form @submit.prevent="login"
      class="m-auto bg-white shadow-lg border border-gray-300 rounded-xl w-72 md:w-96 text-center">
      <div class="my-10">
        <div class="text-4xl font-bold">Welcome</div>
        <div class="text-lg font-light leading-3">Sign in to your account</div>
      </div>
      <div class="mx-5 my-10">
        <input type="text"
          class="flex-1 block w-full rounded-t-xl focus:outline-none px-3 py-1.5 border border-b-0 border-gray-300"
          placeholder="NIM" name="nim" v-model="nim">
        <input type="password"
          class="flex-1 block w-full rounded-b-xl focus:outline-none px-3 py-1.5 border border-gray-300"
          placeholder="Password" name="password" v-model="password">
      </div>
      <div class="mx-5 my-10">
        <div class="m-auto flex space-x-5">
          <button :disabled="loadingSignin" :class="loadingSignin && 'bg-indigo-300'"
            class="bg-indigo-500 text-xl text-white text-center rounded-md shadow-md w-full px-5 py-1"><span
              class="align-text-top">{{ loadingSignin ? 'Loading' : 'Sign in' }}</span></button>
        </div>
      </div>
      <div class="fixed right-0 bottom-5 z-50 flex flex-col space-y-1">
        <ToastsComponent v-for="(item, index, key) in this.$store.state.notification" :type="item.type"
          :message="item.message" :index="index" :key="key" />
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import ToastsComponent from "@/components/layout/ToastsComponent.vue";

export default {
  components: { ToastsComponent },
  data() {
    return {
      notifikasi: {
        active: false,
        message: null,
      },
      labeldata: null,
      prestasidata: null,
      jenisdata: null,
      prodidata: null,
      ajuanbeasiswa: {
        jenis_beasiswa: null,
        status_beasiswa: null,
        riwayat_beasiswa: []
      },
      nim: null,
      password: null,
      loadingSignin: false
    }
  },
  name: "LoginPage",
  mounted() {
    // this.topChartGet()
    // this.jenisChartGet()
    // this.prodiChartGet()
    // this.getAjuanBeasiswa()
  },
  methods: {
    // topChartGet: function () {
    //   axios.get(process.env.VUE_APP_BASE_URL + "/dashboard/top-chart-get")
    //       .then(resp => {
    //         this.labeldata = resp.data.label
    //         this.prestasidata = resp.data.data
    //       })
    //       .catch(e => console.log(e))
    // },
    // jenisChartGet: function () {
    //   axios.get(process.env.VUE_APP_BASE_URL + "/dashboard/jenis-chart-get")
    //       .then(resp => {
    //         this.jenisdata = resp.data.data
    //       })
    //       .catch(e => console.log(e))
    // },
    // prodiChartGet: function () {
    //   axios.get(process.env.VUE_APP_BASE_URL + "/dashboard/prodi-chart-get")
    //       .then(resp => {
    //         this.prodidata = resp.data
    //       })
    //       .catch(e => console.log(e))
    // },
    // login() {
    //   if (!this.$router.currentRoute.query.ticket) {
    //     window.location.href = 'https://sso.umkt.ac.id/cas/login?service=' + window.location.origin + this.$router.currentRoute.path
    //   } else {
    //     console.log(this.$router.currentRoute.query.ticket)
    //     axios.post(process.env.VUE_APP_BASE_URL + '/api/auth/', {
    //       service: window.location.origin + this.$route.path,
    //       ticket: this.$router.currentRoute.query.ticket
    //     })
    //     .then(resp => {
    //       this.$store.commit('auth', resp.data)
    //       this.$router.push({ path: '/' })
    //     })
    //     .catch(e => console.log(e))
    //     // try {
    //     //   this.$auth.loginWith('local', {data: this.model})
    //     //       .then(() => this.$toast.success('Logged In!')).then(this.$router.push('/'))
    //     // } catch (e) {
    //     //   console.log(e)
    //     //   this.$router.push('/')
    //     // }
    //
    //
    //   }
    // }
    login() {
      this.loadingSignin = true
      axios.post(process.env.VUE_APP_BASE_URL + "/api/auth/", {
        username: this.nim,
        password: this.password
      })
        .then(resp => {
          this.$store.commit('auth', resp.data)
        })
        .catch(() => {
          this.loadingSignin = false
          this.$store.commit('addNotification', { type: 'error', message: "Akun atau Password Salah" })
        })
        .finally(() => {
          if (this.$store.state.auth.token !== null) {
            this.loadingSignin = false
            this.notifikasi.active = false
            this.notifikasi.massage = null
            this.$router.push({ path: '/dashboard' })
          } else {
            this.loadingSignin = false
            this.$store.state.notification.active = true
            this.notifikasi.massage = 'Akun atau Password Salah'
          }
        })
    },
    // getProfile: function () {
    //   axios.post(process.env.VUE_APP _BASE_URL + "/api/insert-nim/", {
    //     nim: this.$store.state.auth.nim
    //   })
    //       .then(resp => {
    //         console.log(resp)
    //       })
    // }
  }
}
</script>

<style scoped></style>
